// Tính năng trang Sổ quỹ
export const CASHBOOK_STATISTIC_LIST = 'cashbook_statistic_list';
export const CASHBOOK_STATISTIC_EXPORT = 'cashbook_statistic_export';
export const CASHBOOK_STATISTIC_ACTION = 'cashbook_statistic_action'

export const PERMISSION_CASHBOOK_STATISTIC = {
  CASHBOOK_STATISTIC_LIST: CASHBOOK_STATISTIC_LIST,
  CASHBOOK_STATISTIC_EXPORT: CASHBOOK_STATISTIC_EXPORT,
  CASHBOOK_STATISTIC_ACTION: CASHBOOK_STATISTIC_ACTION
};

export const CASHBOOK_RECEIPT_LIST = 'cashbook_receipt_list';
export const CASHBOOK_RECEIPT_ADD = 'cashbook_receipt_add';
export const CASHBOOK_RECEIPT_EXPORT = 'cashbook_receipt_export';
export const CASHBOOK_RECEIPT_CASHTYPE = 'cashbook_receipt_cashtype';
export const CASHBOOK_RECEIPT_PRINT = 'cashbook_receipt_print';
export const CASHBOOK_RECEIPT_CANCEL = 'cashbook_receipt_cancel';

export const PERMISSION_CASHBOOK_RECEIPT = {
  CASHBOOK_RECEIPT_LIST: CASHBOOK_RECEIPT_LIST,
  CASHBOOK_RECEIPT_ADD: CASHBOOK_RECEIPT_ADD,
  CASHBOOK_RECEIPT_EXPORT: CASHBOOK_RECEIPT_EXPORT,
  CASHBOOK_RECEIPT_CASHTYPE: CASHBOOK_RECEIPT_CASHTYPE,
  CASHBOOK_RECEIPT_PRINT: CASHBOOK_RECEIPT_PRINT,
  CASHBOOK_RECEIPT_CANCEL: CASHBOOK_RECEIPT_CANCEL,
};

export const CASHBOOK_PAYSLIP_LIST = 'cashbook_payslip_list';
export const CASHBOOK_PAYSLIP_ADD = 'cashbook_payslip_add';
export const CASHBOOK_PAYSLIP_EXPORT = 'cashbook_payslip_export';
export const CASHBOOK_PAYSLIP_CASHTYPE = 'cashbook_payslip_cashtype';
export const CASHBOOK_PAYSLIP_PRINT = 'cashbook_payslip_print';
export const CASHBOOK_PAYSLIP_CANCEL = 'cashbook_payslip_cancel';

export const PERMISSION_CASHBOOK_PAYSLIP = {
  CASHBOOK_PAYSLIP_LIST: CASHBOOK_PAYSLIP_LIST,
  CASHBOOK_PAYSLIP_ADD: CASHBOOK_PAYSLIP_ADD,
  CASHBOOK_PAYSLIP_EXPORT: CASHBOOK_PAYSLIP_EXPORT,
  CASHBOOK_PAYSLIP_CASHTYPE: CASHBOOK_PAYSLIP_CASHTYPE,
  CASHBOOK_PAYSLIP_PRINT: CASHBOOK_PAYSLIP_PRINT,
  CASHBOOK_PAYSLIP_CANCEL: CASHBOOK_PAYSLIP_CANCEL,
};

export const PERMISSION_CASHBOOK = { ...PERMISSION_CASHBOOK_STATISTIC, ...PERMISSION_CASHBOOK_RECEIPT, ...PERMISSION_CASHBOOK_PAYSLIP };
