const UserAcitonTypes = {
  SIGN_IN_START: 'SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_OUT: 'SIGN_OUT',
  SET_REFRESH_DATE: 'SET_REFRESH_DATE',
  REFRESH_TOKEN_START: 'REFRESH_TOKEN_START',
  REFRESH_TOKEN_DONE: 'REFRESH_TOKEN_DONE',
  UPDATE_USER_INFO: 'UPDATE_USER_INFO',
  SET_NOTI_TIME: 'SET_NOTI_TIME',
  SET_NOTI: 'SET_NOTI',
  ADD_NOTI: 'ADD_NOTI',
  
};

export default UserAcitonTypes;
