import { takeLatest, put, call } from 'redux-saga/effects';
import sendRequest from 'utils/sendRequest';
import types from './management.types';
import actions from './management.actions';
import urls from 'config/urls';

function* fetchCustomerList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.management.customer,
      params: payload,
    });
    yield put(actions.setCustomerList(data));
  } catch (ex) {
    console.log('[management.sagas.js] ex: ', ex);
  }
}

function* fetchSupplierList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.management.supplier,
      params: payload,
    });
    yield put(actions.setSupplierList(data));
  } catch (ex) {
    console.log('[management.sagas.js] ex: ', ex);
  }
}

function* fetchUserList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.user.list,
      params: payload,
    });
    yield put(actions.setUserList(data));
  } catch (ex) {
    console.log('[management.sagas.js] ex: ', ex);
  }
}

function* managementSagas() {
  yield takeLatest(types.MANAGEMENT_FETCH_CUSTOMER_LIST, fetchCustomerList);
  yield takeLatest(types.MANAGEMENT_FETCH_SUPPLIER_LIST, fetchSupplierList);
  yield takeLatest(types.MANAGEMENT_FETCH_USER_LIST, fetchUserList);
}

export default managementSagas;
