import React from 'react';
import './ReverseListContainer.scss';
import classNames from 'classnames';

const ReverseListContainer = ({ children, className, ...props }) => {
  return (
    <div
      className={classNames('_reverse-list-container', className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default ReverseListContainer;
