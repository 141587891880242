import axios from 'axios';
import { store } from 'redux/store';
import jwt_decode from 'jwt-decode';
import history from '../history';
import { signOut } from 'redux/user/user.actions';
import urls from 'config/urls';
import { showLoader, camelizeKeys, decamelizeKeys, showToast, showAlertDialog, APP_BRANCH, saveCookies } from './util';
import moment from 'moment';
import qs from 'qs';

//skip timezone
const dateTransformer = (data) => {
  if (data instanceof Date || data instanceof moment) {
    return moment(data).utc().format('YYYY-MM-DDTHH:mm:ss');
  }
  if (data instanceof FormData) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map((val) => dateTransformer(val));
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, dateTransformer(val)]));
  }
  return data;
};
// instance
const instance = axios.create({ baseURL: urls.baseURL, transformRequest: [dateTransformer].concat(axios.defaults.transformRequest) });
instance.interceptors.request.use((config) => {
  config.paramsSerializer = (params) =>
    qs.stringify(params, {
      serializeDate: (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss'),
    });
  return config;
});
let currentRequestCount = 0;
const startRequest = (dontCountFlag) => {
  if (dontCountFlag) return;
  currentRequestCount++;
  showLoader();
};
const endRequest = (dontCountFlag) => {
  if (dontCountFlag) return;
  currentRequestCount--;
  if (currentRequestCount < 1) {
    currentRequestCount = 0;
    showLoader(false);
  }
};

const caching = (V_CODE) => {
  let version = localStorage.getItem('version');
  if (version != V_CODE) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }
    // localStorage.clear();
    localStorage.setItem('version', V_CODE);
  }
};

export default async function sendRequest({
  method = 'GET',
  body,
  url,
  headers,
  params,
  background = false,
  notCamelize = false,
  withToken = true,
  checkVersion = true,
}) {
  // decamelizeKeys
  body = decamelizeKeys(body);
  params = decamelizeKeys(params);
  method = method.toLowerCase();
  let token;
  let requestHeader = { ...headers };
  if (withToken) {
    // check user login
    let state = store.getState();
    if (!state.user.currentUser || state.user.currentUser.drugStore?.type !== APP_BRANCH) {
      saveCookies('{}');
      store.dispatch(signOut());
      showToast('Bạn cần phải đăng nhập');
      return history.push('/login');
    }
    // check token expired time
    token = state.user.currentUser.token;
    let { exp } = jwt_decode(token);
    let currentTimeStamp = Math.floor(Date.now() / 1000);

    if (exp < currentTimeStamp + 60 && url !== urls.user.refresh && false) {
      // handle token expired
      saveCookies('{}');
      store.dispatch(signOut());
      showAlertDialog('Phiên làm việc kết thúc, vui lòng đăng nhập lại !!!');
      return Promise.reject('');
      // alert('Phiên làm việc kết thúc, vui lòng đăng nhập lại !!!');
      // store.dispatch(signOut());
    }
    requestHeader.token = token;
  }
  if (withToken) {
    requestHeader['app-platform'] = 'web';
    requestHeader['app-branch'] = APP_BRANCH;
  }

  startRequest(background);
  let response;
  try {
    response = await instance({
      method,
      url,
      data: body,
      headers: requestHeader,
      params,
    });
    endRequest(background);
    if (response.data.error && response.data.error.indexOf('Token') > -1) {
      saveCookies('{}');
      store.dispatch(signOut());
      if (url !== urls.user.refresh) {
        showAlertDialog('Phiên làm việc kết thúc, vui lòng đăng nhập lại !!!');
      }
      return null;
    }

    let { ERR_CODE, ERR_MSG, RESULT, V_CODE } = response.data;
    if (V_CODE && checkVersion) caching(V_CODE);

    if (ERR_CODE === undefined) return notCamelize ? response.data.data : camelizeKeys(response.data.data);
    if (ERR_CODE === '200') return notCamelize ? RESULT : camelizeKeys(RESULT);
    if (ERR_CODE == '-999') {
      showAlertDialog('Hệ thống đang được cập nhật, thời gian dự kiến 60 phút. Vui lòng quay lại sau hoặc liên hệ hotline: 1900575718', () => {
        store.dispatch(signOut());
      });
    }
    if (!background) {
      showToast(ERR_MSG);
    }
    return Promise.reject(ERR_MSG);
  } catch (ex) {
    endRequest(background);
    response = ex.response;
    if (!background) {
      if (!response) {
        showToast('Kết nối đến máy chủ thất bại, vui lòng kiểm tra lại đường truyền internet');
      } else {
        let responseStatus = response.status;
        if (parseInt(responseStatus / 100) === 4) {
          showToast('Kết nối đến máy chủ thất bại');
        } else {
          showToast('Có lỗi xảy ra');
        }
      }
    }

    return Promise.reject(ex);
  }
}
