import Types from './report.types';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getReportRevenueAndGrossProfit: (payload) => ({
    type: Types.GET_REPORT_REVENUE_AND_GROSS_PROFIT,
    payload,
  }),
  setReportRevenueAndGrossProfit: (payload) => ({
    type: Types.SET_REPORT_REVENUE_AND_GROSS_PROFIT,
    payload,
  }),
  getReportInventory: (payload) => ({
    type: Types.GET_REPORT_INVENTORY,
    payload,
  }),
  setReportInventory: (payload) => ({
    type: Types.SET_REPORT_INVENTORY,
    payload,
  }),
  getReportSalesPrescription: (payload) => ({
    type: Types.GET_REPORT_SALES_PRESCRIPTION,
    payload,
  }),
  setReportSalesPrescription: (payload) => ({
    type: Types.SET_REPORT_SALES_PRESCRIPTION,
    payload,
  }),
  getReportQuantityControlList: (payload) => ({
    type: Types.GET_REPORT_QUALITY_CONTROL_LIST,
    payload,
  }),
  setReportQuantityControlList: (payload) => ({
    type: Types.SET_REPORT_QUALITY_CONTROL_LIST,
    payload,
  }),
  getReportQuantityControlDrugList: (payload) => ({
    type: Types.GET_REPORT_QUALITY_CONTROL_DRUG_LIST,
    payload,
  }),
  setReportQuantityControlDrugList: (payload) => ({
    type: Types.SET_REPORT_QUALITY_CONTROL_DRUG_LIST,
    payload,
  }),
  setReportQuantityControlId: (payload) => ({
    type: Types.SET_REPORT_QUALITY_CONTROL_ID,
    payload,
  }),
  removeReportQuantityControlDrugList: (payload) => ({
    type: Types.REMOVE_REPORT_QUALITY_CONTROL_DRUG_LIST,
    payload,
  }),
  updateReportQuantityControlDrugList: (payload) => ({
    type: Types.UPDATE_REPORT_QUALITY_CONTROL_DRUG_LIST,
    payload,
  }),
  postReportQuantityControlDrugList: (payload) => ({
    type: Types.POST_REPORT_QUALITY_CONTROL_DRUG_LIST,
    payload,
  }),
  resetQualityControlDrugList: () => ({
    type: Types.RESET_REPORT_QUALITY_CONTROL_DRUG_LIST,
  }),

  getReportWarehouse: (payload) => ({
    type: Types.GET_REPORT_WAREHOUSE,
    payload,
  }),
  setReportWarehouse: (payload) => ({
    type: Types.SET_REPORT_WAREHOUSE,
    payload,
  }),

  getSpecialDrug: (payload) => ({
    type: Types.GET_SPECIAL_DRUG,
    payload,
  }),
  setSpecialDrug: (payload) => ({
    type: Types.SET_SPECIAL_DRUG,
    payload,
  }),
  getCheckInventory: (payload) => ({
    type: Types.GET_CHECK_INVENTORY,
    payload,
  }),
  setCheckInventory: (payload) => ({
    type: Types.SET_CHECK_INVENTORY,
    payload,
  }),
  setCheckInventoryId: (payload) => ({
    type: Types.SET_CHECK_INVENTORY_ID,
    payload,
  }),
  setReportExportId: (payload) => ({
    type: Types.SET_REPORT_EXPORT_ID,
    payload,
  }),
  setReportStockCardId: (payload) => ({
    type: Types.SET_STOCK_CARD_ID,
    payload,
  }),
};
