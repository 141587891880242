const DOMAIN = window.location.hostname;
const urls = {
  baseURL:

    DOMAIN.search('sphacy-gpp') >= 0
      ? 'https://sphacy-gpp-api.orenda.vn'
      : DOMAIN.search('sphacy-gdp') >= 0 || DOMAIN.search('gpp-test') >= 0
      ? 'https://sphacy-gpp-api.orenda.vn'
      : DOMAIN.search('gpp-staging') >= 0 || DOMAIN.search('gdp-staging') >= 0
      ? 'https://api-staging.sphacy.vn'
      : DOMAIN.search('gpp-real') >= 0 || DOMAIN.search('gdp-real') >= 0
      ? 'https://apigpp-real.sphacy.vn'
      : 'https://apigpp.sphacy.vn',
  user: {
    login: '/api/dang-nhap',
    register: 'api/hieu-thuoc/tao-moi',
    refresh: '/api/v2/user/refresh',
    changePass: '/api/user/change-pass',
    list: '/api/v2/user/list',
    update: '/api/user/update',
    create: '/api/v2/user/create',
    delete: '/api/v2/user/delete',
    settings: '/api/v2/user/settings',
    forgotPassword: '/api/forgotPassword',
    drugStoreInfo: '/api/v2/user/drugStore',
    wbsInfo: '/api/v2/user/wbs-information',
    wbsSetting: '/api/v2/master/drugstore/setting/tmdt',
    createDrugStore: '/api/drugstore/createDrugStoreTemp',
    uploadImage: '/api/drugstore/uploadImage',
    sendOTP: '/api/drugstore/send-otp',
    veryfiOTP: '/api/drugstore/verify-otp',
    systemStatus: '/api/system_status',
    drugStoreCertificate: '/api/v2/master/drugstore/thongtinphaply'
  },
  address: {
    province: 'api/web-sell/address/listProvince',
    district: 'api/web-sell/address/listDistrict',
    ward: 'api/web-sell/address/listWard'
  },
  master: {
    group: '/api/v2/master/drug-group/list',
    groupFilter: '/api/v2/master/drug-group/list-filter',
    category: '/api/v2/master/drug-category/list',
    categoryNew: '/api/v2/master/drug-category/listNew',
    categoryFilter: '/api/v2/master/drug-category/list-filter',
    drugStoreSetting: '/api/v2/master/drugstore/setting',
    unit: '/api/unit',
    unitWBS: '/api/web-sell/units',
    customer: '/api/customer',
    supplier: '/api/supplier',
    parentCategory: '/api/v2/master/drug-category/listParent',
    detailAccumulationPoint: 'api/v2/accumulation_point/detail/',
    saveAccumulationPoint: 'api/v2/accumulation_point/save',
    listAccumulationPoint: 'api/v2/accumulation_point/list_history_edit',
  },
  home: {
    chart: '/api/v2/dashboard/chart',
    statistic: '/api/v2/dashboard/statistic',
    activities: '/api/v2/dashboard/activities',
    warningdate: '/api/v2/dashboard/warningdate',
    warningquantity: '/api/v2/dashboard/warningquantity',
    notification: '/api/v2/dashboard/notification',
    readNotification: '/api/v2/dashboard/notification/read',
    promo: '/api/promotion',
    banner: '/api/v2/dashboard/banner'
  },
  drug: {
    info: '/api/v2/drug/detail/',
    infoStock: '/api/v2/card_warehouse/detail_product/',
    searchMaster: '/api/drugmaster',
    searchMasterNew: '/api/drugmasternew',
    add: '/api/v2/drug/add',
    edit: '/api/v2/drug/edit',
    editMasterDrug: '/api/v2/drug/edit_master_data',
    productCompany: '/api/v2/drug/production_company',
    updateUnit: '/api/v2/drug/updateunit',
    updateWarning: '/api/v2/drug/updatewarning',
    category: '/api/drug-category',
    group: '/api/drug-group',
    combo: '/api/v2/master/drug-combo/list',
    detailCombo: '/api/v2/drug/combo/',
    productsCombo: '/api/v2/drug_combo/list_product',
    deleteCombo: '/api/v2/drug_combo/delete/',
    sellCombo: '/api/warehouse/getListUnitByDrug4SaleComboId/',
    detailProductCombo: '/api/v2/drug_combo/detail_product/',
    saveCombo: '/api/v2/drug_combo/save',
    categoryV2: '/api/v2/drug/category',
    groupV2: '/api/v2/drug/group',
    list: '/api/v2/drug/list',
    listMaster: '/api/v2/drug/list_master_data',
    listImport: '/api/v2/import/drug/list',
    detailImport: '/api/v2/import/drug/detail',
    updateStatus: '/api/v2/drug/updatestatus',
    updateStatusV2: '/api/v2/drug/updatestatusV2',
    scan: '/api/v2/drug/scan',
    scanBarcode: '/api/v2/drug/scan-code',
    search: '/api/v2/drug/search',
    import: '/api/v2/drug/import',
    uploadZip: '/api/v2/drug/uploadzip',
    //Kiểm tra dữ liệu trước khi thực hiện import
    checkImport: '/api/v2/drug/checkimport',
    checkImportGPP: '/api/v2/import/drug/check-import',
    importGPP: '/api/v2/import/drug/import',
    filterDrugByCriteria: '/api/v2/drug/filterDrugByCriteria',
    clone: '/api/v2/drug/clone',
    sync: '/api/v2/drug/sync-tmdt',
    feedback: 'api/v2/report/search_drug_report_complain_detail/',
    sideEffects: 'api/v2/report/search_drug_side_effects_detail/'
  },
  sales: {
    drugListFavorite: '/api/warehouse/autoListWithPackages4SaleFavorite',
    drugDetail: '/api/warehouse/getListUnitByDrug4Sale',
    unitDrugs: '/api/warehouse/getListUnitByDrugIds4Sale',
    searchDrugList: '/api/warehouse/autoListWithPackages4Sale/0',
    clientList: '/api/customer',
    invoice: '/api/invoiceIv1',
    return: '/api/invoiceIv7',
    submit: '/api/v2/invoice/createIV1',
    statistic: '/api/report/revenue_money',
    outStock: '/api/v2/drug/stock',
    uploadXml: '/api/v2/invoice/uploadxml',
    getDetailByCode: '/api/v2/invoice/getDetailByOrderCodeDQG',
    getListPayment: '/api/v2/invoice/get_all_payment_method',
  },
  invoice: {
    detail: '/api/v2/invoice/detail/id',
    detailShort: '/api/v2/invoice/detail-short/id',
    detailCode: '/api/v2/invoice/detail/code',
    return: '/api/invoice/invoice_return',
    list: '/api/v2/invoice/list',
    listTax: '/api/v2/invoice/listEInvoice',
    statistic: '/api/report/goods-in-out',
    save: '/api/v2/invoice/save',
    updateStatus: '/api/v2/invoice/update-status',
    previewEInvoice: '/api/v2/invoice/previewEInvoice',
    getImageInvoice: '/api/v2/invoice/save_file',
    pdfEInvoice: '/api/v2/invoice/pdfEInvoice',
    createEInvoice: '/api/v2/invoice/createEInvoice',
    tempInvoiceRemove: '/api/invoice_tmp',
    tempInvoiceList: '/api/v2/invoice/list-tmp',
    tempInvoiceDetail: '/api/invoice_tmp/detail',
    deleteInvoiceSell: '/api/v2/invoice/delete-sell',
    updateShippingSell: '/api/v2/invoice/shipping-sell',
    paymentDebt: '/api/v2/invoice/payment-debt',
    checkImport: '/api/v2/invoice/checkimport',
    import: '/api/v2/invoice/import',
    payment: '/api/v2/invoice/payment',
    paymentIpn: '/api/v2/invoice/payment-ipn',
    historyPayment: '/api/v2/invoice/history-payment',
    tempList: '/api/v2/import/invoice/list',
    updateVat: '/api/v2/invoice/print_update_vat',
    updateVatEinvoice: '/api/v2/invoice/e_invoice_update_vat',
    changePayment: '/api/v2/invoice/change_payment_method',
  },
  warehousing: {
    drugListFavorite: '/api/drug/autoListFavorite/search',
    drugListFavoriteV2: '/api/drug/autoListFavoriteV2/search',
    searchDrugList: '/api/drug/autoListWithPackages4Import/1',
    supplier: '/api/supplier?',
    getListUnitByDrug: '/api/warehouse/getListUnitByDrug',
    invoice: '/api/v2/invoice/warehousing',
    tempInvoice: '/api/v2/invoice/warehousingtemp',
    invoiceDetail: '/api/invoiceIv7/detail_invoice',
    invoiceList: '/api/invoiceIv2',
    statistic: '/api/v2/invoice/warehousingstatistic',
  },
  report: {
    revenueAndGrossProfit: '/api/report/revenue-profit',
    inventory: '/api/warehouse/stockList',
    checkInventory: '/api/vouchers_check',
    checkInventoryDetail: '/api/vouchers_check/detail/list',
    checkInventoryCheck: '/api/v2/report/voucherscheck/check',
    salesPrescription: '/api/v2/report/prescription-statistic',
    qualityControlList: '/api/v2/report/quality-control/list',
    qualityControlDetail: '/api/v2/report/quality-control/detail',
    qualityControlSave: '/api/v2/report/quality-control/save',
    buySellDrugs: 'api/v2/report/invoice_report',
    drugExpiryReport: 'api/v2/report/drug_expiry_report',
    trackTemperature: 'api/v2/report/temperature_humidity_report',
    trackHygiene: 'api/v2/report/cleaning_report',
    createHygiene: 'api/v2/report/save_cleaning',
    importHygiene: 'api/v2/report/import_cleaning',
    deleteHygiene: 'api/v2/report/delete_cleaning',
    detailHygiene: 'api/v2/report/cleaning_detail',
    importTemperature: 'api/v2/report/import_temperature_humidity',
    detailTemperature: 'api/v2/report/temperature_humidity_detail',
    deleteTemperature: 'api/v2/report/delete_temperature_humidity',
    createTemperature: 'api/v2/report/save_temperature_humidity',
    supplier: 'api/v2/report/get_report_supplier',
    detailSupplier: 'api/v2/report/get_report_supplier_detail/',
    supplierDrugComment: 'api/v2/report/post_report_supplier_drug_comment',
    supplierComment: 'api/v2/report/post_report_supplier_comment',
    listForm: 'api/v2/report/get_list_template_report',
    updateForm: 'api/v2/report/upload_file_report',
    warehouse: 'api/warehouse/inOut',
    specialDrug: 'api/v2/report/special-drug',
    salesPerson: 'api/report/sales-person',
    sales: '/api/v2/dashboard/sell',
    infoPatient: 'api/v2/report/invoice_patient_report',
    feedback: 'api/v2/report/get_complain_report',
    createFeedback: 'api/v2/report/save_complain',
    statusFeedback: 'api/v2/report/update_status_complain',
    detailFeedback: 'api/v2/report/get_detail_complain_report',
    sideEffects: 'api/v2/report/get_side_effects_report',
    createSideEffects: 'api/v2/report/save_side_effects',
    statusSideEffects: 'api/v2/report/update_status_side_effects',
    detailSideEffects: 'api/v2/report/get_detail_side_effects_report',
    invalidInvoice: 'api/v2/report/get_report_invalid_invoice',
    detailInvalidInvoice: 'api/v2/report/get_report_invalid_invoice_detail',
    createInvalidInvoice: 'api/v2/report/save_report_invalid_invoice',
    statusInvalidInvoice: 'api/v2/report/update_status_report_invalid_invoice',
    invoicePrescription: 'api/v2/report/get_invoice_prescription',
    warehousing: '/api/v2/dashboard/warehousing',
    cardWarehouse: '/api/v2/card_warehouse/list',
    cardWarehouseDetail: '/api/v2/card_warehouse/detail/',
    createCardWarehouse: '/api/v2/card_warehouse/create',
    deleteCardWarehouse: '/api/v2/card_warehouse/delete',
    warehouseReport: '/api/v2/dashboard/warehouse_report',
    fullStock: '/api/v2/dashboard/full_of_stock',
    outStock: '/api/v2/dashboard/out_of_stock',
    productsCardWarehouse: '/api/v2/card_warehouse/list_product',
    calculate: 'api/v2/report/post_create_data_warehouse',
    productsFeedback: 'api/v2/report/search_drug_report_complain',
    productsSideEffects: 'api/v2/report/search_drug_side_effects'
  },
  shipping: {
    shippingFrom: '/api/v2/warehouse_transfer/list',
    createShipping: '/api/v2/warehouse_transfer/save',
    shippingDetail: '/api/v2/warehouse_transfer/detail/',
    deleteShipping: '/api/v2/warehouse_transfer/delete/',
    updateShipping: '/api/v2/warehouse_transfer/update_status/',
  },
  management: {
    supplier: '/api/v2/master/supplier/list',
    supplierListInvoice: '/api/supplier/getlistinvoice',
    supplierPurchased: '/api/v2/master/supplier/history',
    customer: '/api/v2/master/customer/list',
    customerImport: '/api/v2/master/customer/import',
    supplierImport: '/api/v2/master/supplier/import',
    doctor: '/api/v2/master/doctor/list',
    createDoctor: '/api/doctor/',
    agency: '/api/v2/agency/getListAgency',
    allAgency: '/api/v2/agency/getall',
    mergeAgency: '/api/v2/agency/mergeAgency',
    createAgency: '/api/v2/agency/create',
    updateAgency: '/api/v2/agency/update',
    updateStatusUser: '/api/v2/user/updateStatus',
    updateStatusAgency: '/api/v2/agency/status',
    customerListInvoice: '/api/customer/listinvoice',
    customerPurchased: '/api/v2/master/customer/history',
    customerPoint: '/api/v2/accumulation_point/list_history_customer_accumulation',
    detailInvoice: '/api/invoice/detail_invoice',
    circularsCate: '/api/v2/notification_important/category',
    circularsList: '/api/v2/notification_important/listwithCate',
    circularsDetail: '/api/v2/notification_important/detail',
    videoTraining: 'api/v2/notification_important/video_training'
  },
  cashBook: {
    list: '/api/v2/cashbook/list',
    save: '/api/v2/cashbook/save',
    cashType: '/api/v2/cashbook/cash-type',
    getCode: '/api/v2/cashbook/code',
    cancel: '/api/v2/cashbook/cancel',
  },
  promotion: {
    promotion: '/api/v2/promotion',
    filter: '/api/v2/promotion/filter',
    save: '/api/v2/promotion/save',
    delete: '/api/v2/promotion/delete',
    status: '/api/v2/promotion/status',
    promotionAvaiable: '/api/v2/promotion/avaiable',
  },
  order: {
    save: '/api/v2/order/save',
    detail: '/api/v2/order/detail',
    list: 'api/v2/order/list',
    listByDrug: 'api/v2/order/listByDrug',
  },
  warehouseManager: {
    detail: '/api/v2/invoiceWarehouse',
    save: '/api/v2/invoiceWarehouse',
    filter: '/api/v2/invoiceWarehouse/filter',
    cancel: '/api/v2/invoiceWarehouse/cancel',
    status: '/api/v2/invoiceWarehouse/status',
    checkImport: '/api/v2/invoiceWarehouse/checkimport',
    import: '/api/v2/invoiceWarehouse/import-invoice',
  },
  admin: {
    drugStore: {
      list: '/api/v2/admin/drugstore/list',
      save: '/api/v2/admin/drugstore/save',
      delete: '/api/v2/admin/drugstore/delete',
      copy: '/api/v2/admin/drugstore/copy',
      check: '/api/v2/admin/drugstore/check',
      listDrugBySource: 'api/v2/admin/drugstore/listBySource',
    },
    announcement: {
      list: '/api/v2/admin/announcement/list',
      save: '/api/v2/admin/announcement/save',
    },
    user: {
      list: '/api/v2/admin/user/list',
      save: '/api/v2/admin/user/save',
    },
    unit: {
      save: '/api/v2/admin/unit/save',
    },
    drugDQG: {
      save: '/api/v2/admin/drug/save',
    },
    bank: {
      list: '/api/v2/admin/bank',
    },
  },
  warehouseProvisional: {
    filter: '/api/v2/import/invoiceWarehouse/filter',
    checkImport: '/api/v2/import/invoiceWarehouse/check-import',
    import: '/api/v2/import/invoiceWarehouse/import-invoice',
    detail: '/api/v2/import/invoiceWarehouse',
    bill: '/api/v2/import/invoice/list?invoice_type=IV7',
    syncIn: '/api/v2/import/invoiceWarehouse/sync-in',
    syncDQG: '/api/v2/import/invoiceWarehouse/sync-dqg',
    checkImportBill: '/api/v2/import/invoice/check-import',
    importBill: '/api/v2/import/invoice/import-invoice',
    syncInBill: '/api/v2/import/invoice/sync-in',
    syncDQGBill: '/api/v2/import/invoice/sync-dqg',
    deleteInvoiceWarehouse: '/api/v2/import/invoiceWarehouse/delete',
    detailBill: '/api/v2/import/invoice/detail/id',
    deleteBill: '/api/v2/import/invoice/delete',
    uploadXml: '/api/v2/import/invoice/uploadxml',
    checkUploadXml: '/api/v2/import/invoice/check-uploadxml',
    checkImportRetail: '/api/v2/import/invoice/check-import-retail',
    importRetail: '/api/v2/import/invoice/import-invoice-retail',
    listImportRetail: '/api/v2/import/invoice/list-retail',
    syncRetail: '/api/v2/import/invoice/sync-in-retail',
  },
  update: {
    listUpdateInventory: '/api/v2/import/warehouse/list',
    detailUpdateInventory: '/api/v2/import/warehouse/detail',
    checkImportInventory: '/api/v2/import/warehouse/check-import',
    confirmImportInventory: '/api/v2/import/warehouse/import',
  }
};

console.log('BASE URL =>', urls.baseURL);
export default urls;
