const paths = {
  root: '/',
  home: '/',
  login: '/login',
  drug: {
    add: '/them-thuoc-moi',
    edit: '/chinh-sua-thuoc',
    list: '/danh-sach-thuoc',
    group: '/nhom-thuoc',
    combo: '/combo-thuoc',
    createCombo: '/cap-nhat-combo',
    category: '/danh-muc-thuoc',
    import: '/ds-thuoc-da-import',
  },
  goods: {
    add: '/them-san-pham-moi',
    edit: '/chinh-sua-san-pham',
    list: '/danh-sach-san-pham',
    group: '/nhom-san-pham',
    category: '/danh-muc-san-pham',
    import: '/ds-san-pham-da-import',
  },
  sales: {
    sales: '/ban-hang',
    product: '/sales/product',
    invoices: '/hoa-don-ban-hang',
    tax: '/hoa-don-dien-tu',
    invoicesTemp: '/don-ban-luu-tam',
    return: '/khach-tra-hang',
    statistic: '/thong-ke-danh-thu',
    paymentHistory: '/lich-su-thanh-toan',
  },
  warehousing: {
    warehousing: '/nhap-hang',
    invoices: '/hoa-don-nhap-hang',
    invoice: '/warehousing/invoice',
    invoiceProcess: '/warehousing/confirm',
    statistic: '/thong-ke-nhap-hang',
    return: '/tra-hang-NCC',
    tempInvoices: '/hoa-don-luu-tam',
    tempInvoicesGDP: '/don-nhap-luu-tam',
    paymentHistory: '/lich-su-thanh-toan-nhap-hang',
  },
  order: {
    order: '/dat-hang',
    list: '/don-dat-hang',
    listwaitingdelivery: '/don-hang-cho-giao',
    temp: '/don-dat-luu-tam',
    payment_history: '/order/payment-history',
    manage: '/danh-sach-don-hang',
    statisticDrug: '/thong-ke-san-pham',
  },
  report: {
    inventory: '/quan-ly-ton-kho',
    revenue: '/doanh-thu-thuc',
    revenueAndGrossProfit: '/doanh-thu-loi-nhuan-gop',
    importExport: '/report/import-export',
    warehousingSell: '/chi-tiet-nhap-ban-hang',
    salesPrescription: '/theo-doi-ban-theo-don',
    qualityControl: '/so-kiem-soat-chat-luong',
    buySellDrug: '/so-mua-ban-thuoc',
    trackExpiryDate: '/so-theo-doi-han-dung',
    temperature: '/so-theo-doi-nhiet-do-do-am',
    hygiene: '/so-theo-doi-ve-sinh',
    patient: '/so-theo-doi-thong-tin-benh-nhan',
    feedback: '/so-theo-doi-xu-ly-khieu-nai',
    createFeedback: '/cap-nhat-so-theo-doi-xu-ly-khieu-nai',
    sideEffects:  '/so-theo-doi-tac-dung-phu',
    supplier:  '/so-theo-doi-cac-nha-phan-phoi',
    uploadForm:  '/bieu-mau-bao-cao-so-y-te',
    invalidInvoice:  '/so-theo-don-khong-hop-le',
    createSideEffects: '/cap-nhat-phieu-theo-doi-tac-dung-phu',
    qualityControlAdd: '/report/quality-control-add',
    warehouse: '/bao-cao-xuat-nhap-ton',
    specialDrug: '/thuoc-kiem-soat-dac-biet',
    checkInventory: '/kiem-kho',
    checkInventoryAdd: '/report/check-inventory-add',
    export: '/xuat-huy',
    exportAdd: '/report/exportAdd',
    salesPerson: '/doanh-so-sale',
    sales: '/bao-cao-ban-hang',
    import:'/bao-cao-nhap-hang',
    stockCard:'/the-kho',
    createStockCard: '/tao-the-kho',
    stockBook:'/so-kho',
    stockFull:'/ton-kho-vuot-dinh-muc',
    stockOut:'/ton-kho-duoi-dinh-muc',
  },
  shipping: {
    shippingFrom: '/phieu-chuyen-di',
    shippingTo: '/phieu-chuyen-den',
    createShippingFrom: '/tao-phieu-chuyen-di',
    editShippingFrom: '/chinh-sua-phieu-chuyen-di'
  },
  management: {
    customer: '/quan-ly-khach-hang',
    supplier: '/quan-ly-nha-cung-cap',
    agency: '/quan-ly-chi-nhanh',
    system: '/thong-tin-cai-dat',
    circulars: '/luat-va-thong-tu',
    guide: '/video-huong-dan',
    user: '/quan-ly-nhan-vien',
    promotion: '/quan-ly-khuyen-mai',
    doctor: '/quan-ly-bac-si'
  },
  warehouse: {
    createImport: '/tao-phieu-nhap-kho',
    import: '/phieu-nhap-kho',
    export: '/phieu-xuat-kho',
    inventory: '/quan-ly-ton-kho',
    checkInventory: '/kiem-kho',
    checkInventoryAdd: '/warehouse/check-inventory-add',
    warehouse: '/bao-cao-xuat-nhap-ton',
    exportCancel: '/xuat-huy',
  },
  cashBook: {
    cashBook: '/xem-so-quy',
    receipt: '/danh-sach-phieu-thu',
    paySlip: '/danh-sach-phieu-chi',
  },
  update: {
    import: '/nhap-phieu-nhap-kho',
    export: '/export-data',
    create: '/tao-hoa-don-ban-hang',
    createImport: '/tao-hoa-don-nhap-hang',
    importRetail: '/tao-hoa-don-ban-le',
    updateInventory: '/cap-nhat-ton-kho'
  },
  admin: {
    user: '/nguoi-dung',
    drugStore: '/cong-ty-phan-phoi-duoc',
    annoucement: '/admin/annoucement',
    drug: '/thuoc-DQG',
    unit: '/don-vi',
  },
  price: {
    price: '/bang-gia',
  },
  payment: {
    invoicePaymentIpn: '/payment/invoice_payment_ipn',
    invoicePaymentIpnIv2: '/payment/invoice_payment_ipn_iv2',
  },
};

export default paths;
