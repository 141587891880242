import { takeLatest, put } from 'redux-saga/effects';
import ChartActionTypes from './chart.types';
import urls from 'config/urls';
import sendRequest from 'utils/sendRequest';
import { fetchChartDataSuccess, fetchChartDataFailure } from './chart.actions';

function* fetchChartData() {
  try {
    let data = yield sendRequest({
      url: urls.home.chart,
    });
    yield put(fetchChartDataSuccess(data));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(fetchChartDataFailure());
  }
}

export function* chartSagas() {
  yield takeLatest(ChartActionTypes.FETCH_CHART_DATA_START, fetchChartData);
}
