import CardCustomize from 'components/CardCustomize';
import SearchForm from 'components/SearchForm';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AddItemComponent.scss';

import useDebounce from 'hooks/useDebounce';
import salesActions from 'redux/sales/sales.action';
import reportActions from 'redux/report/report.actions';
import { v4 } from 'uuid';
import { Card } from 'react-bootstrap';
import {
  selectQualityControlDrugList,
  selectQualityControlValids,
} from 'redux/report/report.selectors';
import ReverseListContainer from 'components/ReverseListContainer';
import { selectSalesDrugList } from 'redux/sales/sales.selectors';
import DrugItem from 'pages/sales/partials/DrugItem';
import './AddItemComponent.scss';
import BillInfoComponent from './BillInfoComponent';
import ItemComponent from './ItemComponent';
import { APP_BRANCH, APP_BRANCH_GDP, getScanData, showToast } from 'utils/util';
import urls from 'config/urls';
import sendRequest from 'utils/sendRequest';
import { SEARCH_FORM_REFRESH_DISABLED } from 'components/SearchForm';

export const ItemType = {
  QualityControl: 'quality-control',
  CheckInventory: 'check-inventory',
  ReportExport: 'report-export',
};
Object.freeze(ItemType);

const AddItemComponent = ({ type = ItemType.QualityControl, initialData }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState(null);
  const searchDataRef = useRef(null);
  const searchRef = useRef(null);
  const debounceSearch = useDebounce(search, 470, () => {
    if (searchDataRef.current) searchDataRef.current.scrollTo(0, 0);
  });
  const [isSearchFocus, setIsSearchFocus] = useState(false);
  const [unitIds, setUnitIds] = useState([]);
  const drugs = initialData?.checkDetail || [];

  const drugListSearch = useSelector(selectSalesDrugList);
  const unitList = useSelector(selectQualityControlDrugList);
  const valids = useSelector(selectQualityControlValids);

  const handleFocus = (searchDataRef) => {
    setIsSearchFocus(true);
    searchDataRef.current.classList.add('boder');
  };
  const handleBlur = (searchDataRef) => {
    setSearch('');
    setIsSearchFocus(false);
    searchDataRef.current.classList.remove('boder');
  };

  const handleDrugChoosen = (drug) => {
    setUnitIds([...unitIds, v4()]);
    setSearch('');
    if (type === ItemType.CheckInventory) {
      drug.currentAmount = drug?.quantity;
    }
    dispatch(reportActions.getReportQuantityControlDrugList(drug));
  };

  const handleChange = (value) => setSearch(value);
  const handleSubmit = (f) => f;
  const handleRemoveItem = (id) =>
    setUnitIds(unitIds.filter((unitId) => unitId !== id));

  const onScanCode = (code) => {
    let { drugCode, number, barcode, scanCode } = getScanData(code);
    sendRequest({
      url: `${urls.drug.scan}`,
      params: {
        scanCode: barcode || drugCode || scanCode,
        number,
      },
    }).then((drug) => {
      if (!drug?.[0]) {
        showToast('Không thể kiểm tra thông tin');
        return;
      }
      handleDrugChoosen(drug?.[0]);
    });
  };

  const renderDrugList = drugListSearch.map((item, index) => (
    <DrugItem
      item={item}
      index={index}
      key={index}
      handleDrugChoosen={handleDrugChoosen}
    />
  ));

  useEffect(() => {
    dispatch(salesActions.getSalesDrugList(debounceSearch));
  }, [debounceSearch]);

  useEffect(
    () => dispatch(reportActions.resetQualityControlDrugList()) && undefined,
    []
  );

  useEffect(() => {
    if (drugs.length) {
      drugs.forEach((drug) => {
        drug.id = drug.drugId || drug.id;
        dispatch(reportActions.getReportQuantityControlDrugList(drug));
      });
      setUnitIds(drugs.map(() => v4()));
    }
  }, [drugs]);

  return (
    <div className="_add-item-component _quality-control-add">
      <CardCustomize
        header={
          <>
            <h3>
              {type === ItemType.QualityControl
                ? 'Sổ kiểm soát chất lượng định kỳ và đột xuất'
                : type === ItemType.CheckInventory
                ? 'Tạo phiếu kiểm kho'
                : type === ItemType.ReportExport
                ? `${!!initialData?.id ? 'Chỉnh sửa' : 'Tạo' } phiếu xuất huỷ`
                : ''}
            </h3>
            <SearchForm
              className="mt-3"
              placeholder={
                type === ItemType.QualityControl
                  ? 'Chọn sản phẩm nhập hàng / nhập thông tin tìm kiếm sản phẩm'
                  : 'Nhập mã hoặc tên thuốc để tìm kiếm'
              }
              handleChange={handleChange}
              searchData={isSearchFocus ? renderDrugList : null}
              searchDataRef={searchDataRef}
              handleSubmit={handleSubmit}
              refreshType={SEARCH_FORM_REFRESH_DISABLED}
              isAutoFocus={false}
              hasSearchButton={false}
              searchRef={searchRef}
              handleFocus={handleFocus}
              handleBlur={handleBlur}
              searchValue={search}
              onScanCode={onScanCode}
            />
          </>
        }
        body={
          <div className="body">
            <Card id="main-card">
              <Card.Header>
                <h6 className="mb-0 text-center">
                  {type === ItemType.QualityControl
                    ? 'Thông tin hàng hóa kiểm tra chất lượng'
                    : type === ItemType.CheckInventory
                    ? 'Thông tin hàng hóa kiểm kho'
                    : type === ItemType.ReportExport
                    ? 'Thông tin hàng hóa xuất huỷ'
                    : ''}
                </h6>
              </Card.Header>
              <Card.Body>
                <ReverseListContainer>
                  {unitIds.map((id, unitIndex) => {
                    return unitIndex < unitList.length ? (
                      <ItemComponent
                        key={id}
                        drug={unitList[unitIndex]}
                        index={unitIndex}
                        handleRemove={() => handleRemoveItem(id)}
                        valid={valids[unitIndex]}
                        type={type}
                      />
                    ) : (
                      <div key={id}></div>
                    );
                  })}
                </ReverseListContainer>
              </Card.Body>
            </Card>
            <BillInfoComponent type={type} initialData={initialData} />
          </div>
        }
      />
    </div>
  );
};

export default AddItemComponent;
