import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import types from './warehousing.types';
import urls from 'config/urls';
import sendRequest from 'utils/sendRequest';
import actions from './warehousing.actions';
import { formatDate, APP_BRANCH } from 'utils/util';

function* fetchDrugListSearch({ payload }) {
  try {
    const URL = payload ? `${urls.warehousing.searchDrugList}/${payload}` : urls.warehousing.drugListFavorite;
    const data = yield call(sendRequest, {
      url: URL,
      background: true,
    });
    yield put(actions.setWarehousingDrugListSearch(data));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(actions.setWarehousingDrugListSearch([]));
  }
}

function* fetchWarehousingDrugList({ payload }) {
  try {
    var params = {}
    if(payload.fromData) {
      switch (payload.fromData) {
        case "drug_master_data":
          params = {
            is_drug_master: true
          }
          break;
        default:
          params = {
            option: true
          }
          break;
      }
    }
    const data = yield call(sendRequest, {
      url: `${urls.drug.info}${payload.drugId || payload.id}`,
      params
    });
    yield put(
      actions.setWarehousingDrugList({
        ...payload,
        drugUnits: data.unit,
        drugNumbers: data.numbers,
        name: data[0].name,
      })
    );
  } catch (ex) {
    console.log('[warehousing.sagas.js] ex: ', ex);
  }
}

function* fetchSupplierList() {
  try {
    const data = yield call(sendRequest, {
      url: urls.warehousing.supplier,
    });

    yield put(actions.setSupplierList(data));
  } catch (ex) {
    console.log('[warehousing.sagas.js] ex: ', ex);
  }
}

function* postSupplier({ payload }) {
  try {
    const { address, email, name, phoneNumber: numberPhone, taxNumber, website } = payload;

    yield call(sendRequest, {
      method: 'POST',
      url: urls.warehousing.supplier,
      body: {
        address,
        email,
        name,
        numberPhone,
        taxNumber,
        website,
      },
    });

    yield call(fetchSupplierList);
  } catch (ex) {
    console.log('[warehousing.sagas.js] ex: ', ex);
  }
}

function* postWarehousingData({ payload }) {
  const { drugList, values, status, summary, saveTemp, isTemp, clientPay } = payload;
  try {
    const body = {
      amount: summary.totalNoVat,
      created_at: formatDate(values.createdDate, 'YYYY-MM-DD'),
      source: APP_BRANCH,
      customer_id: values.supplier,
      description: values.description,
      discount: values.totalDiscount,
      invoice_code: '', //FIXME:

      invoice_id: values.invoiceId || '',
      invoice_type: values.isFromSupplier ? 'IV2' : 'IV7',
      is_temp: !!isTemp,
      order_id: null, //FIXME:
      pay_amount: clientPay ?? values.realPayAmount,
      payment_method: values.paymentMethod,
      payment_status: 'unpaid',
      receipt_date: values.receiptDate,
      refer_id: null, // FIXME:
      status: status, // FIXME:
      supplier_invoice_code: values.billNumber,
      vat_amount: summary.totalVat,
      invoice_detail: drugList.map((d) => {
        return {
          from_data: d.fromData,
          cost: d.cost - d.discount,
          org_cost: d.cost,
          current_cost: d.selectedUnit.currentCost,
          drug_id: d.drugId || d.id,
          exchange: d.selectedUnit.exchange,
          expiry_date: d.expiryDate,
          mfg_date: d.mfgDate,
          is_basic: d.selectedUnit.isBasic,
          main_cost: (d.cost - d.discount) * (1 + d.vat / 100.0),
          number: d.number,
          quantity: d.amount,
          unit_id: d.unitId,
          vat: d.vat,
          warehouse_invoice_id: d.warehouseInvoiceId,
        };
      }),
    };
    if (saveTemp === true) {
      const data = yield call(sendRequest, {
        method: 'POST',
        url: urls.warehousing.tempInvoice,
        body,
      });
      yield put(actions.postWarehousingDataDone(data));
    } else {
      const data = yield call(sendRequest, {
        method: 'POST',
        url: urls.warehousing.invoice,
        body,
      });
      yield put(actions.postWarehousingDataDone(data));
    }
  } catch (ex) {
    console.log('[warehousing.sagas.js] ex: ', ex);
  }
}

function* fetchWarehousingInvoice({ payload }) {
  try {
    const { invoiceId, isTemp, type } = payload;
    const data = yield call(
      sendRequest,
      isTemp
        ? {
            url: `${urls.invoice.tempInvoiceDetail}/${invoiceId}`,
          }
        : type == 'fromProvisional'
        ? {
            url: `${urls.warehouseProvisional.detailBill}/${invoiceId}`,
          }
        : {
            url: `${urls.invoice.detail}/${invoiceId}`,
          }
    );

    let units = [];
    let valids = [];
    for (let i = 0; i < data.invoiceDetail.length; i++) {
      let invoiceDetail = data.invoiceDetail[i];
      let drugUnits = invoiceDetail.drug.units;
      units.push({
        ...invoiceDetail,
        id: invoiceDetail.drugId,
        name: invoiceDetail.name || invoiceDetail.drugName,
        drugUnits,
      });
      valids.push({ duplicated: false });
    }

    yield put(
      actions.setWarehousingInvoiceById({
        invoiceId,
        invoiceData: data,
        units,
        valids,
      })
    );
  } catch (ex) {
    console.log('[warehousing.sagas.js] ex: ', ex);
  }
}

function* fetchWarehousingInvoiceList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.invoice.list,
      params: payload,
    });
    yield put(actions.setWarehousingInvoiceList(data));
  } catch (ex) {
    console.log('[warehousing.sagas.js] ex: ', ex);
  }
}

function* fetchWarehousingTempInvoiceList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: `${urls.invoice.tempInvoiceList}`,
      params: payload,
    });
    yield put(actions.setWarehousingTempInvoiceList(data));
  } catch (ex) {
    console.log('[warehousing.sagas.js] ex: ', ex);
  }
}

export function* warehousingSagas() {
  yield takeLatest(types.GET_WAREHOUSING_DRUG_LIST_SEARCH, fetchDrugListSearch);
  yield takeEvery(types.FETCH_WAREHOUSING_DRUG_LIST, fetchWarehousingDrugList);
  yield takeLatest(types.GET_SUPPLIER_LIST, fetchSupplierList);
  yield takeLatest(types.POST_SUPPLIER, postSupplier);
  yield takeLatest(types.POST_WAREHOUSING_DATA, postWarehousingData);
  yield takeLatest(types.GET_WAREHOUSING_INVOICE_BY_ID, fetchWarehousingInvoice);
  yield takeLatest(types.GET_WAREHOUSING_INVOICE_LIST, fetchWarehousingInvoiceList);
  yield takeLatest(types.GET_WAREHOUSING_TEMP_INVOICE_LIST, fetchWarehousingTempInvoiceList);
}
