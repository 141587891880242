import { takeLatest, put, call } from 'redux-saga/effects';
import types from './drug.types';
import urls from 'config/urls';
import sendRequest from 'utils/sendRequest';
import {
  setDrugList,
  setDrugGroup,
  postDrugGroupFinish,
  setDrugCategory,
  postDrugCategoryFinish,
  removeDrugGroupFinish,
  removeDrugCategoryFinish,
  getDrugCategoryDetailFinish,
  getDrugGroupDetailFinish,
  setDrugOutStock,
  setDrugOutDate,
} from './drug.actions';

function* fetchDrugList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.drug.list,
      params: payload,
    });
    yield put(setDrugList(data));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(setDrugList([]));
  }
}

function* fetchDrugGroup({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.master.group,
      params: payload,
    });
    yield put(setDrugGroup(data));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(setDrugGroup([]));
  }
}

function* postDrugGroup({ payload, callback = () => {} }) {
  try {
    const data = yield call(sendRequest, {
      method: payload.id ? 'PUT' : 'POST',
      url: urls.drug.group + (payload.id ? `/${payload.id}` : ''),
      body: { name: payload.name, active: 'yes', is_drug: payload.isDrug },
    });
    yield put(postDrugGroupFinish({ ...payload, ...data }));
    yield call(callback, data);
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(postDrugGroupFinish([]));
  }
}

function* fetchDrugCategory({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.master.category,
      params: payload,
    });
    yield put(setDrugCategory(data));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(setDrugCategory([]));
  }
}

function* postDrugCategory({ payload, callback = () => {} }) {
  try {
    const data = yield call(sendRequest, {
      method: payload.id ? 'PUT' : 'POST',
      url: urls.drug.category + (payload.id ? `/${payload.id}` : ''),
      body: { name: payload.name, active: 'yes', is_drug: payload.isDrug },
    });
    yield put(postDrugCategoryFinish({ ...payload, ...data }));
    yield call(callback, data);
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(postDrugCategoryFinish([]));
  }
}

function* removeDrugGroup({ payload }) {
  try {
    yield call(sendRequest, {
      method: 'DELETE',
      url: `${urls.drug.group}/${payload}`,
    });
    yield put(removeDrugGroupFinish(payload));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(removeDrugGroupFinish([]));
  }
}

function* removeDrugCategory({ payload }) {
  try {
    yield call(sendRequest, {
      method: 'DELETE',
      url: `${urls.drug.category}/${payload}`,
    });
    yield put(removeDrugCategoryFinish(payload));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(removeDrugCategoryFinish([]));
  }
}

function* fetchDrugCategoryDetail({ id, payload }) {
  try {
    const data = yield call(sendRequest, {
      url: `${urls.drug.categoryV2}/${id}`,
      params: payload,
    });
    yield put(getDrugCategoryDetailFinish(data));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(getDrugCategoryDetailFinish(null));
  }
}

function* fetchDrugGroupDetail({ id, payload }) {
  try {
    const data = yield call(sendRequest, {
      url: `${urls.drug.groupV2}/${id}`,
      params: payload,
    });
    yield put(getDrugGroupDetailFinish(data));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(getDrugGroupDetailFinish(null));
  }
}

function* fetchDrugOutDate({ callback = () => {} }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.home.warningdate,
    });
    yield put(setDrugOutDate(data));
    yield call(callback);
  } catch (ex) {
    console.log('[drug.sagas.js] ex: ', ex);
  }
}

function* fetchDrugOutStock({ callback = () => {} }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.home.warningquantity,
    });
    yield put(setDrugOutStock(data));
    yield call(callback);
  } catch (ex) {
    console.log('[drug.sagas.js] ex: ', ex);
  }
}

export function* drugSagas() {
  yield takeLatest(types.GET_DRUG_LIST, fetchDrugList);
  yield takeLatest(types.ADD_DRUG_GROUP_START, postDrugGroup);
  yield takeLatest(types.GET_DRUG_GROUPS, fetchDrugGroup);
  yield takeLatest(types.GET_DRUG_CATEGORIES, fetchDrugCategory);
  yield takeLatest(types.ADD_DRUG_CATEGORY_START, postDrugCategory);
  yield takeLatest(types.REMOVE_DRUG_GROUP_START, removeDrugGroup);
  yield takeLatest(types.REMOVE_DRUG_CATEGORY_START, removeDrugCategory);
  yield takeLatest(types.GET_DRUG_GROUP_DETAIL_START, fetchDrugGroupDetail);
  yield takeLatest(
    types.GET_DRUG_CATEGORY_DETAIL_START,
    fetchDrugCategoryDetail
  );
  yield takeLatest(types.GET_DRUG_OUT_DATE_START, fetchDrugOutDate);
  yield takeLatest(types.GET_DRUG_OUT_STOCK_START, fetchDrugOutStock);
}
