import _ from 'lodash';
import { v4 } from 'uuid';
import { cloneObj, mapDrugBatches } from 'utils/util';
import types from './sales.types';

export const SALES_UNIT_TYPE = 'SALES_UNIT_TYPE';
export const SALES_COMBO_TYPE = 'SALES_COMBO_TYPE';
export const SALES_PRESCRIPTION_TYPE = 'SALES_PRESCRIPTION_TYPE';
export const SALES_PRESCRIPTION_NATION_TYPE = 'SALES_PRESCRIPTION_NATION_TYPE';
export const SALES_COPY_TYPE = 'SALES_COPY_TYPE';
export const SALES_SESSION_STORAGE_TYPE = 'SALES_SESSION_STORAGE_TYPE';
export const SHIPPING = 'SHIPPING';
export const SUBMIT_TYPE_DEFAULT = 'SUBMIT_TYPE_DEFAULT';
export const SUBMIT_TYPE_PENDING = 'SUBMIT_TYPE_PENDING';
export const SUBMIT_TYPE_VALIDATED = 'SUBMIT_TYPE_VALIDATED';

const TYPE_COMBO_COPY = 'TYPE_COMBO_COPY';

const checkUnitsDuplication = (items, forms, valid, idxRemove) => {
  let objValid = { ...valid };
  const formList = forms.map((i) => i.values);
  if (idxRemove !== undefined) {
    let key = [items[idxRemove].drugCode, formList[idxRemove].number, formList[idxRemove].unitId].join('-');
    objValid[key]--;
  } else
    for (let i = 0; i < items.length; i++) {
      let key = [items[i].drugCode, formList[i]?.number, formList[i]?.unitId].join('-');
      if (!objValid[key]) {
        objValid[key] = 0;
      }
      objValid[key]++;
    }
  return objValid;
};

const INITIAL_STATE = {
  units: [],
  items: [],
  forms: [],
  search: '',
  searchData: [],
  drugBatches: {},
  valid: {},
  billInfo: null,
};

const salesGdpReducer = (state = INITIAL_STATE, action) => {
  let { drugBatches } = state;
  switch (action.type) {
    case types.ADD_ITEM:
      const { data, batches } = action.payload;
      const id = data.id;
      if (!_.isEqual(drugBatches[id], batches)) {
        if (!drugBatches[id]) drugBatches[id] = [];
        drugBatches[id] = _.uniq([...drugBatches[id], ...batches]);
      }
      let itemsAdd = [...state.items, data];
      return {
        ...state,
        items: itemsAdd,
        units: [...state.units, v4()],
      };
    case types.REMOVE_ITEM: {
      let valid = { ...state.valid };
      let items = [...state.items];
      let forms = [...state.forms];
      valid = checkUnitsDuplication(items, forms, valid, action.payload);
      items = items.filter((_, i) => i !== action.payload);
      forms = forms.filter((_, i) => i !== action.payload);
      return {
        ...state,
        valid,
        items,
        units: state.units.filter((_, i) => i !== action.payload),
        forms,
      };
    }
    case types.SET_ITEM: {
      let data = action.payload;
      let items = data.invoiceDetail.filter((e) => e.drugId > 0);
      if (data?.isStorage)
        return {
          ...state,
          drugBatches: data.drugBatches,
          items,
          units: items.map((e) => v4()),
          forms: [],
        };
      else
        items.forEach((item) => {
          if (item?.drug?.numbers && !_.isEqual(drugBatches[item.id], item.drug.numbers)) {
            //create array with key = id then multiply array to create batches and drugId
            drugBatches[item.id] = mapDrugBatches(item.drug.numbers, item.drug.units, item.id);
          }
        });
      var d = {
        ...state,
        items,
        units: items.map((e) => v4()),
        forms: [],
      };
      return d;
    }
    case types.CLEAR_ITEM:
      return {
        ...state,
        items: [],
        units: [],
        forms: [],
        drugBatches: {},
        valid: {},
        billInfo: null,
      };

    // search
    case types.SET_SEARCH:
      return { ...state, search: action.payload };

    // search data
    case types.SET_SEARCH_DATA:
      return { ...state, searchData: action.payload };
    case types.SET_BILL_INFO_GDP:
      return { ...state, billInfo: action.payload };

    case types.UPDATE_FORM: {
      let valid = {};
      let items = [...state.items];
      let forms = [...state.forms];
      forms[action.index] = action.payload;
      valid = checkUnitsDuplication(items, forms, valid);
      return { ...state, forms, valid };
    }
    case types.UPDATE_DRUG_BATCHES: {
      let drugBatches = state.drugBatches;
      action.payload.forEach((item) => {
        drugBatches[item.drugId] = mapDrugBatches(item.numbers, item.units, item.drugId);
      });
      return {
        ...state,
        drugBatches,
      };
    }
    default:
      return { ...state };
  }
};

export default salesGdpReducer;
