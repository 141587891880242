import { takeLatest, put, call, delay } from 'redux-saga/effects';

import UserAcitonTypes from './user.types';
import urls from 'config/urls';
import { refreshDone, signInFailure, signInSuccess } from './user.actions';
import sendRequest from 'utils/sendRequest';
import { showToast } from 'utils/util';
import moment from 'moment';

export function* signIn({ payload: { username, password, type, subscription } }) {
  try {
    const user = yield call(sendRequest, {
      url: urls.user.login,
      body: {
        username,
        password,
        type,
        subscription,
      },
      method: 'post',
      withToken: false,
    });
    if (
      user &&
      user.drugStore.endTime &&
      moment(user.drugStore.endTime).add(14, 'days').utcOffset(0, true).unix() <= moment().utcOffset(0, true).unix()
    ) {
      showToast('Nhà thuốc đã hết hạn, vui lòng liên hệ hotline: 0931.439.456 hoặc 0917.777.711 để được gia hạn.', 'error');
    } else {
      yield put(signInSuccess(user));
    }
  } catch (ex) {
    yield put(signInFailure(ex));
  }
}

export function* refresh({ payload: { subscription } }) {
  try {
    const user = yield call(sendRequest, {
      url: urls.user.refresh,
      method: 'post',
      body: { subscription },
    });
    if (user) {
      yield put(refreshDone(user));
    } else {
      yield delay(2000);
      yield put(refreshDone(null));
    }
  } catch (ex) {
    yield put(refreshDone(null));
  }
}

export function* userSagas() {
  yield takeLatest(UserAcitonTypes.SIGN_IN_START, signIn);
  yield takeLatest(UserAcitonTypes.REFRESH_TOKEN_START, refresh);
}
