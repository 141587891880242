// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ADD_ITEM: 'ADD_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  SET_ITEM: 'SET_ITEM',
  CLEAR_ITEM: 'CLEAR_ITEM',
  SET_SEARCH: 'SET_SEARCH',
  SET_SEARCH_DATA: 'SET_SEARCH_DATA',
  UPDATE_FORM: 'UPDATE_FORM',
  UPDATE_DRUG_BATCHES: 'UPDATE_DRUG_BATCHES',
  SET_BILL_INFO_GDP: 'SET_BILL_INFO_GDP',
};
