import React  from 'react';
import { ReactComponent as LogoGPPColored } from 'assets/logos/gpp.svg';
import { ReactComponent as LogoGPPWhite } from 'assets/logos/gpp-white.svg';
import { ReactComponent as LogoGDPColored } from 'assets/logos/gdp.svg';
import { ReactComponent as LogoGDPWhite } from 'assets/logos/gdp-white.svg';
import { ReactComponent as LogoSphacy } from 'assets/logos/logo-sphacy.svg';
import { APP_BRANCH, APP_BRANCH_GDP } from 'utils/util';

const Logo = ({ type = 'colored', ...props }) => {
  const LogoComponent =
    APP_BRANCH === APP_BRANCH_GDP
      ? type === 'white'
        ? LogoGDPWhite
        : LogoGDPColored
      : type === 'white'
      ? LogoGPPWhite
      : LogoGPPColored;
  return <LogoComponent {...props} />;
};

export default Logo;
