const WarehousingTypes = {
  GET_WAREHOUSING_DRUG_LIST_SEARCH: 'GET_WAREHOUSING_DRUG_LIST_SEARCH',
  SET_WAREHOUSING_DRUG_LIST_SEARCH: 'SET_WAREHOUSING_DRUG_LIST_SEARCH',
  SET_WAREHOUSING_DRUG_LIST: 'SET_WAREHOUSING_DRUG_LIST',
  FETCH_WAREHOUSING_DRUG_LIST: 'FETCH_WAREHOUSING_DRUG_LIST',
  REMOVE_WAREHOUSING_DRUG_LIST: 'REMOVE_WAREHOUSING_DRUG_LIST',
  UPDATE_WAREHOUSING_DRUG_LIST: 'UPDATE_WAREHOUSING_DRUG_LIST',
  GET_SUPPLIER_LIST: 'GET_SUPPLIER_LIST',
  SET_SUPPLIER_LIST: 'SET_SUPPLIER_LIST',
  POST_SUPPLIER: 'POST_SUPPLIER',
  RESET_WAREHOUSING_REDUX: 'RESET_WAREHOUSING_REDUX',
  POST_WAREHOUSING_DATA: 'POST_WAREHOUSING_DATA',
  DONE_POST_WAREHOUSING_DATA: 'DONE_POST_WAREHOUSING_DATA',
  GET_WAREHOUSING_INVOICE_BY_ID: 'GET_WAREHOUSING_INVOICE_BY_ID',
  SET_WAREHOUSING_INVOICE_BY_ID: 'SET_WAREHOUSING_INVOICE_BY_ID',
  REMOVE_WAREHOUSING_INVOICE_ID: 'REMOVE_WAREHOUSING_INVOICE_ID',
  SET_WAREHOUSING_INVOICE_LIST: 'SET_WAREHOUSING_INVOICE_LIST',
  GET_WAREHOUSING_INVOICE_LIST: 'GET_WAREHOUSING_INVOICE_LIST',
  GET_WAREHOUSING_TEMP_INVOICE_LIST: 'GET_WAREHOUSING_TEMP_INVOICE_LIST',
  SET_WAREHOUSING_TEMP_INVOICE_LIST: 'SET_WAREHOUSING_TEMP_INVOICE_LIST',
  SET_WAREHOUSING_INVOICE_BY_SESSION_STORAGE:
    'SET_WAREHOUSING_INVOICE_BY_SESSION_STORAGE',
    SET_WAREHOUSING_BILL_INFO: 'SET_WAREHOUSING_BILL_INFO'
};

export default WarehousingTypes;
