import React, { useEffect, useRef } from 'react';
import './CustomUIDialog.scss';
import { APP_BRANCH } from 'utils/util';
import FormCustomize from './FormCustomize';
import DropdownFilter from './DropdownFilter';
const CustomUIDialog = ({ onClose, options = null, children }) => {
  const { title, message, customButtons = [], cancelText, cancelAction, defaultText, defaultAction, defaultWrapAction } = options;
  const btnRef = useRef(null);
  
  useEffect(() => {
    window.onpopstate = function (e) {
      if (cancelAction) cancelAction();
      onClose();
    };
    return () => (window.onpopstate = () => {});
  }, [cancelAction, onClose]);

  useEffect(() => {
    let ele = document.getElementById('_customUIDialog');
    const modal = document.getElementsByClassName('modal')[0];
    const modalTabIndex = modal?.tabIndex;
    if (ele && modal && modalTabIndex != null) modal.removeAttribute('tabindex');
    const keyDownHandler = (e) => {
      if (e.which === 27) e.returnValue = false;
    };
    window.addEventListener('keydown', keyDownHandler, true);
    let timeOut = setTimeout(() => {
      if (btnRef?.current) {
        btnRef.current.focus();
      }
    }, 200);

    return () => {
      clearTimeout(timeOut);
      window.removeEventListener('keydown', keyDownHandler, true);
      if (modalTabIndex != null) modal.tabIndex = modalTabIndex;
    };
  }, []);

  return (
    <div id="_customUIDialog" className="react-confirm-alert-body _customUIDialog">
      <h1>Sphacy {APP_BRANCH}</h1>
      <p>{message}</p>
    
     {children}
      <div className="react-confirm-alert-button-group">
        {customButtons.map(({ label, className, onClick }, i) => (
          <button
            tabIndex={i + 1}
            key={i}
            className={`${className} tabIndex-${i}`}
            onClick={() => {
              onClose();
              onClick();
            }}
          >
            {label}
          </button>
        ))}
        <p style={{ flex: 1 }}></p>
        <button
          id="_customUIDialog-btn-cancel"
          tabIndex={customButtons.length + 1}
          onClick={() => {
            if (cancelAction) cancelAction();
            onClose();
          }}
        >
          {cancelText}
        </button>
        {defaultText.length > 0 && (
          <button
            tabIndex={customButtons.length + 2}
            onClick={() => {
              if(defaultWrapAction) {
                defaultWrapAction()
              } else {
                onClose();
                defaultAction();
              }
            }}
            ref={btnRef}
            id="_customUIDialog-btn-submit"
          >
            {defaultText}
          </button>
        )}

        <span
          tabIndex={customButtons.length + 3}
          id="_customUIDialog-focus-guard"
          onFocus={() => {
            if (customButtons.length > 0) document.getElementsByClassName('tabIndex-0')[0].focus();
            else document.getElementById('_customUIDialog-btn-cancel').focus();
          }}
        ></span>
      </div>
    </div>
  );
};
export default CustomUIDialog;
