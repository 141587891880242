import Types from './master.types';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCustomerList: (isSupplier, moneyFromPoint) => ({
    type: Types.GET_CUSTOMER_LIST,
    isSupplier,
    moneyFromPoint
  }),
  setCustomerList: (payload) => ({
    type: Types.SET_CUSTOMER_LIST,
    payload,
  }),
  setSupplierList: (payload) => ({
    type: Types.SET_SUPPLIER_LIST,
    payload,
  }),
  postCustomer: (payload) => ({
    type: Types.START_POST_CUSTOMER,
    payload,
  }),
  postCustomerDone: (payload) => ({
    type: Types.DONE_POST_CUSTOMER,
    payload,
  }),
  postSupplierDone: (payload) => ({
    type: Types.DONE_POST_SUPPLIER,
    payload,
  }),
  setCustomerId: (payload) => ({
    type: Types.SET_CUSTOMER_ID,
    payload,
  }),
  resetMasterRedux: () => ({
    type: Types.RESET_MASTER_REDUX,
  }),
};
