import ScanCodeActionTypes from './scan-code.types';

const INITIAL_STATE = {
  code: null,
};

const scanCodeReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ScanCodeActionTypes.SCAN_CODE:
      return {
        ...state,
        code: payload,
      };
    default:
      return state;
  }
};

export default scanCodeReducer;
