import ProductImage from 'components/ProductImage';
import React from 'react';
import { Media, Row, Col } from 'react-bootstrap';
import { addComma, formatDate } from 'utils/util';

import './DrugItem.scss';

const DrugItem = ({ item, handleDrugChoosen, ...props }) => {
  return (
    item && (
      <Media
        {...props}
        className="wrap-item"
        onMouseDown={() => (handleDrugChoosen ? handleDrugChoosen(item) : null)}
      >
        <ProductImage
          width={80}
          height={80}
          className="mr-3"
          src={item.image}
          alt="Generic placeholder"
        />
        <Media.Body>
          <h5 className="title">
            {item.name}
            {item.shortName && ` (${item.shortName})`} - {item.unitName}
          </h5>
          <p className="item">{item.company}</p>
          <Row>
            <Col>
              <p className="item">
                Mã SP: <span className="content">{item.drugCode}</span>
              </p>
              <p className="item">
                Số đăng ký:{' '}
                <span className="content">{item.registryNumber}</span>
              </p>
              <p className="item">
                Số lô: <span className="content">{item.number}</span>
              </p>
            </Col>
            <Col>
              <p className="item">
                Tồn kho:{' '}
                <span className="content">
                  {addComma(Math.floor(item.quantity))}
                </span>
              </p>
              <p className="item">
                Hạn sử dụng:{' '}
                <span className="content">
                  {formatDate(item.expiryDate, 'DD/MM/YYYY')}
                </span>
              </p>
              <p className="item">
                Giá bán:{' '}
                <span className="content">{addComma(+item.currentCost)}</span>
              </p>
            </Col>
          </Row>
        </Media.Body>
      </Media>
    )
  );
};

export default DrugItem;
