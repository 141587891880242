// Tính năng trang Bán hàng
const SALES_ADD = 'sales_add';
const SALES_INVOICES_RETURN = 'sales_invoices_return';
const SALES_INVOICES_CANCEL = 'sales_invoices_cancel';
const SALES_INVOICES_EXPORT = 'sales_invoices_export';
const SALES_INVOICES_IMPORT = 'sales_invoices_import';
const SALES_INVOICES_PRINT = 'sales_invoices_print';
const SALES_INVOICES_STATUS = 'sales_invoices_status';
const SALES_INVOICES_LIST = 'sales_invoices_list';
const SALES_INVOICES_COPY = 'sales_invoices_copy';
const SALES_INVOICES_EXPORT_EINVOICE =  'sales_invoices_export_einvoice';
const SALES_E_INVOICE =  'sales_e_invoice';

export const PERMISSION_SALES_INVOICES = {
  SALES_INVOICES_RETURN: SALES_INVOICES_RETURN,
  SALES_INVOICES_CANCEL: SALES_INVOICES_CANCEL,
  SALES_INVOICES_EXPORT_EINVOICE: SALES_INVOICES_EXPORT_EINVOICE,
  SALES_INVOICES_EXPORT: SALES_INVOICES_EXPORT,
  SALES_INVOICES_IMPORT: SALES_INVOICES_IMPORT,
  SALES_INVOICES_PRINT: SALES_INVOICES_PRINT,
  SALES_INVOICES_STATUS: SALES_INVOICES_STATUS,
  SALES_INVOICES_LIST: SALES_INVOICES_LIST,
  SALES_INVOICES_COPY: SALES_INVOICES_COPY,
  SALES_E_INVOICE: SALES_E_INVOICE,
};

const SALES_RETURN_PRINT = 'sales_return_print';
const SALES_RETURN_EXPORT = 'sales_return_export';
const SALES_RETURN_IMPORT = 'sales_return_import';
const SALES_RETURN_LIST = 'sales_return_list';

export const PERMISSION_SALES_RETURN = {
  SALES_RETURN_PRINT: SALES_RETURN_PRINT,
  SALES_RETURN_EXPORT: SALES_RETURN_EXPORT,
  SALES_RETURN_IMPORT: SALES_RETURN_IMPORT,
  SALES_RETURN_LIST: SALES_RETURN_LIST,
};

const SALES_STATISTIC_LIST = 'sales_statistic_list';
const SALES_STATISTIC_EXPORT = 'sales_statistic_export';

export const PERMISSION_SALES_STATISTIC = {
  SALES_STATISTIC_LIST: SALES_STATISTIC_LIST,
  SALES_STATISTIC_EXPORT: SALES_STATISTIC_EXPORT,
};

const SALES_TEMP_LIST = 'sales_temp_list';
const SALES_TEMP_EXPORT = 'sales_temp_export';
const SALES_TEMP_CANCEL = 'sales_temp_cancel';
const SALES_TEMP_SELL = 'sales_temp_sell';

export const PERMISSION_SALES_TEMP = {
  SALES_TEMP_LIST: SALES_TEMP_LIST,
  SALES_TEMP_EXPORT: SALES_TEMP_EXPORT,
  SALES_TEMP_CANCEL: SALES_TEMP_CANCEL,
  SALES_TEMP_SELL: SALES_TEMP_SELL,
};

const SALES_PAYMENT_HISTORY_LIST = 'sales_payment_history_list';
const SALES_PAYMENT_HISTORY_EXPORT = 'sales_payment_history_export';

const SALES_WITH_POINT = 'sales_with_point';

export const PERMISSION_SALES_PAYMENT_HISTORY = {
  SALES_PAYMENT_HISTORY_LIST: SALES_PAYMENT_HISTORY_LIST,
  SALES_PAYMENT_HISTORY_EXPORT: SALES_PAYMENT_HISTORY_EXPORT,
};

export const PERMISSION_SALES = {
  SALES_ADD: SALES_ADD,
  ...PERMISSION_SALES_INVOICES,
  ...PERMISSION_SALES_RETURN,
  ...PERMISSION_SALES_STATISTIC,
  ...PERMISSION_SALES_TEMP,
  ...PERMISSION_SALES_PAYMENT_HISTORY,
  SALES_WITH_POINT,
};
