import ChartActionTypes from './chart.types';

export const fetchChartDataStart = () => ({
  type: ChartActionTypes.FETCH_CHART_DATA_START,
});

export const fetchChartDataSuccess = (data) => ({
  type: ChartActionTypes.FETCH_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchChartDataFailure = () => ({
  type: ChartActionTypes.FETCH_CHART_DATA_FAILURE,
});
