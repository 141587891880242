//Tính năng trang update
const UPDATE_IMPORT = 'update_import';
const UPDATE_SALES_INVOICE = 'update_sales_invoice';
const UPDATE_RETAIL_INVOICE = 'update_retail_invoice';
const UPDATE_IMPORT_INVOICE = 'update_import_invoice';
const UPDATE_INVENTORY = 'update_inventory';

export const PERMISSION_UPDATE = {
  UPDATE_IMPORT: UPDATE_IMPORT,
  UPDATE_SALES_INVOICE: UPDATE_SALES_INVOICE,
  UPDATE_RETAIL_INVOICE: UPDATE_RETAIL_INVOICE,
  UPDATE_IMPORT_INVOICE: UPDATE_IMPORT_INVOICE,
  UPDATE_INVENTORY: UPDATE_INVENTORY
};

