// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GET_INVOICE_LIST: 'GET_INVOICE_LIST',
  SET_INVOICE_LIST: 'SET_INVOICE_LIST',
  REMOVE_INVOICE_ID: 'REMOVE_INVOICE_ID',
  GET_INVOICE_ID: 'GET_INVOICE_ID',
  SET_INVOICE_ID: 'SET_INVOICE_ID',
  GET_INVOICE_BY_ID: 'GET_INVOICE_BY_ID',
  SET_INVOICE_BY_ID: 'SET_INVOICE_BY_ID',
  GET_INVOICE_STATISTIC: 'GET_INVOICE_STATISTIC',
  SET_INVOICE_STATISTIC: 'SET_INVOICE_STATISTIC',
  RESET_INVOICE_REDUX: 'RESET_INVOICE_REDUX',
};
