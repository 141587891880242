// Tính năng trang Nhập hàng
const WAREHOUSING_ADD = 'warehousing_add';

const WAREHOUSING_INVOICES_RETURN = 'warehousing_invoices_return';
const WAREHOUSING_INVOICES_CANCEL = 'warehousing_invoices_cancel';
const WAREHOUSING_INVOICES_EXPORT = 'warehousing_invoices_export';
const WAREHOUSING_INVOICES_PRINT = 'warehousing_invoices_print';
const WAREHOUSING_INVOICES_STATUS = 'warehousing_invoices_status';
const WAREHOUSING_INVOICES_COPY = 'warehousing_invoices_copy';
const WAREHOUSING_INVOICES_LIST = 'warehousing_invoices_list';

export const PERMISSION_WAREHOUSING_INVOICES = {
  WAREHOUSING_INVOICES_RETURN: WAREHOUSING_INVOICES_RETURN,
  WAREHOUSING_INVOICES_CANCEL: WAREHOUSING_INVOICES_CANCEL,
  WAREHOUSING_INVOICES_EXPORT: WAREHOUSING_INVOICES_EXPORT,
  WAREHOUSING_INVOICES_PRINT: WAREHOUSING_INVOICES_PRINT,
  WAREHOUSING_INVOICES_STATUS: WAREHOUSING_INVOICES_STATUS,
  WAREHOUSING_INVOICES_COPY: WAREHOUSING_INVOICES_COPY,
  WAREHOUSING_INVOICES_LIST: WAREHOUSING_INVOICES_LIST,
};

const WAREHOUSING_RETURN_LIST = 'warehousing_return_list';
const WAREHOUSING_RETURN_PRINT = 'warehousing_return_print';
const WAREHOUSING_RETURN_EXPORT = 'warehousing_return_export';
const WAREHOUSING_RETURN_CANCEL = 'warehousing_return_cancel';

export const PERMISSION_WAREHOUSING_RETURN = {
  WAREHOUSING_RETURN_LIST: WAREHOUSING_RETURN_LIST,
  WAREHOUSING_RETURN_PRINT: WAREHOUSING_RETURN_PRINT,
  WAREHOUSING_RETURN_EXPORT: WAREHOUSING_RETURN_EXPORT,
  WAREHOUSING_RETURN_CANCEL: WAREHOUSING_RETURN_CANCEL,
};

const WAREHOUSING_STATISTIC_LIST = 'warehousing_statistic_list';
const WAREHOUSING_STATISTIC_EXPORT = 'warehousing_statistic_export';

export const PERMISSION_WAREHOUSING_STATISTIC = {
  WAREHOUSING_STATISTIC_LIST: WAREHOUSING_STATISTIC_LIST,
  WAREHOUSING_STATISTIC_EXPORT: WAREHOUSING_STATISTIC_EXPORT,
};

const WAREHOUSING_TEMP_LIST = 'warehousing_temp_list';
const WAREHOUSING_TEMP_EXPORT = 'warehousing_temp_export';
const WAREHOUSING_TEMP_CANCEL = 'warehousing_temp_cancel';
const WAREHOUSING_TEMP_IMPORT = 'warehousing_temp_import';

export const PERMISSION_WAREHOUSING_TEMP = {
  WAREHOUSING_TEMP_LIST: WAREHOUSING_TEMP_LIST,
  WAREHOUSING_TEMP_EXPORT: WAREHOUSING_TEMP_EXPORT,
  WAREHOUSING_TEMP_CANCEL: WAREHOUSING_TEMP_CANCEL,
  WAREHOUSING_TEMP_IMPORT: WAREHOUSING_TEMP_IMPORT,
};

const WAREHOUSING_PAYMENT_HISTORY_LIST = 'warehousing_payment_history_list';
const WAREHOUSING_PAYMENT_HISTORY_EXPORT = 'warehousing_payment_history_export';

export const PERMISSION_WAREHOUSING_PAYMENT_HISTORY = {
  WAREHOUSING_PAYMENT_HISTORY_LIST: WAREHOUSING_PAYMENT_HISTORY_LIST,
  WAREHOUSING_PAYMENT_HISTORY_EXPORT: WAREHOUSING_PAYMENT_HISTORY_EXPORT,
};

export const PERMISSION_WAREHOUSING = {
  WAREHOUSING_ADD: WAREHOUSING_ADD,
  ...PERMISSION_WAREHOUSING_INVOICES,
  ...PERMISSION_WAREHOUSING_RETURN,
  ...PERMISSION_WAREHOUSING_STATISTIC,
  ...PERMISSION_WAREHOUSING_TEMP,
  ...PERMISSION_WAREHOUSING_PAYMENT_HISTORY,
};
