import React, { useEffect, useRef } from 'react';
import sendRequest from './sendRequest';
import moment from 'moment';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import NotiSound from 'assets/sounds/noti.mp3';
import _ from 'lodash';
import Logo from 'components/Logo';
import CustomUIDialog from 'components/CustomUIDialog';

const notiAudio = new Audio(NotiSound);
const DOMAIN = window.location.hostname;
export const APP_BRANCH_GPP = 'GPP';
export const APP_BRANCH_GDP = 'GDP';
export const APP_BRANCH = DOMAIN.search('gdp') >= 0 ? APP_BRANCH_GDP : APP_BRANCH_GPP;
export const APP_BRANCH_LCASE = APP_BRANCH === APP_BRANCH_GDP ? 'gdp' : 'gpp';

export const DEPLOY_MODE =
  DOMAIN.search('localhost') >= 0 || DOMAIN.search('gdp-test') >= 0 || DOMAIN.search('gpp-test') >= 0
    ? 'develop'
    : DOMAIN.search('gpp-staging') >= 0 || DOMAIN.search('gdp-staging') >= 0
    ? 'staging'
    : 'production';
export const DEV_MODE =
  DOMAIN.search('localhost') >= 0 ||
  DOMAIN.search('gdp-test') >= 0 ||
  DOMAIN.search('gpp-test') >= 0 ||
  DOMAIN.search('gpp-staging') >= 0 ||
  DOMAIN.search('gdp-staging') >= 0;

export const APP_TYPE_URL = {
  GPP: DEV_MODE ? 'https://gppdev.sphacy.vn' : 'https://gpp.sphacy.vn',
  GDP: DEV_MODE ? 'https://gdpdev.sphacy.vn' : 'https://gdp.sphacy.vn',
};

export const invoiceMethods = [
  { name: 'Trực tiếp', id: 'direct' },
  { name: 'Online', id: 'online' },
];

export const payMethods = [
  { name: 'Tiền mặt', id: 'cash' },
  { name: 'Chuyển khoản', id: 'banking' },
  { name: 'Thẻ (máy POS)', id: 'card' },
  { name: 'MOMO', id: 'momo' },
  { name: 'VNPay', id: 'vnpay' },
  { name: 'Hình thức khác', id: 'other' },
];

export const promotionStatus = {
  pending: 'Chưa áp dụng',
  running: 'Đang áp dụng',
  pause: 'Tạm dừng',
  ended: 'Ngừng áp dụng',
};

export const addComma = (x, y = 0) => {
  return x != null
    ? parseFloat(x)
      ? round(parseFloat(x), y)
          .toString()
          .replace('.', ',')
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      : x
    : '0';
};

export const unComma = (_, value) => {
  if (value == null) value = '';
  return parseInt(value.toString().replaceAll('.', ''));
};

export const round = (x, y = 0) => {
  return Math.round(x * 10 ** y) / 10 ** y || 0;
};

export const defineRichtext = (value) => {
  if (value.richText) {
    return value.richText[0].text;
  } else {
    return value;
  }
};

export const generateDates = (fromDate, dateCount, options) => {
  options = options || {};
  const oneDay = 1000 * 86400;
  let curTic = fromDate.getTime();
  let toDate = curTic + dateCount * oneDay;
  let step = 1;
  let arr = [];
  if (dateCount < 0) {
    let a = curTic;
    curTic = toDate;
    toDate = a;
  }
  if (options.backward === true) {
    step = -1;
    let a = curTic;
    curTic = toDate;
    toDate = a;
  }
  while (curTic <= toDate) {
    arr.push(new Date(curTic));
    curTic += step * oneDay;
  }
  return arr;
};

export const fetchData = async (url, callback, options) => {
  let data = await sendRequest({
    url,
    ...options,
  });
  callback(data);
};

export const showLoader = (status = true) => {
  const loaderContainer = document.getElementsByClassName('_loaderContainer')[0];
  if (loaderContainer) {
    if (status) {
      loaderContainer.classList.remove('hidden');
      document.body.classList.add('_noselect');
    } else {
      loaderContainer.classList.add('hidden');
      document.body.classList.remove('_noselect');
    }
  }
};

export const showConfirmDialog = ({
  title = 'SPHACY ' + APP_BRANCH,
  message = 'Lưu thông tin?',
  customButtons = [],
  cancelText = 'Huỷ',
  cancelAction,
  defaultText = 'Đồng ý',
  defaultAction,
  children,
  closeOnClickOutside = true,
  closeOnEscape = true,
  ...options
}) => {
  confirmAlert({
    closeOnEscape,
    closeOnClickOutside,
    overlayClassName: options.overlayClassName || '',
    customUI: ({ onClose }) => (
      <CustomUIDialog
        {...{
          options: {
            title,
            message,
            customButtons,
            cancelText,
            cancelAction,
            defaultText,
            defaultAction,
            ...options,
          },
          children,
          onClose,
        }}
      />
    ),
  });
};

export const showAlertDialog = (message, callback, labelButton = 'OK') => {
  let alertOptions = {
    title: 'SPHACY ' + APP_BRANCH,
    message: message,
    buttons: [{ label: labelButton }],
    closeOnEscape: false,
    closeOnClickOutside: false,
    afterClose: callback,
  };
  confirmAlert(alertOptions);
};

export const readMoney = (number) => {
  if (number > 999999999) {
    var number_ = number / 1000000000;
    return { value: round(number_, 2), unit: 'Tỷ' };
  } else if (number > 999999) {
    var number_ = number / 1000000;
    return { value: round(number_, 2), unit: 'Triệu' };
  } else if (number > 999) {
    var number_ = number / 1000;
    return { value: round(number_, 2), unit: 'Nghìn' };
  } else {
    var number_ = number / 1;
    return { value: round(number_, 2), unit: 'VNĐ' };
  }
};

export const readMoneyShort = (number) => {
  if (Math.abs(number) > 999999999) {
    var number_ = number / 1000000000;
    return round(number_, 2) + 'B';
  } else if (Math.abs(number) > 999999) {
    var number_ = number / 1000000;
    return round(number_, 2) + 'M';
  } else if (Math.abs(number) > 999) {
    var number_ = number / 1000;
    return round(number_, 2) + 'K';
  } else {
    var number_ = number / 1;
    return round(number_, 2) + 'đ';
  }
};

export const showToast = (toastContent, toastConfig = 'error') => {
  let toastType = 'error';
  if (typeof toastConfig === 'string') {
    toastType = toastConfig;
  } else if (toastConfig?.type) {
    toastType = toastConfig.type;
  }
  if (toastType === 'default') {
    toast(toastContent, toastConfig || {});
  } else {
    toast[toastType](toastContent, toastConfig || {});
  }
};

export const showNotification = (userOptions) => {
  const title = 'SPHACY ' + APP_BRANCH;
  let options = {
    icon: Logo,
  };
  if (typeof userOptions === 'string') {
    options['body'] = userOptions;
  } else {
    options = { ...options, ...userOptions };
  }

  let notification = null;
  // Check browser notification support
  if (!('Notification' in window)) {
    return;

    // Check notification permission
  } else if (Notification.permission === 'granted') {
    // If it's okay let's create a notification
    notification = new Notification(title, options);

    // ask for permission
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts
      if (permission === 'granted') {
        notification = new Notification(title, options);
      }
    });
  }
  if (!notification) {
    return;
  }
  notiAudio.play();
  notification.onclick = (e) => {
    e.preventDefault();
    notification.close();
    if (userOptions?.onClick) {
      userOptions.onClick(e);
    }
  };
};
export const getValueForPath = (obj, path) => {
  try {
    const arrPath = path.split('.');
    let values = { ...obj };
    for (let item of arrPath) {
      values = values[`${item}`];
    }
    return values;
  } catch (e) {
    return undefined;
  }
};
export const commonFormikSubmit = (e, formik, callback) => {
  let formEm = e.target;
  e.preventDefault();
  formik.handleSubmit();
  if (JSON.stringify(formik.errors) !== '{}') {
    // showToast('Thông tin không hợp lệ, vui lòng kiểm tra lại.');
    setTimeout(() => {
      let firstInvalid = formEm.querySelector('.form-control.is-invalid');
      if (firstInvalid !== null) {
        firstInvalid.focus();
      }
    }, 0);
  }
};

export const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_.camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export const formatNameDrug = (string) => {
  var newString = string.replace('\n', '');
  var drugName = newString.replace(/.*?"(.*)".*/, '$1');
  if (drugName.length < newString.length) {
    return drugName;
  } else if (newString.indexOf('(') > -1) {
    return newString.slice(0, newString.indexOf(' ('));
  } else {
    return newString;
  }
};

export const decamelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => decamelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [decamelize(key)]: decamelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export const findAsync = async (arr, asyncCallback) => {
  const promises = arr.map(asyncCallback);
  const results = await Promise.all(promises);
  const index = results.findIndex(result => result);
  return arr[index];
}

const decamelize = (str, separator = '_') => {
  return str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase();
};

export const objectToQueryString = (params = {}) => {
  return Object.keys(params)
    .map((key) => (params[key] || typeof params[key] === 'boolean' ? `${key}=${params[key]}` : null))
    .filter((item) => item != null)
    .join('&');
};

export const queryStringToObject = (str) => {
  let obj = {};
  str.split(/[?&]+/g).forEach((s) => {
    let arr = s.split('=', 1);
    if (!_.trim(arr[0])) {
      return;
    }
    obj[arr[0]] = s.substr(arr[0].length + 1) || '';
  });
  return obj;
};

/**
 * Chuẩn hóa kí tự đầu vào: Loại bỏ dấu
 * @param {*} inputString
 * @returns
 */
export const removeAccent = (inputString) => {
  return inputString
    ?.normalize('NFD')
    ?.replace(/[\u0300-\u036f]/g, '')
    ?.replace(/[Đđ]/g, (a) => ({ Đ: 'D', đ: 'd' }[a]));
};

export const getElementPosition = (node) => {
  let currentTop = 0;
  let currentScrollTop = document.documentElement.scrollTop;

  if (node.offsetParent) {
    do {
      currentTop += node.offsetTop;
      currentScrollTop += node.offsetParent ? node.offsetParent.scrollTop : 0;
    } while ((node = node.offsetParent));
  }
  return {
    currentTop,
    currentScrollTop,
    currentScreenTop: currentTop - currentScrollTop,
  };
};

export const rem2Px = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const formatDate = (inputDate, formatString = 'DD/MM/YYYY') => {
  if (inputDate) {
    return moment(inputDate).format(formatString);
  } else {
    return undefined;
  }
};

export const getFilteredItems = (items, filterText, filterField = 'name') => {
  const filters = removeAccent(filterText?.toLowerCase()).split(/[ \t\r\n]+/g);
  return filters[0] === ''
    ? items
    : items.filter((item) => {
        let filterData = removeAccent(item[filterField]?.toLowerCase());
        return filters?.filter((filter) => filterData?.indexOf(filter) > -1).length === filters.length;
      });
};

export const getLastMonthsDate = (months = 1) => {
  let curDate = new Date();
  return new Date(curDate.getFullYear(), curDate.getMonth() - months, curDate.getDate() + 1);
};

export const getFirstDOM = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getLastDOM = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const truncTime = (date = new Date()) => {
  try {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    date.setHours(7, 0, 0, 0);
    return date;
  } catch (e) {}
  return null;
};

export const filterUniqueByKey = (arrObj, key) => {
  return (arrObj =
    arrObj.filter((value, index, self) => {
      return self.findIndex((e) => e[key] === value[key]) === index;
    }) || []);
};

export const getScanData = (scanCode) => {
  // try {
  //   return JSON.parse(scanCode);
  // } catch (e) { }
  let m = /^((DQG|DRUG|SP|PT)[0-9]+)_(.+)/.exec(scanCode);
  if (m?.length || 0 >= 4) {
    return { drugCode: m[1], number: m[3] };
  }

  m = /^01([0-9]{13,14})((17[0-9]{6})|(10((?!17).)+))((17[0-9]{6})|(10((?!21).)+)).+/.exec(scanCode);
  if (m?.length || 0 >= 10) {
    return {
      barcode: m[1],
      number: (m[4] || m[8] || '').substr(2),
      expiredDate: moment((m[3] || m[7] || '').substr(2), 'YYMMDD').toDate(),
    };
  }
  m = scanCode.split('_');
  if (m[0]) {
    return {
      scanCode: m[0],
      number: m[1],
    };
  }

  return { scanCode };
};

export const hidePhoneNumber  = (phoneNumber) => {
  if (phoneNumber.length < 10) return phoneNumber;
  return phoneNumber.replace(/(\d{2})\d+(\d{2})$/, '$1******$2');
}

export const jsonDiff = (obj1, obj2) => {
  const result = {};
  if (Object.is(obj1, obj2) || JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = jsonDiff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
};

export const cloneObj = (obj) => _.cloneDeep(obj);

export const processDateFields = (data) => {
  return Object.keys(data).reduce((result, key) => {
    let val = data[key];
    if (val instanceof Date) {
      result[key] = formatDate(val);
    } else {
      result[key] = val;
    }
    return result;
  }, {});
};

export const titleToFileName = (title) => removeAccent(title?.replace(/ [a-z]/g, (a) => a.toUpperCase()).replace(/[ \r\t\n]/g, ''));

export const addChar = (c, inc = 1) => String.fromCharCode(c.charCodeAt(0) + inc);

export const isTypeDate = (date) => Object.prototype.toString.call(date) === '[object Date]';

export const checkEndDateWarning = (endDate, checkDate) => {
  if (endDate) {
    return (
      moment(endDate).utcOffset(0, true).unix() - (checkDate ? moment(checkDate).utcOffset(0, true).unix() : moment().utcOffset(0, true).unix()) <=
      86400 * 7
    );
  } else {
    return true;
  }
};

// auto generate object value = object key
export const processActions = (actions) => {
  Object.keys(actions).forEach((item) => {
    actions[item] = item;
  });
};

export const getLocationQueries = () => {
  let queryString = window.location.search;
  if (queryString.indexOf('?') !== 0) {
    return {};
  }
  queryString = queryString.substr(1);
  return queryString.split('&').reduce((a, b) => {
    let arr = b.split('=');
    return { ...a, [arr[0]]: arr[1] };
  }, {});
};

export const adjustDate = (adjDate = new Date(), number = 0) => {
  const isDate = adjDate instanceof Date;
  const adjustedDate = isDate ? new Date(adjDate) : new Date();
  adjustedDate.setDate(adjustedDate.getDate() + (isDate ? number : adjDate));
  return adjustedDate;
};
export const mapDrugBatches = (numbers, units, id) => {
  if (!numbers) {
    return [];
  }
  return numbers.reduce((a, b) => {
    const quantityBasic = b.quantity; // dvt tồn kho cơ bản
    // if (quantityBasic === 0) {
    //   return [...a];
    // }
    return [
      ...a,
      ...units.map((c) => {
        // map quantity ( đơn vị tính)
        let quantity = 0;
        if (c.isBasic === 'no' && c.exchange !== 1) {
          quantity = Math.floor(quantityBasic / c.exchange);
        } else {
          quantity = quantityBasic;
        }
        return {
          ...b,
          ...c,
          drugId: id,
          quantity,
        };
      }),
    ];
  }, []);
};

export const convertToVietnameseDate = (dateTimeStr) => {
  // Kiểm tra xem chuỗi đầu vào có đúng định dạng không
  dateTimeStr = dateTimeStr.slice(0, 8);
  const regex = /^(\d{4})(\d{2})(\d{2})$/;
  const match = dateTimeStr.match(regex);
  if (!match) {
    return 'Invalid date format';
  }

  const year = match[1];
  const month = parseInt(match[2], 10);
  const vietnameseMonths = [
    'Tháng 1',
    'Tháng 2',
    'Tháng 3',
    'Tháng 4',
    'Tháng 5',
    'Tháng 6',
    'Tháng 7',
    'Tháng 8',
    'Tháng 9',
    'Tháng 10',
    'Tháng 11',
    'Tháng 12',
  ];

  return `${vietnameseMonths[month - 1]} Năm ${year}`;
};
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
export const useOnClickOutside = (ref, handler, clas) => {
  const listener = (event) => {
    if (!ref.current || ref.current.contains(event.target) || event.path.filter((i) => i.className === clas).length !== 0) {
      return;
    }
    handler(event);
  };
  useEffect(() => {
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

export const inArray = (needle, haystack) => {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i] === needle) return true;
  }
  return false;
};

/**
 * Chuyển từ unicode tổ hợp sang unicode dựng sẵn
 * @param {*} str
 * @returns
 */
export const compoundUnicode = (str) => {
  return `${str}`
    .replace(/\u0065\u0309/g, '\u1EBB') //  ẻ
    .replace(/\u0065\u0301/g, '\u00E9') //  é
    .replace(/\u0065\u0300/g, '\u00E8') //  è
    .replace(/\u0065\u0323/g, '\u1EB9') //  ẹ
    .replace(/\u0065\u0303/g, '\u1EBD') //  ẽ
    .replace(/\u00EA\u0309/g, '\u1EC3') //  ể
    .replace(/\u00EA\u0301/g, '\u1EBF') //  ế
    .replace(/\u00EA\u0300/g, '\u1EC1') //  ề
    .replace(/\u00EA\u0323/g, '\u1EC7') //  ệ
    .replace(/\u00EA\u0303/g, '\u1EC5') //  ễ
    .replace(/\u0079\u0309/g, '\u1EF7') //  ỷ
    .replace(/\u0079\u0301/g, '\u00FD') //  ý
    .replace(/\u0079\u0300/g, '\u1EF3') //  ỳ
    .replace(/\u0079\u0323/g, '\u1EF5') //  ỵ
    .replace(/\u0079\u0303/g, '\u1EF9') //  ỹ
    .replace(/\u0075\u0309/g, '\u1EE7') //  ủ
    .replace(/\u0075\u0301/g, '\u00FA') //  ú
    .replace(/\u0075\u0300/g, '\u00F9') //  ù
    .replace(/\u0075\u0323/g, '\u1EE5') //  ụ
    .replace(/\u0075\u0303/g, '\u0169') //  ũ
    .replace(/\u01B0\u0309/g, '\u1EED') //  ử
    .replace(/\u01B0\u0301/g, '\u1EE9') //  ứ
    .replace(/\u01B0\u0300/g, '\u1EEB') //  ừ
    .replace(/\u01B0\u0323/g, '\u1EF1') //  ự
    .replace(/\u01B0\u0303/g, '\u1EEF') //  ữ
    .replace(/\u0069\u0309/g, '\u1EC9') //  ỉ
    .replace(/\u0069\u0301/g, '\u00ED') //  í
    .replace(/\u0069\u0300/g, '\u00EC') //  ì
    .replace(/\u0069\u0323/g, '\u1ECB') //  ị
    .replace(/\u0069\u0303/g, '\u0129') //  ĩ
    .replace(/\u006F\u0309/g, '\u1ECF') //  ỏ
    .replace(/\u006F\u0301/g, '\u00F3') //  ó
    .replace(/\u006F\u0300/g, '\u00F2') //  ò
    .replace(/\u006F\u0323/g, '\u1ECD') //  ọ
    .replace(/\u006F\u0303/g, '\u00F5') //  õ
    .replace(/\u01A1\u0309/g, '\u1EDF') //  ở
    .replace(/\u01A1\u0301/g, '\u1EDB') //  ớ
    .replace(/\u01A1\u0300/g, '\u1EDD') //  ờ
    .replace(/\u01A1\u0323/g, '\u1EE3') //  ợ
    .replace(/\u01A1\u0303/g, '\u1EE1') //  ỡ
    .replace(/\u00F4\u0309/g, '\u1ED5') //  ổ
    .replace(/\u00F4\u0301/g, '\u1ED1') //  ố
    .replace(/\u00F4\u0300/g, '\u1ED3') //  ồ
    .replace(/\u00F4\u0323/g, '\u1ED9') //  ộ
    .replace(/\u00F4\u0303/g, '\u1ED7') //  ỗ
    .replace(/\u0061\u0309/g, '\u1EA3') //  ả
    .replace(/\u0061\u0301/g, '\u00E1') //  á
    .replace(/\u0061\u0300/g, '\u00E0') //  à
    .replace(/\u0061\u0323/g, '\u1EA1') //  ạ
    .replace(/\u0061\u0303/g, '\u00E3') //  ã
    .replace(/\u0103\u0309/g, '\u1EB3') //  ẳ
    .replace(/\u0103\u0301/g, '\u1EAF') //  ắ
    .replace(/\u0103\u0300/g, '\u1EB1') //  ằ
    .replace(/\u0103\u0323/g, '\u1EB7') //  ặ
    .replace(/\u0103\u0303/g, '\u1EB5') //  ẵ
    .replace(/\u00E2\u0309/g, '\u1EA9') //  ẩ
    .replace(/\u00E2\u0301/g, '\u1EA5') //  ấ
    .replace(/\u00E2\u0300/g, '\u1EA7') //  ầ
    .replace(/\u00E2\u0323/g, '\u1EAD') //  ậ
    .replace(/\u00E2\u0303/g, '\u1EAB') //  ẫ
    .replace(/\u0045\u0309/g, '\u1EBA') //  Ẻ
    .replace(/\u0045\u0301/g, '\u00C9') //  É
    .replace(/\u0045\u0300/g, '\u00C8') //  È
    .replace(/\u0045\u0323/g, '\u1EB8') //  Ẹ
    .replace(/\u0045\u0303/g, '\u1EBC') //  Ẽ
    .replace(/\u00CA\u0309/g, '\u1EC2') //  Ể
    .replace(/\u00CA\u0301/g, '\u1EBE') //  Ế
    .replace(/\u00CA\u0300/g, '\u1EC0') //  Ề
    .replace(/\u00CA\u0323/g, '\u1EC6') //  Ệ
    .replace(/\u00CA\u0303/g, '\u1EC4') //  Ễ
    .replace(/\u0059\u0309/g, '\u1EF6') //  Ỷ
    .replace(/\u0059\u0301/g, '\u00DD') //  Ý
    .replace(/\u0059\u0300/g, '\u1EF2') //  Ỳ
    .replace(/\u0059\u0323/g, '\u1EF4') //  Ỵ
    .replace(/\u0059\u0303/g, '\u1EF8') //  Ỹ
    .replace(/\u0055\u0309/g, '\u1EE6') //  Ủ
    .replace(/\u0055\u0301/g, '\u00DA') //  Ú
    .replace(/\u0055\u0300/g, '\u00D9') //  Ù
    .replace(/\u0055\u0323/g, '\u1EE4') //  Ụ
    .replace(/\u0055\u0303/g, '\u0168') //  Ũ
    .replace(/\u01AF\u0309/g, '\u1EEC') //  Ử
    .replace(/\u01AF\u0301/g, '\u1EE8') //  Ứ
    .replace(/\u01AF\u0300/g, '\u1EEA') //  Ừ
    .replace(/\u01AF\u0323/g, '\u1EF0') //  Ự
    .replace(/\u01AF\u0303/g, '\u1EEE') //  Ữ
    .replace(/\u0049\u0309/g, '\u1EC8') //  Ỉ
    .replace(/\u0049\u0301/g, '\u00CD') //  Í
    .replace(/\u0049\u0300/g, '\u00CC') //  Ì
    .replace(/\u0049\u0323/g, '\u1ECA') //  Ị
    .replace(/\u0049\u0303/g, '\u0128') //  Ĩ
    .replace(/\u004F\u0309/g, '\u1ECE') //  Ỏ
    .replace(/\u004F\u0301/g, '\u00D3') //  Ó
    .replace(/\u004F\u0300/g, '\u00D2') //  Ò
    .replace(/\u004F\u0323/g, '\u1ECC') //  Ọ
    .replace(/\u004F\u0303/g, '\u00D5') //  Õ
    .replace(/\u01A0\u0309/g, '\u1EDE') //  Ở
    .replace(/\u01A0\u0301/g, '\u1EDA') //  Ớ
    .replace(/\u01A0\u0300/g, '\u1EDC') //  Ờ
    .replace(/\u01A0\u0323/g, '\u1EE2') //  Ợ
    .replace(/\u01A0\u0303/g, '\u1EE0') //  Ỡ
    .replace(/\u00D4\u0309/g, '\u1ED4') //  Ổ
    .replace(/\u00D4\u0301/g, '\u1ED0') //  Ố
    .replace(/\u00D4\u0300/g, '\u1ED2') //  Ồ
    .replace(/\u00D4\u0323/g, '\u1ED8') //  Ộ
    .replace(/\u00D4\u0303/g, '\u1ED6') //  Ỗ
    .replace(/\u0041\u0309/g, '\u1EA2') //  Ả
    .replace(/\u0041\u0301/g, '\u00C1') //  Á
    .replace(/\u0041\u0300/g, '\u00C0') //  À
    .replace(/\u0041\u0323/g, '\u1EA0') //  Ạ
    .replace(/\u0041\u0303/g, '\u00C3') //  Ã
    .replace(/\u0102\u0309/g, '\u1EB2') //  Ẳ
    .replace(/\u0102\u0301/g, '\u1EAE') //  Ắ
    .replace(/\u0102\u0300/g, '\u1EB0') //  Ằ
    .replace(/\u0102\u0323/g, '\u1EB6') //  Ặ
    .replace(/\u0102\u0303/g, '\u1EB4') //  Ẵ
    .replace(/\u00C2\u0309/g, '\u1EA8') //  Ẩ
    .replace(/\u00C2\u0301/g, '\u1EA4') //  Ấ
    .replace(/\u00C2\u0300/g, '\u1EA6') //  Ầ
    .replace(/\u00C2\u0323/g, '\u1EAC') //  Ậ
    .replace(/\u00C2\u0303/g, '\u1EAA'); //  Ẫ
};

export const capitalize = (str) => {
  if (!str) {
    return '';
  }

  const arrOfWords = str.split(' ');
  const arrOfWordsCased = [];

  for (let i = 0; i < arrOfWords.length; i++) {
    const word = arrOfWords[i];
    if (!word) {
      continue;
    }

    arrOfWordsCased.push(word[0].toUpperCase() + word.slice(1).toLowerCase());
  }

  return arrOfWordsCased.join(' ');
};

export const responseMsgVNPay = {
  '01': 'Không tìm thấy mã đơn hàng',
  '02': 'Không tìm thấy mã đơn hàng',
  '04': 'Số tiền không hợp lệ',
  '05': 'Giao dịch không thành công do: Tài khoản của quý khách không đủ số dư để thực hiện giao dịch.',
  '06': 'Giao dịch không thành công do Quý khách nhập sai mật khẩu xác thực giao dịch (OTP). Xin quý khách vui lòng thực hiện lại giao dịch.',
  '09': 'Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng chưa đăng ký dịch vụ InternetBanking tại ngân hàng.',
  10: 'Giao dịch không thành công do: Khách hàng xác thực thông tin thẻ/tài khoản không đúng quá 3 lần',
  11: 'Giao dịch không thành công do: Đã hết hạn chờ thanh toán. Xin quý khách vui lòng thực hiện lại giao dịch.',
  12: 'Giao dịch không thành công do: Thẻ/Tài khoản của khách hàng bị khóa.',
  24: 'Giao dịch không thành công do: Khách hàng hủy giao dịch',
  79: 'Giao dịch không thành công do: Quý khách nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch',
  65: 'Giao dịch không thành công do: Quý khách nhập sai mật khẩu thanh toán quá số lần quy định. Xin quý khách vui lòng thực hiện lại giao dịch',
  75: 'Ngân hàng thanh toán đang bảo trì',
  99: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
};

export const getValueFromObject = (object) => {
  let values = [];
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(object)) {
    values.push(value);
  }
  return values;
};

export const getValueFromArray = (array) => {
  let values = [];
  array.map((item) => {
    if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
      values = [...values, ...getValueFromObject(item)];
    } else {
      values.push(item);
    }
    return null;
  });
  return values;
};

export const getCookie = (name) => {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith(name + '='))
    ?.split('=')[1];
  return cookieValue;
};

export const saveCookies = (string) => {
  var LastReportGenerated = 'Jul 11 2013',
    baseDomain = '.sphacy.vn',
    expireAfter = new Date();
  //setting up  cookie expire date after a week
  expireAfter.setDate(expireAfter.getDate() + 7);
  //now setup cookie
  document.cookie = "auth={'json':" + string + '}; domain=' + baseDomain + '; expires=' + expireAfter + '; path=/';
};
