// Tính năng trang Quản lý đặt hàng
const ORDER_MANAGE_STATUS = 'order_manage_status';
const ORDER_MANAGE_CANCEL = 'order_manage_cancel';
const ORDER_MANAGE_CREATE = 'order_manage_create';
const ORDER_MANAGE_EXPORT = 'order_manage_export';
const ORDER_MANAGE_PRINT = 'order_manage_print';
const ORDER_MANAGE_LIST = 'order_manage_list';
const ORDER_MANAGE_STATISTIC_LIST = 'order_manage_statistic_list';
const ORDER_MANAGE_STATISTIC_EXPORT = 'order_manage_statistic_export';

export const PERMISSION_ORDER_MANAGE = {
  ORDER_MANAGE_STATUS: ORDER_MANAGE_STATUS,
  ORDER_MANAGE_CANCEL: ORDER_MANAGE_CANCEL,
  ORDER_MANAGE_CREATE: ORDER_MANAGE_CREATE,
  ORDER_MANAGE_EXPORT: ORDER_MANAGE_EXPORT,
  ORDER_MANAGE_PRINT: ORDER_MANAGE_PRINT,
  ORDER_MANAGE_LIST: ORDER_MANAGE_LIST,
  ORDER_MANAGE_STATISTIC_LIST: ORDER_MANAGE_STATISTIC_LIST,
  ORDER_MANAGE_STATISTIC_EXPORT: ORDER_MANAGE_STATISTIC_EXPORT,
};
