import types from './master.types';

const INITIAL_STATE = {
  isFetching: true,
  customers: [],
  suppliers: [],
  customerId: null,
};

const masterReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GET_CUSTOMER_LIST:
      return {
        ...state,
        isFetching: true,
      };

    case types.SET_CUSTOMER_LIST:
      return {
        ...state,
        customers: payload,
      };

    case types.SET_SUPPLIER_LIST:
      return {
        ...state,
        suppliers: payload,
      };

    case types.DONE_POST_CUSTOMER:
      const customers = state.customers;
      if (payload) customers.unshift(payload);
      return {
        ...state,
        customers: [...customers],
        customerId: payload?.id || -1,
      };

    case types.DONE_POST_SUPPLIER:
      const suppliers = state.suppliers;
      if (payload) suppliers.unshift(payload);
      return {
        ...state,
        suppliers: [...suppliers],
        customerId: payload?.id || -1,
      };

    case types.SET_CUSTOMER_ID:
      return { ...state, customerId: payload };

    case types.RESET_SALES_REDUX:
      return { ...INITIAL_STATE };

    default:
      return { ...state, isFetching: false };
  }
};

export default masterReducer;
