import { createSelector } from 'reselect';
import { roleAction, roleGDP, roleGPP } from 'permission/role';
import { APP_BRANCH, APP_BRANCH_GDP, APP_BRANCH_GPP } from 'utils/util';
import { PERMISSION_DRUG } from 'permission/resources/drug';

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector([selectUser], (user) => user.currentUser);

export const selectSystemWarningDate = createSelector([selectUser], (user) =>
  !!user.currentUser?.drugStore?.warningDate ? user.currentUser?.drugStore?.warningDate : 180
);

export const selectIsFetchingUser = createSelector([selectUser], (user) => user.isFetching);

export const selectUserToken = createSelector([selectCurrentUser], (currentUser) => currentUser.token);

export const selectUserSignInErrorMessage = createSelector([selectUser], (user) => user.error);

export const selectRefreshDate = createSelector([selectUser], (user) => user.refreshDate);

export const selectNotiTime = createSelector([selectUser], (user) => {
  let result = user.notiTime;
  try {
    if (typeof result === 'string') {
      result = new Date(result);
    }
  } catch (e) {
    result = null;
  }
  return result;
});

export const selectNotification = createSelector([selectUser], (user) => user.notification);

export const selectUsername = createSelector([selectUser], (user) => user.username);

export const selectUserPermissions = createSelector([selectUser], (user) => {
  if (!user?.currentUser?.userinfo) {
    return {};
  }
  let role = user.currentUser.userinfo?.userRole || 'admin';
  let additionPermissions = user?.currentUser?.userinfo?.permission || [];
  let result = {};
  let _roleGDP = JSON.parse(JSON.stringify(roleGDP))
  let _roleGPP = JSON.parse(JSON.stringify(roleGPP))
  let roleDetail =
    additionPermissions.includes('system') && APP_BRANCH === APP_BRANCH_GDP
      ? roleGDP['system']
      : APP_BRANCH === APP_BRANCH_GDP
      ? _roleGDP[role]
      : _roleGPP[role];
  if (!roleDetail) {
    return result;
  }
  if (user.currentUser.userinfo?.agencyStatus != 1 && APP_BRANCH == APP_BRANCH_GPP) {
    roleDetail.excludes = [...roleDetail.excludes, ...roleAction];
  }
  let excludes = roleDetail.excludes ?? [];
  let resources = roleDetail.resources ?? [];
  resources.forEach((p) => {
    if (!excludes.includes(p)) {
      result[p] = true;
    }
  });
  return result;
});
