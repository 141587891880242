import { call, takeLatest, put, takeEvery } from 'redux-saga/effects';
import types from './report.types';
import urls from 'config/urls';
import sendRequest from 'utils/sendRequest';
import actions from './report.actions';
import { ItemType } from 'pages/report/partials/AddItemComponent';

function* fetchReportRevenueAndGrossProfit({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.report.revenueAndGrossProfit,
      params: payload,
    });
    yield put(actions.setReportRevenueAndGrossProfit(data));
  } catch (ex) {
    console.log('[report.sagas.js] ex: ', ex);
  }
}

function* fetchReportInventory({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.report.inventory,
      params: payload,
    });
    yield put(actions.setReportInventory(data));
  } catch (ex) {
    console.log('[report.sagas.js] ex: ', ex);
  }
}

function* fetchReportSalesPrescription({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.report.salesPrescription,
      params: payload,
    });
    yield put(actions.setReportSalesPrescription(data));
  } catch (ex) {
    console.log('[report.sagas.js] ex: ', ex);
  }
}

function* fetchReportQuantityControlList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.report.qualityControlList,
      params: payload,
    });
    yield put(actions.setReportQuantityControlList(data));
  } catch (ex) {
    console.log('[report.sagas.js] ex: ', ex);
  }
}

function* fetchReportQuantityControlDrugList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: `${urls.sales.drugDetail}/${payload.drugId || payload.id}`,
    });
    yield put(
      actions.setReportQuantityControlDrugList({ ...payload, drugUnits: data })
    );
  } catch (ex) {
    console.log('[report.sagas.js] ex: ', ex);
  }
}

function* postReportQuantityControlDrugList({ payload }) {
  try {
    const { type, ...body } = payload;

    let url = urls.report.qualityControlSave;
    if (type === ItemType.CheckInventory) url = urls.report.checkInventory;
    if (type === ItemType.ReportExport) url = urls.invoice.save;

    const data = yield call(sendRequest, {
      url,
      body,
      method: 'POST',
    });
    if (type === ItemType.ReportExport) {
      yield put(actions.setReportExportId(data?.invoice?.id));
    } else if (type === ItemType.CheckInventory) {
      yield put(actions.setCheckInventoryId(data));
    } else {
      yield put(actions.setReportQuantityControlId(data?.id || true));
    }
  } catch (ex) {
    console.log('[report.sagas.js] ex: ', ex);
  }
}

function* fetchReportWarehouse({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.report.warehouse,
      params: payload,
    });
    yield put(actions.setReportWarehouse(data));
  } catch (ex) {
    console.log('[report.sagas.js] ex: ', ex);
  }
}

function* fetchSpecialDrug({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.report.specialDrug,
      params: payload,
    });
    yield put(actions.setSpecialDrug(data));
  } catch (ex) {
    console.log('[report.sagas.js] ex: ', ex);
  }
}

function* fetchCheckInventory({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.report.checkInventory,
      params: payload,
    });
    yield put(actions.setCheckInventory(data));
  } catch (ex) {
    console.log('[report.sagas.js] ex: ', ex);
  }
}

export function* reportSagas() {
  yield takeLatest(
    types.GET_REPORT_REVENUE_AND_GROSS_PROFIT,
    fetchReportRevenueAndGrossProfit
  );
  yield takeLatest(types.GET_REPORT_INVENTORY, fetchReportInventory);
  yield takeLatest(
    types.GET_REPORT_SALES_PRESCRIPTION,
    fetchReportSalesPrescription
  );
  yield takeLatest(
    types.GET_REPORT_QUALITY_CONTROL_LIST,
    fetchReportQuantityControlList
  );
  yield takeEvery(
    types.GET_REPORT_QUALITY_CONTROL_DRUG_LIST,
    fetchReportQuantityControlDrugList
  );
  yield takeLatest(
    types.POST_REPORT_QUALITY_CONTROL_DRUG_LIST,
    postReportQuantityControlDrugList
  );
  yield takeLatest(types.GET_REPORT_WAREHOUSE, fetchReportWarehouse);
  yield takeLatest(types.GET_SPECIAL_DRUG, fetchSpecialDrug);
  yield takeLatest(types.GET_CHECK_INVENTORY, fetchCheckInventory);
}
