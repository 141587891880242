import React from 'react';
import './Radio.scss';

const Radio = ({ name, label, options, value, onChange = (f) => f }) => {
  return (
    <div className="radio-group">
      {/* {label ? <div className="label">{label}</div> : ''} */}
      {options &&
        options.map((item, index) => (
          <label key={index}>
            <input type="radio" value={item.value} name={name} checked={value === item.value} onChange={onChange} />
            <span className="label-text">{item.name}</span>
            <span className="checkmark"></span>
            {item.customElement ? item.customElement : null}
          </label>
        ))}
    </div>
  );
};

export default Radio;
