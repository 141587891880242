import _ from 'lodash';
import { cloneObj, mapDrugBatches } from 'utils/util';
import types from './sales.types';

export const SALES_UNIT_TYPE = 'SALES_UNIT_TYPE';
export const SALES_COMBO_TYPE = 'SALES_COMBO_TYPE';
export const SALES_PRESCRIPTION_TYPE = 'SALES_PRESCRIPTION_TYPE';
export const RESET_SALES_PRESCRIPTION = 'RESET_SALES_PRESCRIPTION';
export const SALES_PRESCRIPTION_NATION_TYPE = 'SALES_PRESCRIPTION_NATION_TYPE';
export const SALES_COPY_TYPE = 'SALES_COPY_TYPE';
export const SALES_SESSION_STORAGE_TYPE = 'SALES_SESSION_STORAGE_TYPE';
export const SHIPPING = 'SHIPPING';
export const SUBMIT_TYPE_DEFAULT = 'SUBMIT_TYPE_DEFAULT';
export const SUBMIT_TYPE_PENDING = 'SUBMIT_TYPE_PENDING';
export const SUBMIT_TYPE_VALIDATED = 'SUBMIT_TYPE_VALIDATED';

const TYPE_COMBO_COPY = 'TYPE_COMBO_COPY';
const checkUnitsDuplication = (arr) => {
  const valids = [];
  let isEmpty = true;
  const sameIndexs = arr
    .map((item, index) => {
      if (item !== undefined) {
        isEmpty = false;
        if (arr.findIndex((i) => item?.drugCode === i?.drugCode && item?.number === i?.number && item?.unitId === i?.unitId) !== index) {
          return index;
        }
      }
    })
    .filter((item) => item !== undefined);
  for (let index = 0; index < arr.length; index++) {
    if (sameIndexs.includes(index)) valids[index] = { duplicated: true };
    else valids[index] = { duplicated: false };
  }
  return valids;
};

const checkNestedDuplication = (arr, valids) => {
  const sameIndexs = arr.map((item, index) => {
    if (item.comboName && arr.findIndex((i) => item.comboName === i.comboName) !== index) return index;
  });
  for (let index = 0; index < arr.length; index++) {
    if (sameIndexs.includes(index)) valids[index] = { ...valids[index], duplicated: true };
    else valids[index] = { ...valids[index], duplicated: false };
  }
  return valids;
};

const setNestedArray = (newParent, parents, pIndex, child, cIndex, batches, drugBatches, valids, formType) => {
  if (newParent) {
    let units = [];
    // Init units
    if (parents[pIndex]) units = parents[pIndex].units;
    else valids[pIndex] = { duplicated: false, units: [] };
    parents[pIndex] = { ...newParent, units };

    // Check duplicated parent
    valids = checkNestedDuplication(parents, valids);
  }
  if (child) {
    if (cIndex !== undefined && parents[pIndex]) {
      parents[pIndex].units[cIndex] = child;
      if (formType == 'national' || formType == SALES_PRESCRIPTION_NATION_TYPE) {
        if (!drugBatches[child.id]) drugBatches[child.id] = [];
        drugBatches[child.id] = _.uniq([...drugBatches[child.id], ...batches]);
      }
    } else {
      if (!parents[pIndex]) parents[pIndex] = { units: [] };
      parents[pIndex].units = [...parents[pIndex].units, child];

      if (batches && !_.isEqual(drugBatches[child.id], batches)) {
        if (!drugBatches[child.id]) drugBatches[child.id] = [];
        drugBatches[child.id] = _.uniq([...drugBatches[child.id], ...batches]);
      }
    }
    // Check duplicated parent units
    valids[pIndex].units = checkUnitsDuplication(parents[pIndex].units);
  }
};

const setNestedArrayCopy = (itemCopy, itemsReducer, drugBatches, valids, type) => {
  if (type === TYPE_COMBO_COPY) {
    itemCopy.forEach((item, index) => {
      itemsReducer[index] = item;
      item.units.forEach((unit, idx) => {
        if (!_.isEqual(drugBatches[unit.id], unit.drug.numbers)) {
          //create array with key = id then multiply array to create batches and drugId

          drugBatches[unit.id] = mapDrugBatches(unit.drug.numbers, unit.drug.units, unit.id);
        }
      });

      // Check valid duplicate
      valids[index] = { duplicated: false, units: [] };
      valids[index].units = checkUnitsDuplication(itemsReducer[index].units);
    });
  } else {
    itemsReducer[0] = itemCopy;
    // // Check duplicated parent
    valids[0] = { duplicated: false, units: [] };
    itemCopy.units.forEach((unit, index) => {
      // map drugBatches
      if (!_.isEqual(drugBatches[unit?.id], unit?.drug.numbers)) {
        //create array with key = id then multiply array to create batches and drugId
        drugBatches[unit?.id] = mapDrugBatches(unit.drug.numbers, unit.drug.units, unit.id);
      }
    });
    // Check duplicated units
    valids[0].units = checkUnitsDuplication(itemsReducer[0].units);
  }
};

const removeNestedArray = (parents, pIndex, cIndex, valids, infoNational = false) => {
  if (cIndex !== undefined) {
    parents[pIndex].units = infoNational
      ? [...parents[pIndex].units.slice(0, cIndex), undefined, ...parents[pIndex].units.slice(cIndex + 1)]
      : [...parents[pIndex].units.slice(0, cIndex), ...parents[pIndex].units.slice(cIndex + 1)];

    // Delete duplicated parent units
    valids[pIndex].units = [...valids[pIndex].units.slice(0, cIndex), ...valids[pIndex].units.slice(cIndex + 1)];

    // Check duplicated parent units
    valids[pIndex].units = checkUnitsDuplication(parents[pIndex].units);
  } else {
    parents = parents.filter((_, index) => index !== pIndex);

    // Delete duplicated parents
    valids = [...valids.slice(0, pIndex), ...valids.slice(pIndex + 1)];

    // Check duplicated parents
    valids = checkNestedDuplication(parents, valids);
  }

  return { parents, valids };
};

const INITIAL_STATE = {
  isFetching: true,
  drugList: [],
  drugBatches: {},
  units: [],
  combos: [],
  prescriptions: [],
  clients: [],
  invoice: null,
  valid: {
    validUnits: [],
    validCombos: [],
    validPrescriptions: [],
  },
  billInfo: null,
  invoiceId: null,
  statistic: [],
  submitType: SUBMIT_TYPE_DEFAULT,
  invoiceById: {},
  infoNational: null,
};

const salesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GET_SALES_DRUG_LIST:
      return { ...state, isFetching: true };

    case types.SET_SALES_DRUG_LIST:
      return { ...state, drugList: payload, isFetching: false };

    case types.GET_SALES_DRUG_DETAIL:
      return { ...state, isFetching: true };

    case types.SET_SALES_DRUG_DETAIL: {
      let { validUnits, validCombos, validPrescriptions } = state.valid;
      let { units, combos, prescriptions, drugBatches, infoNational } = state;
      const {
        formType,
        detail,
        combo,
        prescription,
        batches = [],
        unitIndex,
        index,
        unitCopy,
        prescriptionCopy,
        comboCopy,
        drugBatchesStorage,
        validsStorage,
        national,
      } = payload;
      switch (formType) {
        case 'national':
        case SALES_PRESCRIPTION_NATION_TYPE:
          setNestedArray(prescription, prescriptions, index, detail, unitIndex, batches, drugBatches, validPrescriptions, formType);
          // setNestedArrayCopy(prescription[0], prescriptions, drugBatches, validPrescriptions);
          break;

        case SALES_UNIT_TYPE:
          if (unitIndex !== undefined) {
            // Init data next time
            // When choose drug from search
            units[unitIndex] = detail;
          } else if (detail) {
            // Init data first time
            // When choose drug from search
            units = [...units, detail];
            if (!_.isEqual(drugBatches[detail.id], batches)) {
              if (!drugBatches[detail.id]) drugBatches[detail.id] = [];
              drugBatches[detail.id] = _.uniq([...drugBatches[detail.id], ...batches]);
            }
          } else {
            return { ...state };
          }
          // Check duplicated units
          validUnits = checkUnitsDuplication(units);
          break;

        case SALES_COPY_TYPE:
          if (unitCopy && unitCopy.length > 0) {
            // map unit Copy
            units = [...unitCopy];

            unitCopy.forEach((item, index) => {
              // map drugBatches
              if (item.drug.numbers && !_.isEqual(drugBatches[item.id], item.drug.numbers)) {
                //create array with key = id then multiply array to create batches and drugId
                let _batches = mapDrugBatches(item.drug.numbers, item.drug.units, item.id);
                if (item.drug.numbersUnit && item.drug.numbersUnit.length > 0) {
                  _batches = _batches.map((obj1) => {
                    const matchedObj = item.drug.numbersUnit.find((obj2) => obj2.number + obj2.unitId === obj1.number + obj1.unitId);
                    return { ...obj1, mainCost: matchedObj.mainCost, quantity: matchedObj.quantity };
                  });
                }
                drugBatches[item.id] = _batches;
              }
            });
            console.log(drugBatches);
            // Check duplicated units
            validUnits = checkUnitsDuplication(units);
          }
          if (prescriptionCopy && prescriptionCopy.length > 0) {
            setNestedArrayCopy(prescriptionCopy[0], prescriptions, drugBatches, validPrescriptions);
          }
          if (comboCopy && comboCopy.length > 0) {
            setNestedArrayCopy(comboCopy, combos, drugBatches, validCombos, TYPE_COMBO_COPY);
          }
          break;

        case SALES_COMBO_TYPE:
          setNestedArray(combo, combos, index, detail, unitIndex, batches, drugBatches, validCombos);

          break;

        case SALES_PRESCRIPTION_TYPE:
          setNestedArray(prescription, prescriptions, index, detail, unitIndex, batches, drugBatches, validPrescriptions);

          break;

        case SALES_SESSION_STORAGE_TYPE:
          units = [...unitCopy];
          combos = [...comboCopy];
          prescriptions = [...prescriptionCopy];
          validUnits = [...validsStorage.validUnits];
          validCombos = [...validsStorage.validCombos];
          validPrescriptions = [...validsStorage.validPrescriptions];
          drugBatches = { ...drugBatchesStorage };
          break;
        case SHIPPING:
          if (unitIndex !== undefined) {
            // Init data next time
            // When choose drug from search
            units[unitIndex] = detail;
          } else if (detail) {
            // Init data first time
            // When choose drug from search
            units = [...units, detail];
            if (!_.isEqual(drugBatches[detail.id], batches)) {
              if (!drugBatches[detail.id]) drugBatches[detail.id] = [];
              drugBatches[detail.id] = _.uniq([...drugBatches[detail.id], ...batches]);
            }
          } else {
            return { ...state };
          }
          // Check duplicated units
          validUnits = checkUnitsDuplication(units);
          break;
        default:
          break;
      }
      return {
        ...state,
        units,
        combos: [...combos],
        prescriptions: [...prescriptions],
        drugBatches,
        isFetching: false,
        infoNational: national ? national : infoNational,
        valid: { ...{ validUnits, validCombos, validPrescriptions } },
      };
    }

    case types.REMOVE_SALES_DRUG_DETAIL:
      let { validUnits, validCombos, validPrescriptions } = state.valid;
      let { units, combos, prescriptions, infoNational } = state;
      const { formType, unitIndex, index } = payload;

      switch (formType) {
        case SALES_UNIT_TYPE:
          units = [...units.slice(0, unitIndex), ...units.slice(unitIndex + 1)];

          // Delete duplicated units
          validUnits = [...validUnits.slice(0, unitIndex), ...validUnits.slice(unitIndex + 1)];

          // Check duplicated units
          validUnits = checkUnitsDuplication(units);

          break;

        case SALES_COMBO_TYPE:
          const dataCombos = removeNestedArray(combos, index, unitIndex, validCombos);
          combos = dataCombos.parents;
          validCombos = dataCombos.valids;
          break;

        case SALES_PRESCRIPTION_TYPE:
          const dataPrescription = removeNestedArray(prescriptions, index, unitIndex, validPrescriptions, infoNational);
          prescriptions = dataPrescription.parents;
          // infoNational = []
          validPrescriptions = dataPrescription.valids;
          break;

        case SALES_PRESCRIPTION_NATION_TYPE:
          const dataPrescriptionNation = removeNestedArray(prescriptions, index, unitIndex, validPrescriptions, infoNational);
          prescriptions = dataPrescriptionNation.parents;
          validPrescriptions = dataPrescriptionNation.valids;
          break;

        case RESET_SALES_PRESCRIPTION:
          prescriptions = [];
          break;

        case SHIPPING:
          units = [...units.slice(0, unitIndex), ...units.slice(unitIndex + 1)];

          // Delete duplicated units
          validUnits = [...validUnits.slice(0, unitIndex), ...validUnits.slice(unitIndex + 1)];

          // Check duplicated units
          validUnits = checkUnitsDuplication(units);

          break;
        default:
          break;
      }

      return {
        ...state,
        units,
        combos: [...combos],
        prescriptions: [...prescriptions],
        valid: { ...{ validUnits, validCombos, validPrescriptions } },
      };
    case types.SET_BILL_INFO:
      return {
        ...state,
        billInfo: payload,
      };

    case types.GET_SALES_CLIENT_LIST:
      return {
        ...state,
        isFetching: true,
      };

    case types.SET_SALES_CLIENT_LIST:
      return {
        ...state,
        clients: payload,
      };

    case types.DONE_POST_SALES_CLIENT:
      const clients = state.clients;
      clients.unshift(payload);

      return {
        ...state,
        clients: [...clients],
      };

    case types.START_POST_SALES_DATA:
      return {
        ...state,
        isFetching: true,
      };

    case types.DONE_POST_SALES_DATA: {
      return {
        ...state,
        units: [],
        combos: [],
        drugBatches: {},
        drugList: [],
        prescriptions: [],
        valid: {
          validUnits: [],
          validCombos: [],
          validPrescriptions: [],
        },
        invoiceId: payload,
        isFetching: false,
      };
    }
    case types.REMOVE_SALES_INVOICE_ID: {
      return {
        ...state,
        invoiceId: null,
      };
    }

    case types.SET_SALES_INVOICE_ID: {
      if(payload.invoiceData.national) {
        for (const key in state.invoiceById) {
          if (state.invoiceById[key].national === true) {
            delete state.invoiceById[key];
          }
        }
      }
      return {
        ...state,
        invoiceById: {
          ...state.invoiceById,
          [payload.invoiceId]: payload.invoiceData,
        },
        units: payload.units || state.units,
      };
    }

    case types.GET_SALES_INVOICE_LIST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case types.SET_SALES_INVOICE_LIST: {
      return {
        ...state,
        invoice: payload,
        isFetching: false,
      };
    }

    case types.SET_SALES_STATISTIC: {
      return {
        ...state,
        statistic: payload,
      };
    }
    case types.SET_SALES_SUBMIT: {
      return { ...state, submitType: payload };
    }

    case types.RESET_SALES_REDUX:
      return {
        ...INITIAL_STATE,
        units: [],
        combos: [],
        drugBatches: {},
        drugList: [],
        prescriptions: [],
        valid: {
          validUnits: [],
          validCombos: [],
          validPrescriptions: [],
        },
        invoiceById: {},
      };

    case types.SET_SALES_OUTSTOCK: {
      let batches = cloneObj(state.drugBatches);
      payload.forEach((item, index) => {
        // map drugBatches
        if (item.numbers && !_.isEqual(batches[item.drugId], item.numbers)) {
          //create array with key = id then multiply array to create batches and drugId
          batches[item.drugId] = mapDrugBatches(item.numbers, item.units, item.drugId);
        }
      });
      return { ...state, drugBatches: batches };
    }
    default:
      return { ...state, isFetching: false };
  }
};

export default salesReducer;
