import React, { useState, useCallback, forwardRef, useEffect, useRef, useLayoutEffect } from 'react';
import './DropdownFilter.scss';
import { Form, Row } from 'react-bootstrap';
import { getElementPosition, rem2Px, removeAccent } from 'utils/util';
import { useDispatch } from 'react-redux';
import { setDrugSupplier } from 'redux/drug/drug.actions';

const DropdownFilter = forwardRef(
  (
    {
      name = '',
      textField = 'name',
      valueField = 'id',
      placeholder,
      searchBy,
      className = '',
      options,
      value,
      onChange,
      onBlur,
      action,
      source,
      typeAutoComplete = false,
      ...props
    },
    ref
  ) => {
    if (!searchBy) {
      searchBy = textField;
    }

    // text input value
    const [filterText, setFilterText] = useState('');
    // selected item data
    const [selectedItem, setSelectedItem] = useState({});
    // text input focus state
    const [filterFocus, setFilterFocus] = useState(false);
    // processed option data
    const [selectOptions, setSelectOptions] = useState([]);

    // const dispatch = useDispatch();

    // text input ref
    const inputRef = useRef(null);

    // forwared input ref
    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(inputRef.current);
        } else {
          ref.current = inputRef.current;
        }
      }
    }, [inputRef, ref]);

    const focusSelectedItem = useCallback(
      (findClass = 'selected') => {
        let dropdownFilterMenuElm = inputRef?.current?.parentNode?.querySelector('.dropdown-filter-menu');
        // menu is closed
        if (!dropdownFilterMenuElm) {
          return;
        }
        let selectedElm = dropdownFilterMenuElm.querySelector(`.dropdown-filter-menu-item.${findClass}`);
        let scrollTop = 0;
        if (selectedElm) {
          // focus selected item
          scrollTop = selectedElm.offsetTop;
        } else {
          let dropdownFirstElm = dropdownFilterMenuElm.querySelector(`.dropdown-filter-menu-item:not(.hidden)`);
          // focus first item
          if (dropdownFirstElm) {
            dropdownFirstElm.classList.add('focus');
          }
        }
        // scroll to focused item
        dropdownFilterMenuElm.scrollTop = scrollTop - 5;
        window.dropdownFilterScrolling = true;
      },
      [inputRef]
    );
    const moveFocusItem = useCallback(
      (changeIndex) => {
        let dropdownFilterMenuElm = inputRef.current.parentNode.querySelector('.dropdown-filter-menu');
        // show menu if is closed
        if (!dropdownFilterMenuElm) {
          setFilterFocus(true);
          return;
        }
        let filteredItems = [...dropdownFilterMenuElm.querySelectorAll('.dropdown-filter-menu-item:not(.hidden)')];
        let focusedItems = [...dropdownFilterMenuElm.querySelectorAll('.dropdown-filter-menu-item:not(.hidden).focus')];
        // if no item is focused, focus first (down arrow) / last (up arrow) item
        if (focusedItems.length === 0) {
          filteredItems[changeIndex > 0 ? 0 : filteredItems.length - 1]?.classList?.add('focus');
        } else {
          // remove all focus state
          focusedItems.forEach((elm) => {
            elm.classList.remove('focus');
          });

          // if focused item is first / last item
          // -> set next item = last (up arrow) / first (down arrow) item
          let nextFocusIndex = filteredItems.indexOf(focusedItems[0]) + changeIndex;
          if (nextFocusIndex < 0) {
            nextFocusIndex = filteredItems.length - 1;
          } else if (nextFocusIndex > filteredItems.length - 1) {
            nextFocusIndex = 0;
          }
          // set focus state to next focus item
          filteredItems[nextFocusIndex].classList.add('focus');
        }
        focusSelectedItem('focus');
      },
      [inputRef, focusSelectedItem]
    );
    const testRedux = (id, name) => {
      const newOrder = {
        id: id,
        name: name,
      };
      
      const action = setDrugSupplier(newOrder);
      // dispatch(action);
    };
    const updateChangedValue = useCallback(
      (item) => {
        if (source === 'order') {
          testRedux(item.id, item.name);
        }
        // when onChange is defined and selectedItem is set
        if (typeof onChange === 'function' && JSON.stringify(item) !== '{}') {
          onChange({ target: { name, value: item[valueField] }, ...item });
        }
      },
      [onChange, name, valueField]
    );

    // process option data
    useEffect(() => {
      let opts = [...options];
      if (props.required !== true && !typeAutoComplete) {
        opts.unshift({
          [valueField]: '',
          [textField]: placeholder || '　',
        });
      }
      setSelectOptions(opts);
    }, [options, props.required, valueField, textField, placeholder]);

    // set selectedItem from value
    useEffect(() => {
      const selected = selectOptions.find((option) => option[valueField] === value);
      if (selected) {
        setSelectedItem(selected);
        if (typeAutoComplete) {
          setFilterText(selected[textField])
        }
      } else if (!selected && typeAutoComplete) {
        setSelectedItem({ [valueField]: value });
      } else {
        setSelectedItem({ [valueField]: null });
      }

      // setSelectedItem(
      //   selectOptions.find((option) => option[valueField] === value) || typeAutoComplete ? {
      //     [valueField]: value,
      //   } : {
      //     [valueField]: null,
      //   }
      // );
    }, [value, valueField, selectOptions]);

    // set filter text state when text changed
    const handleFilterTextChange = useCallback(
      (e) => {
        let newText = e.target.value;
        setFilterText(newText);
        if (typeAutoComplete) {
          setSelectedItem({ [valueField]: e.target.value });
          updateChangedValue({ [valueField]: e.target.value });
        }
        let elm = inputRef.current.parentNode;
        setTimeout(() => {
          let dropdownFirstElm = elm.querySelector(`.dropdown-filter-menu-item:not(.hidden)`);
          // focus first item
          if (newText && dropdownFirstElm) {
            setFilterFocus(true);
            dropdownFirstElm.classList.add('focus');
            focusSelectedItem('focus');
          }
        }, 0);
      },
      [inputRef]
    );
    const handleFilterTextFocus = useCallback(
      (e) => {
        setFilterFocus(true);
        // do nothing if readonly / disabled
        if (props.readOnly === true || props.disabled === true) {
          return false;
        }
        // reset filter text and show menu
        if (!typeAutoComplete) {
          setFilterText('');
        }
        setTimeout(focusSelectedItem, 0);
      },
      [props.readOnly, props.disabled, focusSelectedItem]
    );
    const handleFilterTextBlur = useCallback(
      (e) => {
        // reset filter text and close menu
        setFilterFocus(false);
        if (!typeAutoComplete) {
          setFilterText('');
        }
        // trigger onBlur if is defined
        if (typeof onBlur === 'function') {
          onBlur(e);
        }
      },
      [onBlur]
    );
    const handleFilterTextKeyDown = useCallback(
      (e) => {
        // console.log(e.key);
        switch (e.key) {
          case 'ArrowUp':
            // move focus up
            moveFocusItem(-1);
            e.preventDefault();
            break;
          case 'ArrowDown':
            //move focus down
            moveFocusItem(1);
            e.preventDefault();
            break;
          case 'Enter':
            let focusedItem = inputRef.current.parentNode.querySelector('.dropdown-filter-menu-item:not(.hidden).focus');
            // set selectedItem = focused item
            if (focusedItem) {
              focusedItem.click();
            }
            // reset filter text and close menu
            setFilterFocus(false);
            setFilterText('');
            e.preventDefault();
            return;
          case 'Escape':
            // reset filter text and close menu
            setFilterFocus(false);
            setFilterText('');
            return;
          default:
        }
        setFilterFocus(true);
      },
      [inputRef, moveFocusItem]
    );
    const handleFilterClick = useCallback(
      (e) => {
        // do nothing if readonly / disabled
        if (props.readOnly === true || props.disabled === true) {
          return false;
        }
        setTimeout(focusSelectedItem, 0);

        setFilterFocus(!filterFocus);
      },
      [filterFocus, props.readOnly, props.disabled, focusSelectedItem]
    );

    const handleOptionClick = useCallback(
      (index, option, e) => {
        // left mouse
        if (e.button === 0) {
          // set selectedItem = clicked item
          setFilterFocus(false);
          setSelectedItem(option);
          updateChangedValue(option);
        }
        if (typeAutoComplete) {
          setFilterText(option[textField])
        }
      },
      [updateChangedValue]
    );

    const isItemHidden = useCallback(
      (option) => {
        // filter text is all contain in [searchBy] field
        const filters = removeAccent(filterText.toLowerCase()).split(/[ \t\r\n]+/g);
        const searchText = removeAccent(option[searchBy] || '').toLowerCase();
        return (
          (filters[0] !== '' && filters.filter((item) => searchText.indexOf(item) > -1).length !== filters.length) || option.isSelection == false
        );
      },
      [filterText, searchBy]
    );

    const handleItemMouseOver = useCallback(
      (e) => {
        if (window.dropdownFilterScrolling === true) {
          window.dropdownFilterScrolling = false;
          return;
        }

        // remove focus state of another item
        inputRef.current.parentNode.querySelectorAll('.dropdown-filter-menu-item.focus').forEach((elm) => {
          elm.classList.remove('focus');
        });
        // set focus state to current hover item
        e.target.classList.add('focus');
      },
      [inputRef]
    );

    const isDropUp = () => {
      if (!inputRef || !inputRef.current) {
        return;
      }
      return (
        window.innerHeight -
        // screen top position
        getElementPosition(inputRef.current).currentScreenTop -
        // input height
        inputRef.current.offsetHeight -
        // menu height
        rem2Px(11) -
        // padding margin
        20 <
        0
      );
    };

    return (
      <div
        className={`dropdown-filter ${selectedItem?.id !== null ? 'has-value' : ''} ${className || ''}${filterFocus ? ' focus' : ''}${props.isInvalid === true ? ' is-invalid' : ''
          }${isDropUp() ? ' dropup' : ''}`}
      >
        {/* filter text input */}
        <Form.Control
          {...props}
          ref={inputRef}
          name={name}
          // disable bootstrap form validate for input field
          formNoValidate
          // disable filter input autocomplete
          autoComplete="off"
          className="dropdown-filter-input"
          type="text"
          placeholder={selectedItem[valueField] == null ? placeholder : ''}
          title={selectedItem[textField]}
          value={filterText}
          onChange={handleFilterTextChange}
          onFocus={handleFilterTextFocus}
          onBlur={handleFilterTextBlur}
          onKeyDown={handleFilterTextKeyDown}
          onMouseDown={handleFilterClick}
        />
        {/* value display element and indicator icon (▼) */}
        <div className="dropdown-filter-container">
          <div className="dropdown-filter-value">{filterText !== '' ? '' : selectedItem[textField] || ''}</div>
          <div className={`dropdown-filter-indicator${action ? ' action' : ''}`}>
            {action ? (
              action
            ) : (
              <>
                <span className="dropdown-filter-indicator-separator"></span>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                  <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
              </>
            )}
          </div>
        </div>
        {filterFocus && props.disabled !== true && props.readOnly !== true && (
          <div className="dropdown-filter-menu-container">
            <div className="dropdown-filter-menu">
              {selectOptions.map((option, index) => {
                let className = ['dropdown-filter-menu-item'];
                if (isItemHidden(option)) {
                  className.push('hidden');
                }
                if (option[valueField] === selectedItem[valueField] && option[valueField] !== '') {
                  className.push('selected', 'focus');
                }
                return (
                  <Row
                    key={index}
                    className={className.join(' ')}
                    onClick={(e) => {
                      handleOptionClick(index, option, e);
                    }}
                    onMouseDown={(e) => {
                      handleOptionClick(index, option, e);
                    }}
                    onMouseOver={handleItemMouseOver}
                  >
                    {option[textField]}
                  </Row>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default DropdownFilter;
