import types from './warehousing.types';
import { addComma, APP_BRANCH, APP_BRANCH_GDP, round } from 'utils/util';
const INITIAL_STATE = {
  drugList: [],
  units: [],
  suppliers: [],
  invoiceId: null,
  invoiceById: {},
  invoice: null,
  tempInvoice: null,
  billInfo: null,
  valids: [],
};

const warehousingReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.SET_WAREHOUSING_DRUG_LIST_SEARCH:
      return { ...state, drugList: payload };
    case types.SET_WAREHOUSING_DRUG_LIST: {
      let units = [...state.units];
      if (payload.update === true) {
        units = units.map((unit) => ({
          ...unit,
          name: unit.id === payload.drugId ? payload.name : unit.name,
          drugUnits: unit.id === payload.drugId ? payload.drugUnits : unit.drugUnits,
          drugNumbers: unit.id === payload.drugId ? payload.numbers : unit.numbers,
        }));
      } else {
        units.push(payload);
      }
      return { ...state, units, valids: checkUnitsDuplication(units) };
    }
    case types.UPDATE_WAREHOUSING_DRUG_LIST: {
      const { data, index } = payload;
      let { units } = state;
      units = units.map((unit, idx) => (index === idx ? { ...unit, ...data } : unit));
      return {
        ...state,
        units,
        valids: checkUnitsDuplication(units),
      };
    }
    case types.DONE_POST_WAREHOUSING_DATA: {
      return {
        ...INITIAL_STATE,
        invoiceId: payload,
      };
    }
    case types.REMOVE_WAREHOUSING_DRUG_LIST: {
      let { units } = state;
      units = [...units.slice(0, payload), ...units.slice(payload + 1)];
      return {
        ...state,
        units,
        valids: checkUnitsDuplication(units),
      };
    }
    case types.SET_SUPPLIER_LIST:
      return {
        ...state,
        suppliers: payload,
      };
    case types.RESET_WAREHOUSING_REDUX:
      return { ...INITIAL_STATE };

    case types.SET_WAREHOUSING_INVOICE_BY_ID: {
      return {
        ...state,
        invoiceById: {
          ...state.invoiceById,
          [payload.invoiceId]: payload.invoiceData,
        },
        units: payload.units || state.units,
        valids: payload.valids || state.valids,
      };
    }
     case types.SET_WAREHOUSING_BILL_INFO: {
      return {
        ...state,
        billInfo: payload,
      };
    }
    case types.SET_WAREHOUSING_INVOICE_BY_SESSION_STORAGE: {
      let units = payload.units.map((item) => {
        item.expiryDate = item.expiryDate ? new Date(item.expiryDate) : item.expiryDate;
        return item;
      });
      return {
        ...state,
        units,
        valids: payload.valids,
      };
    }
    case types.REMOVE_WAREHOUSING_INVOICE_ID: {
      return { ...state, invoiceId: null };
    }
    case types.SET_WAREHOUSING_INVOICE_LIST: {
      return { ...state, invoice: payload };
    }
    case types.SET_WAREHOUSING_TEMP_INVOICE_LIST: {
      return { ...state, tempInvoice: payload };
    }
    default:
      return state;
  }
};

export default warehousingReducer;

const checkUnitsDuplication = (drugList) => {
  let dup = drugList.map(() => ({
    duplicated: false,
  }));
  drugList.map((e, i, a) => {
    const idx = a
      .slice(i + 1)
      .findIndex((d) =>
        APP_BRANCH == APP_BRANCH_GDP
          ? d.drugCode === e.drugCode && d.number === e.number && d.unitId === e.unitId
          : d.drugCode === e.drugCode && d.number === e.number
      );
    if (idx !== -1) dup[idx + i + 1] = dup[i] = { duplicated: true };
    return e;
  });
  return dup;
};
