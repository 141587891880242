import types from './drug.types';

const INITIAL_STATE = {
  isFetching: false,
  drugList: null,
  drugCategories: [],
  drugCategoryDetail: null,
  drugGroups: [],
  drugGroupDetail: null,
  fetchDrugListPayload: {},
  drugOutDate: [],
  drugOutStock: [],
  drugOrder: [],
  drugSupplier: [],
  importTime: null
};

const drugReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.GET_DRUG_LIST:
      return {
        ...state,
        fetchDrugListPayload: payload,
      };
    case types.GET_DRUG_CATEGORIES:
      return { ...state, isFetching: true };
    case types.GET_DRUG_GROUPS:
      return { ...state, isFetching: true };
    case types.SET_DRUG_LIST:
      return { ...state, drugList: payload, isFetching: false };
    case types.SET_DRUG_CATEGORIES:
      return { ...state, drugCategories: payload, isFetching: false };
    case types.SET_DRUG_GROUPS:
      return { ...state, drugGroups: payload, isFetching: false };
    case types.GET_DRUG_GROUP_START:
      return { ...state, isFetching: true };
    case types.GET_DRUG_GROUP_FINISH:
      return {
        ...state,
        drugGroups: payload,
        isFetching: false,
      };
    case types.SET_DRUG_OUT_DATE:
      return {
        ...state,
        drugOutDate: payload,
      };
    case types.SET_DRUG_OUT_STOCK:
      return {
        ...state,
        drugOutStock: payload,
      };
      case types.SET_IMPORT_TIME:
      return {
        ...state,
        importTime: payload,
      };
    case types.SET_DRUG_ORDER:
      const newListOrder = [...state.drugOrder];
      newListOrder.push(payload)
      return {
        ...state,
        drugOrder: newListOrder,
      };
      case types.SET_DRUG_ORDER_SUPPLIER:
        return {
          ...state,
          drugSupplier: payload,
        };
    case types.ADD_DRUG_GROUP_START:
      return { ...state, isFetching: true };
    case types.ADD_DRUG_GROUP_FINISH:
      const drugGroups = state.drugGroups;
      const grIndex = drugGroups.findIndex((item) => item.id === payload.id);

      if (grIndex !== -1) {
        drugGroups[grIndex] = { ...drugGroups[grIndex], name: payload.name };
      } else drugGroups.push(payload);

      return {
        ...state,
        drugGroups: [...drugGroups],
        isFetching: false,
      };

    case types.ADD_DRUG_CATEGORY_START:
      return { ...state, isFetching: true };
    case types.ADD_DRUG_CATEGORY_FINISH:
      const drugCategories = state.drugCategories;
      const catIndex = drugCategories.findIndex(
        (item) => item.id === payload.id
      );

      if (catIndex !== -1) {
        drugCategories[catIndex] = {
          ...drugCategories[catIndex],
          name: payload.name,
        };
      } else drugCategories.push(payload);

      return {
        ...state,
        drugCategories: [...drugCategories],
        isFetching: false,
      };

    case types.REMOVE_DRUG_GROUP_START:
      return { ...state, isFetching: true };

    case types.REMOVE_DRUG_GROUP_FINISH:
      const removeDrugGroups = state.drugGroups.filter(
        (item) => item.id !== payload
      );
      return { ...state, drugGroups: [...removeDrugGroups], isFetching: false };

    case types.REMOVE_DRUG_CATEGORY_START:
      return { ...state, isFetching: true };

    case types.REMOVE_DRUG_CATEGORY_FINISH:
      const removeDrugCategories = state.drugCategories.filter(
        (item) => item.id !== payload
      );
      return {
        ...state,
        drugCategories: [...removeDrugCategories],
        isFetching: false,
      };

    case types.GET_DRUG_CATEGORY_DETAIL_START:
      return { ...state, isFetching: true };

    case types.GET_DRUG_CATEGORY_DETAIL_FINISH:
      return {
        ...state,
        drugCategoryDetail: payload,
        isFetching: false,
      };

    case types.GET_DRUG_GROUP_DETAIL_START:
      return { ...state, isFetching: true };

    case types.GET_DRUG_GROUP_DETAIL_FINISH:
      return {
        ...state,
        drugGroupDetail: payload,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default drugReducer;
