// Tính năng trang Quản lý
const MANAGE_GENERAL_INFO = 'manage_general_info';
const MANAGE_GENERAL_PASSWORD = 'manage_general_password';
const MANAGE_GENERAL_PRINT = 'manage_general_print';
const MANAGE_GENERAL_OTHER = 'manage_general_other';
const MANAGE_GENERAL_BANK = 'manage_general_bank';
const MANAGE_GENERAL_VNPAY = 'manage_general_vnpay';

export const PERMISSION_MANAGE_GENERAL = {
  MANAGE_GENERAL_INFO: MANAGE_GENERAL_INFO,
  MANAGE_GENERAL_PASSWORD: MANAGE_GENERAL_PASSWORD,
  MANAGE_GENERAL_PRINT: MANAGE_GENERAL_PRINT,
  MANAGE_GENERAL_OTHER: MANAGE_GENERAL_OTHER,
  MANAGE_GENERAL_BANK: MANAGE_GENERAL_BANK,
  MANAGE_GENERAL_VNPAY: MANAGE_GENERAL_VNPAY,
};

const MANAGE_PROMOTION_LIST = 'manage_promotion_list';
const MANAGE_PROMOTION_ADD = 'manage_promotion_add';
const MANAGE_PROMOTION_EDIT = 'manage_promotion_edit';
const MANAGE_PROMOTION_PAUSE = 'manage_promotion_pause';
const MANAGE_PROMOTION_DELETE = 'manage_promotion_delete';

export const PERMISSION_MANAGE_PROMOTION = {
  MANAGE_PROMOTION_LIST: MANAGE_PROMOTION_LIST,
  MANAGE_PROMOTION_ADD: MANAGE_PROMOTION_ADD,
  MANAGE_PROMOTION_EDIT: MANAGE_PROMOTION_EDIT,
  MANAGE_PROMOTION_PAUSE: MANAGE_PROMOTION_PAUSE,
  MANAGE_PROMOTION_DELETE: MANAGE_PROMOTION_DELETE,
};

const MANAGE_EMPLOYEE_LIST = 'manage_employee_list';
const MANAGE_EMPLOYEE_ADD = 'manage_employee_add';
const MANAGE_EMPLOYEE_EDIT = 'manage_employee_edit';
const MANAGE_EMPLOYEE_EXPORT = 'manage_employee_export';
const MANAGE_EMPLOYEE_DELETE = 'manage_employee_delete';

export const PERMISSION_MANAGE_EMPLOYEE = {
  MANAGE_EMPLOYEE_LIST: MANAGE_EMPLOYEE_LIST,
  MANAGE_EMPLOYEE_ADD: MANAGE_EMPLOYEE_ADD,
  MANAGE_EMPLOYEE_EDIT: MANAGE_EMPLOYEE_EDIT,
  MANAGE_EMPLOYEE_EXPORT: MANAGE_EMPLOYEE_EXPORT,
  MANAGE_EMPLOYEE_DELETE: MANAGE_EMPLOYEE_DELETE,
};
const MANAGE_AGENCY_LIST = 'manage_agency_list';
const MANAGE_AGENCY_ACTION = 'manage_agency_action';
const MANAGE_AGENCY_FILTER = 'manage_agency_filter'

export const PERMISSION_MANAGE_AGENCY = {
  MANAGE_AGENCY_LIST: MANAGE_AGENCY_LIST,
  MANAGE_AGENCY_ACTION: MANAGE_AGENCY_ACTION,
  MANAGE_AGENCY_FILTER: MANAGE_AGENCY_FILTER,
};

const MANAGE_CUSTOMER_LIST = 'manage_customer_list';

const MANAGE_CUSTOMER_EXPORT = 'manage_customer_export';
const MANAGE_CUSTOMER_EDIT = 'manage_customer_edit';
const MANAGE_CUSTOMER_ADD = 'manage_customer_add';
export const PERMISSION_MANAGE_CUSTOMER = {
  MANAGE_CUSTOMER_LIST: MANAGE_CUSTOMER_LIST,
  MANAGE_CUSTOMER_EXPORT: MANAGE_CUSTOMER_EXPORT,
  MANAGE_CUSTOMER_EDIT: MANAGE_CUSTOMER_EDIT,
  MANAGE_CUSTOMER_ADD: MANAGE_CUSTOMER_ADD,
};

const MANAGE_SUPPLIER_LIST = 'manage_supplier_list';
const MANAGE_SUPPLIER_ADD = 'manage_supplier_add';
const MANAGE_SUPPLIER_EDIT = 'manage_supplier_edit';
const MANAGE_SUPPLIER_EXPORT = 'manage_supplier_export';

export const PERMISSION_MANAGE_SUPPLIER = {
  MANAGE_SUPPLIER_LIST: MANAGE_SUPPLIER_LIST,
  MANAGE_SUPPLIER_ADD: MANAGE_SUPPLIER_ADD,
  MANAGE_SUPPLIER_EDIT: MANAGE_SUPPLIER_EDIT,
  MANAGE_SUPPLIER_EXPORT: MANAGE_SUPPLIER_EXPORT,
};

const MANAGE_ACCUMULATION_POINT = 'manage_accumulation_point';
const MANAGE_EDIT_POINT = 'manage_edit_point';

export const PERMISSION_MANAGE_POINT = {
  MANAGE_ACCUMULATION_POINT: MANAGE_ACCUMULATION_POINT,
  MANAGE_EDIT_POINT: MANAGE_EDIT_POINT
};

export const PERMISSION_MANAGE = {
  ...PERMISSION_MANAGE_GENERAL,
  ...PERMISSION_MANAGE_PROMOTION,
  ...PERMISSION_MANAGE_EMPLOYEE,
  ...PERMISSION_MANAGE_CUSTOMER,
  ...PERMISSION_MANAGE_SUPPLIER,
  ...PERMISSION_MANAGE_POINT,
};
