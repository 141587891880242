import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

import history from './history';
import onScan from 'onscan.js';
import { APP_BRANCH, APP_BRANCH_GDP } from 'utils/util';
import Loader from 'components/Loader';

const GPP = lazy(() => import('GPP'));
const GDP = lazy(() => import('GDP'));

onScan.attachTo(document, {
  keyCodeMapper: (oEvent) => {
    var iCode = oEvent.which || oEvent.keyCode;
    switch (true) {
      case iCode >= 186 && iCode <= 192:
      case iCode >= 219 && iCode <= 222:
      case iCode >= 48 && iCode <= 90: // numbers and letters
      case iCode >= 106 && iCode <= 111: // operations on numeric keypad (+, -, etc.)
        if (oEvent.key !== undefined && oEvent.key !== '') {
          return oEvent.key;
        }

        var sDecoded = String.fromCharCode(iCode);
        switch (oEvent.shiftKey) {
          case false:
            sDecoded = sDecoded.toLowerCase();
            break;
          case true:
            sDecoded = sDecoded.toUpperCase();
            break;
          default:
            break;
        }
        return sDecoded;
      case iCode >= 96 && iCode <= 105: // numbers on numeric keypad
        return 0 + (iCode - 96);
      default:
        return '';
    }
  },
});

document.querySelector("#manifest").href = APP_BRANCH === APP_BRANCH_GDP ? "/manifestGDP.json" : "/manifestGPP.json";

window.onScan = onScan;

ReactDOM.render(
  <>
    <Router history={history}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Suspense fallback={<Loader white={true} />}>
            {APP_BRANCH === APP_BRANCH_GDP ? <GDP /> : <GPP />}
          </Suspense>
        </PersistGate>
      </Provider>
    </Router>
    <ToastContainer />
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
