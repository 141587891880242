const DASHBOARD_SELL = 'dashboard_sell';
const DASHBOARD_WAREHOUSING = 'dashboard_warehousing';
const DASHBOARD_ORDER = 'dashboard_order';
const DASHBOARD_PRODUCT_DEADLINE = 'dashboard_product_deadline';
const DASHBOARD_PRODUCT_OUT_OF_STOCK = 'dashboard_product_out_of_stock';
const DASHBOARD_SUPPLIER_ORDER = 'dashboard_supplier_order';
const DASHBOARD_SUPPLIER_PRICE = 'dashboard_supplier_price';
const DASHBOARD_TODAY_REVENUE = 'dashboard_today_revenue';
const DASHBOARD_WEEK_REVENUE = 'dashboard_week_revenue';
const DASHBOARD_MONTH_REVENUE = 'dashboard_month_revenue';
const DASHBOARD_YEAR_REVENUE = 'dashboard_year_revenue';
const DASHBOARD_TRANSACTION_HISTORY = 'dashboard_transaction_history';
const DASHBOARD_SELL_HISTORY = 'dashboard_sell_history';

export const PERMISSION_DASHBOARD = {
  DASHBOARD_SELL: DASHBOARD_SELL,
  DASHBOARD_WAREHOUSING: DASHBOARD_WAREHOUSING,
  DASHBOARD_ORDER: DASHBOARD_ORDER,
  DASHBOARD_PRODUCT_DEADLINE: DASHBOARD_PRODUCT_DEADLINE,
  DASHBOARD_PRODUCT_OUT_OF_STOCK: DASHBOARD_PRODUCT_OUT_OF_STOCK,
  DASHBOARD_SUPPLIER_ORDER: DASHBOARD_SUPPLIER_ORDER,
  DASHBOARD_SUPPLIER_PRICE: DASHBOARD_SUPPLIER_PRICE,
  DASHBOARD_TODAY_REVENUE: DASHBOARD_TODAY_REVENUE,
  DASHBOARD_WEEK_REVENUE: DASHBOARD_WEEK_REVENUE,
  DASHBOARD_MONTH_REVENUE: DASHBOARD_MONTH_REVENUE,
  DASHBOARD_YEAR_REVENUE: DASHBOARD_YEAR_REVENUE,
  DASHBOARD_TRANSACTION_HISTORY: DASHBOARD_TRANSACTION_HISTORY,
  DASHBOARD_SELL_HISTORY: DASHBOARD_SELL_HISTORY,
};
