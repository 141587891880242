import Types from './warehousing.types';

const warehousingActions = {
  getWarehousingDrugListSearch: (payload) => ({
    type: Types.GET_WAREHOUSING_DRUG_LIST_SEARCH,
    payload,
  }),
  setWarehousingDrugListSearch: (payload) => ({
    type: Types.SET_WAREHOUSING_DRUG_LIST_SEARCH,
    payload,
  }),
  setWarehousingDrugList: (payload) => ({
    type: Types.SET_WAREHOUSING_DRUG_LIST,
    payload,
  }),
  removeWarehousingDrugList: (payload) => ({
    type: Types.REMOVE_WAREHOUSING_DRUG_LIST,
    payload,
  }),
  getWarehousingDrugList: (payload) => ({
    type: Types.GET_WAREHOUSING_DRUG_LIST,
    payload,
  }),
  updateWarehousingDrugList: (payload) => ({
    type: Types.UPDATE_WAREHOUSING_DRUG_LIST,
    payload,
  }),
  getSupplierList: () => ({
    type: Types.GET_SUPPLIER_LIST,
  }),
  setSupplierList: (payload) => ({
    type: Types.SET_SUPPLIER_LIST,
    payload,
  }),
  postSupplier: (payload) => ({
    type: Types.POST_SUPPLIER,
    payload,
  }),
  fetchWarehousingDrugList: (payload) => ({
    type: Types.FETCH_WAREHOUSING_DRUG_LIST,
    payload,
  }),
  resetWarehousingRedux: () => ({
    type: Types.RESET_WAREHOUSING_REDUX,
  }),
  postWarehousingData: (payload) => ({
    type: Types.POST_WAREHOUSING_DATA,
    payload,
  }),
  postWarehousingDataDone: (payload) => ({
    type: Types.DONE_POST_WAREHOUSING_DATA,
    payload,
  }),
  getWarehousingInvoiceById: (payload) => ({
    type: Types.GET_WAREHOUSING_INVOICE_BY_ID,
    payload,
  }),
  setWarehousingInvoiceById: (payload) => ({
    type: Types.SET_WAREHOUSING_INVOICE_BY_ID,
    payload,
  }),
  removeWarehousingInvoiceId: () => ({
    type: Types.REMOVE_WAREHOUSING_INVOICE_ID,
  }),
  getWarehousingInvoiceList: (payload) => ({
    type: Types.GET_WAREHOUSING_INVOICE_LIST,
    payload,
  }),
  setWarehousingBillInfo: (payload) => ({
    type: Types.SET_WAREHOUSING_BILL_INFO,
    payload
  }),
  setWarehousingInvoiceList: (payload) => ({
    type: Types.SET_WAREHOUSING_INVOICE_LIST,
    payload,
  }),
  getWarehousingTempInvoiceList: (payload) => ({
    type: Types.GET_WAREHOUSING_TEMP_INVOICE_LIST,
    payload,
  }),
  setWarehousingTempInvoiceList: (payload) => ({
    type: Types.SET_WAREHOUSING_TEMP_INVOICE_LIST,
    payload,
  }),
  setWarehousingInvoiceBySessionStorage: (payload) => ({
    type: Types.SET_WAREHOUSING_INVOICE_BY_SESSION_STORAGE,
    payload,
  }),
};

export default warehousingActions;
