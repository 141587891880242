import { camelizeKeys } from 'utils/util';
import UserAcitonTypes from './user.types';

const INITIAL_STATE = {
  currentUser: null,
  notification: [],
  refreshDate: null,
  notiTime: null,
  isFetching: false,
};

const userReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UserAcitonTypes.SIGN_IN_START:
      return {
        ...state,
        username: payload.username,
        isFetching: true,
      };
    case UserAcitonTypes.SIGN_IN_SUCCESS:
      let curUser = payload;
      if (curUser.drugStore && curUser.drugStore.settings) {
        try {
          curUser.drugStore.settings = camelizeKeys(JSON.parse(curUser.drugStore.settings));
        } catch (e) {}
      }
      return {
        ...state,
        currentUser: curUser,
        refreshDate: new Date(),
        notiTime: new Date(),
        isFetching: false,
      };
    case UserAcitonTypes.SIGN_IN_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case UserAcitonTypes.SIGN_OUT:
      return {
        ...state,
        currentUser: null,
        refreshDate: null,
        notiTime: null,
        notification: [],
        isFetching: false,
      };
    case UserAcitonTypes.SET_REFRESH_DATE:
      return {
        ...state,
        refreshDate: payload,
      };
    case UserAcitonTypes.REFRESH_TOKEN_DONE:
      let currentUser = {
        userinfo: { ...state.currentUser?.userinfo, ...payload?.userinfo },
        drugStore: { ...state.currentUser?.drugStore, ...payload?.drugStore },
        token: payload?.token,
      };
      if (!payload?.token) {
        if (new Date().getTime() - new Date(state.refreshDate).getTime() > 3000) {
          return {
            ...state,
            currentUser: null,
            refreshDate: null,
            notiTime: null,
            isFetching: false,
          };
        }
        return state;
      } else if (payload?.drugStore && payload?.drugStore?.settings) {
        let storeSettings = payload.drugStore.settings;
        try {
          storeSettings = camelizeKeys(JSON.parse(payload.drugStore.settings));
        } catch (e) {}
        currentUser.drugStore.settings = storeSettings;
      }
      if (JSON.stringify(state.currentUser) === JSON.stringify(currentUser)) {
        return state;
      }
      return {
        ...state,
        currentUser,
        refreshDate: new Date(),
      };
    case UserAcitonTypes.UPDATE_USER_INFO:
      return {
        ...state,
        currentUser: payload,
      };

    case UserAcitonTypes.SET_NOTI_TIME:
      return {
        ...state,
        notiTime: payload,
      };

    case UserAcitonTypes.SET_NOTI:
      return {
        ...state,
        notification: payload,
      };
    case UserAcitonTypes.ADD_NOTI:
      return {
        ...state,
        notification: [...state.notification.slice(0, payload.index || 0), ...(payload.data || []), ...state.notification.slice(payload.index || 0)],
      };

    default:
      return state;
  }
};

export default userReducer;
