import ChartActionTypes from './chart.types';

const INITIAL_STATE = {
  isFetching: false,
  data: null,
};

const chartReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ChartActionTypes.FETCH_CHART_DATA_START:
      return {
        ...state,
        isFetching: true,
      };
    case ChartActionTypes.FETCH_CHART_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        isFetching: false,
      };
    case ChartActionTypes.FETCH_CHART_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default chartReducer;
