import FormCustomize from 'components/FormCustomize';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './ReportForm.scss';
import { Form } from 'react-bootstrap';
import { addComma, filterUniqueByKey, formatDate, unComma } from 'utils/util';
import reportActions from 'redux/report/report.actions';
import { ItemType } from './AddItemComponent';
import * as Yup from 'yup';
let validationSchema = null;

const ReportForm = ({
  drug,
  index,
  valid,
  type = ItemType.QualityControl,
  formikInitialValues,
}) => {
  const dispatch = useDispatch();
  const drugUnits = filterUniqueByKey(drug.drugUnits, 'unitId');
  const drugNumbers = filterUniqueByKey(drug.drugUnits, 'number');
  const [selectedUnit, setSelectedUnit] = useState(null);
  const initialValues =
    type === ItemType.QualityControl
      ? {
          unitId: drugUnits?.[0]?.unitId,
          quantity: 0,
          number: drug?.number,
          checkDate: new Date(),
          sensoryQuality: '',
          conclude: 'Đạt',
          reason: 'Kiểm tra định kỳ',
          drugId: drug.id,
        }
      : type === ItemType.CheckInventory
      ? {
          unitId: drugUnits?.[0]?.unitId,
          number: drug?.number,
          note: '',
          currentAmount: +drug?.quantity || '',
          amount: +drug?.quantity || '',
          mainCost: parseFloat(drug?.mainCost),
          expiryDate: new Date(drug?.expiryDate),
          diffAmount: 0,
          diffValue: 0,
          drugCode: drug?.drugCode,
          drugId: drug?.id,
        }
      : {
          drugId: drug.id,
          unitId: drugUnits?.[0]?.unitId,
          number: drug?.number,
          quantity: drug.quantity,
          mainCost: parseFloat(drug?.mainCost),
          totalCost: 0,
          expiryDate: new Date(drug?.expiryDate),
          vat: 0,
        };

  if (type === ItemType.ReportExport) {
    validationSchema = Yup.object({
      quantity: Yup.number()
        .min(1, 'Số lượng > 0')
        .required('Vui lòng nhập số lượng'),
    });
  }


  const formik = useFormik({
    validate: (values, props) => {
      const errors = {};
      const amount = parseInt(unComma(null, values.quantity));
      if (selectedUnit && amount > selectedUnit.quantity) {
        errors.quantity = 'Số lượng lớn hơn số lượng tồn kho';
      }
      return errors;
    },
    initialValues: {
      ...initialValues,
      ...formikInitialValues,
    },
    validationSchema:
      type === ItemType.CheckInventory
        ? Yup.object({
            currentAmount: Yup.number().min(0, 'Số lượng >= 0').required('Chưa nhập số lượng thực tế'),
          })
        : type === ItemType.ReportExport
        ? validationSchema
        : null,
    initialTouched: !!formikInitialValues && {
      quantity: true,
    },
  });

  const formList = [
    {
      name: 'number',
      label: 'Lô SX',
      required: true,
      options: drugNumbers.map((e) => ({
        name: e.number,
        id: e.number,
      })),
      warning: valid?.duplicated && 'Trùng số lô và đơn vị',
    },
    {
      name: 'unitId',
      label: 'Đơn vị',
      required: true,
      options: drugUnits.map((e) => ({
        name: `${e.unitName}${e.isBasic === 'yes' ? '' : ` (x${e.exchange})`}`,
        id: e.unitId,
      })),
      warning: valid?.duplicated && 'Trùng số lô và đơn vị',
    },
    ...(type === ItemType.QualityControl
      ? [
          {
            name: 'quantity',
            label: 'Số lượng',
            type: 'number',
          },
          {
            name: 'checkDate',
            label: 'Ngày kiểm tra',
            type: 'date',
            required: true,
          },
          {
            name: 'conclude',
            label: 'Kết luận',
          },
          {
            name: 'reason',
            label: 'Lý do kiểm tra',
            required: true,
            options: [
              { name: 'Kiểm tra định kỳ', id: 'Kiểm tra định kỳ' },
              { name: 'Kiểm tra đột xuất', id: 'Kiểm tra đột xuất' },
            ],
          },
          {
            name: 'sensoryQuality',
            label: 'Đánh giá chất lượng cảm quan',
            type: 'autocomplete',
            valueField: 'name',
            // placeholder: 'Bao bì nguyên vẹn, thuốc không đổi màu',
            // placeholder: 'Nhập hãng sản xuất',
            options:[
              { name: 'Bao bì nguyên vẹn, thuốc không đổi màu', id: 'Bao bì nguyên vẹn, thuốc không đổi màu' },
              { name: 'Viên thuốc đủ góc cạnh, hình dạng ban đầu, viên không bị nứt, đồng nhất màu', id: 'Viên thuốc đủ góc cạnh, hình dạng ban đầu, viên không bị nứt, đồng nhất màu' },
              { name: 'Viên nang không bị phai màu, không khô cứng, các viên nang tách rời nhau khi ở trong chai, lọ và viên không dính sát vỉ', id: 'Viên nang không bị phai màu, không khô cứng, các viên nang tách rời nhau khi ở trong chai, lọ và viên không dính sát vỉ' },
              { name: 'Các viên vẫn giữ được hình dạng khi ở trong vỉ, vỉ thuốc không bị móp, viên không dính vào vỉ', id: 'Các viên vẫn giữ được hình dạng khi ở trong vỉ, vỉ thuốc không bị móp, viên không dính vào vỉ' },
              { name: 'Lọ bột thuốc đồng nhất màu sắc, không vón cục, khi lắc ngược lọ bột thuốc chảy đều, bột thuốc không chảy theo hình dạng của lọ', id: 'Lọ bột thuốc đồng nhất màu sắc, không vón cục, khi lắc ngược lọ bột thuốc chảy đều, bột thuốc không chảy theo hình dạng của lọ' },
              { name: 'Dung dịch trong suốt, đồng nhất, không có cặn', id: 'Dung dịch trong suốt, đồng nhất, không có cặn' },
              { name: 'Tuýp thuốc không bị móp, biến dạng, dung dịch đồng nhất, không có bọt khí bên trong', id: 'Tuýp thuốc không bị móp, biến dạng, dung dịch đồng nhất, không có bọt khí bên trong' },
            ],
          },
        ]
      : type === ItemType.CheckInventory
      ? [
          {
            name: 'note',
            label: 'Lý do điều chỉnh',
          },
          {
            name: 'currentAmount',
            label: 'Số lượng thực tế',
            type: 'number',
          },
          {
            name: 'expiryDate',
            label: 'Hạn sử dụng',
            type: 'date',
            readOnly: true,
          },
          {
            name: 'mainCost',
            label: 'Giá nhập (VNĐ)',
            type: 'number',
            readOnly: true,
          },
          {
            name: 'diffAmount',
            label: 'Số lượng lệch',
            type: 'number',
            readOnly: true,
          },
          {
            name: 'diffValue',
            label: 'Giá trị lệch',
            type: 'number',
            readOnly: true,
          },
        ]
      : [
          {
            name: 'expiryDate',
            label: 'Hạn sử dụng',
            type: 'date',
            readOnly: true,
          },
          {
            name: 'mainCost',
            label: 'Giá nhập(VNĐ)',
            readOnly: true,
            type: 'number',
          },
          {
            name: 'quantity',
            label: 'Số lượng',
            decimalScale: 0,
            type: 'number',
          },
          {
            name: 'totalCost',
            label: 'Thành tiền',
            type: 'number',
            readOnly: true,
          },
          {
            name: 'vat',
            label: 'VAT(%)',
            readOnly: true,
            type: 'number',
          },
        ]),
  ];
  const basicInfo = [
    {
      label: 'Tên thuốc',
      name: 'name',
      value: drug.name || drug.drugName,
    },
    { label: 'Mã thuốc', name: 'drugCode' },
    {
      label:
        type !== ItemType.CheckInventory ? 'Tồn kho' : 'Số lượng trước kiểm',
      name: 'stockQuantity',
      value: addComma(+selectedUnit?.quantity) || 0,
    },
  ];

  useEffect(() => {
    dispatch(
      reportActions.updateReportQuantityControlDrugList({
        index,
        data: {
          values: {
            ...formik.values,
            ...(type === ItemType.QualityControl
              ? {
                  date: formatDate(formik.values.checkDate, 'YYYY-MM-DD'),
                }
              : type === ItemType.CheckInventory
              ? {
                  expiryDate: formatDate(
                    formik.values.expiryDate,
                    'YYYY-MM-DD'
                  ),
                }
              : {
                  expiryDate: formatDate(
                    formik.values.expiryDate,
                    'YYYY-MM-DD'
                  ),
                }),
          },
        },
      })
    );
  }, [formik.values, index]);
  useEffect(() => {
    let selectedItem = drug.drugUnits.find(
      (unit) =>
        unit.unitId === formik.values.unitId &&
        unit.number === formik.values.number
    );
    selectedItem = {
      ...selectedItem,
      quantity: Math.floor(selectedItem?.quantity || 0),
    };
    setSelectedUnit(selectedItem);
    switch (type) {
      case ItemType.QualityControl:
        formik.setFieldValue('expiryDate', selectedItem?.expiryDate);
        formik.setFieldValue('quantity', +selectedItem?.quantity);
        break;
      case ItemType.CheckInventory:
        formik.setFieldValue('mainCost', +selectedItem?.mainCost);
        formik.setFieldValue('amount', +selectedItem?.quantity);
        // if (!formikInitialValues)
        formik.setFieldValue('currentAmount', +selectedItem?.quantity);
        formik.setFieldValue('stockQuantity', +selectedItem?.quantity);
        break;
      case ItemType.ReportExport:
        formik.setFieldValue('mainCost', +selectedItem?.mainCost);
        formik.setFieldValue('quantity', Math.floor(formik.values.quantity));
        formik.setFieldValue(
          'totalCost',
          +selectedItem?.mainCost *
            +formik.values.quantity *
            (1 + (+selectedItem.vat || 0) / 100.0)
        );
        formik.setFieldValue('vat', +selectedItem?.vat || 0);
        break;
      default:
    }
  }, [
    formik.values.unitId,
    formik.values.number,
    drug.drugUnits,
    formik.values.quantity,
  ]);

  useEffect(() => {
    if (type === ItemType.CheckInventory) {
      formik.setFieldValue(
        'diffAmount',
        formik.values.currentAmount - selectedUnit?.quantity
      );
      formik.setFieldValue(
        'diffValue',
        (formik.values.currentAmount - selectedUnit?.quantity) *
          selectedUnit?.mainCost
      );
    }
  }, [formik.values.currentAmount, formik.values.mainCost]);

  return (
    <div className={`_quality-control-form ${type}`}>
      <Form>
        {basicInfo.map((fieldProps, index) => (
          <div className={`form-${fieldProps.name}`} key={index}>
            <div className="label">{fieldProps.label}</div>
            <div className="value">
              {fieldProps.value !== undefined
                ? fieldProps.value
                : drug[fieldProps.name]}
            </div>
          </div>
        ))}
        {formList.map((formProps, index) => (
          <FormCustomize formik={formik} key={index} {...formProps} />
        ))}
      </Form>
    </div>
  );
};

export default ReportForm;
