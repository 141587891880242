import Types from './invoice.types';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getInvoiceList: (payload) => ({
    type: Types.GET_INVOICE_LIST,
    payload,
  }),
  setInvoiceList: (payload) => ({
    type: Types.SET_INVOICE_LIST,
    payload,
  }),
  getInvoiceStatistic: (payload) => ({
    type: Types.GET_INVOICE_STATISTIC,
    payload,
  }),
  setInvoiceStatistic: (payload) => ({
    type: Types.SET_INVOICE_STATISTIC,
    payload,
  }),
  removeInvoiceId: () => ({
    type: Types.REMOVE_INVOICE_ID,
  }),
};
