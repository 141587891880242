import types from './invoice.types';

const INITIAL_STATE = {
  isFetching: true,
  invoice: null,
  invoiceId: null,
  invoiceStatistic: [],
};

const invoiceReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case types.REMOVE_INVOICE_ID: {
      return {
        ...state,
        invoiceId: null,
      };
    }

    case types.SET_INVOICE_ID: {
      return {
        ...state,
        invoiceById: {
          ...state.invoiceById,
          [payload.invoiceId]: payload.invoiceData,
        },
      };
    }

    case types.GET_INVOICE_LIST: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case types.SET_INVOICE_LIST: {
      return {
        ...state,
        invoice: payload,
        isFetching: false,
      };
    }

    case types.SET_INVOICE_STATISTIC: {
      return {
        ...state,
        invoiceStatistic: payload,
      };
    }

    case types.RESET_INVOICE_REDUX:
      return { ...INITIAL_STATE };

    default:
      return { ...state, isFetching: false };
  }
};

export default invoiceReducer;
