import React from 'react';
import './ItemComponent.scss';
import ReportForm from './ReportForm';
import FormItemRemoveButton from 'components/FormItemRemoveButton';
import { useDispatch } from 'react-redux';
import reportActions from 'redux/report/report.actions.js';
import { ItemType } from './AddItemComponent';

const ItemComponent = ({ drug, index, handleRemove, valid, type }) => {
  const dispatch = useDispatch();

  const handleRemoveUnit = () => {
    handleRemove();
    dispatch(reportActions.removeReportQuantityControlDrugList(index));
  };

  const formikInitialValues =
    type === ItemType.QualityControl
      ? {}
      : type === ItemType.CheckInventory
      ? {
          number: drug?.number,
          unitId: drug?.unitId,
          note: drug?.note,
          currentAmount: +drug?.currentAmount || '',
        }
      : {};

  return (
    <div className="_item-component">
      <ReportForm
        drug={drug}
        index={index}
        valid={valid}
        type={type}
        formikInitialValues={formikInitialValues}
      />
      <FormItemRemoveButton onClick={handleRemoveUnit} />
    </div>
  );
};

export default ItemComponent;
