import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import chartReducer from './chart/chart.reducer';
import salesReducer from './sales/sales.reducer';
import drugReducer from './drug/drug.reducer';
import warehousingReducer from './warehousing/warehousing.reducer';
import invoiceReducer from './invoice/invoice.reducer';
import masterReducer from './master/master.reducer';
import reportReducer from './report/report.reducer';
import managementReducer from './management/management.reducer';
import scanCodeReducer from './scan-code/scan-code.reducer';
import salesGdpReducer from './salesgdp/sales.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const rootReducer = combineReducers({
  user: userReducer,
  chart: chartReducer,
  master: masterReducer,
  sales: salesReducer,
  salesgdp: salesGdpReducer,
  invoice: invoiceReducer,
  drug: drugReducer,
  warehousing: warehousingReducer,
  report: reportReducer,
  management: managementReducer,
  scanCode: scanCodeReducer,
});

export default persistReducer(persistConfig, rootReducer);
