// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ADD_DRUG_START: 'ADD_DRUG_START',
  ADD_DRUG_FINISH: 'ADD_DRUG_FINISH',
  GET_DRUG_LIST: 'GET_DRUG_LIST',
  SET_DRUG_LIST: 'SET_DRUG_LIST',
  GET_DRUG_CATEGORIES: 'GET_DRUG_CATEGORIES',
  SET_DRUG_CATEGORIES: 'SET_DRUG_CATEGORIES',
  GET_DRUG_GROUPS: 'GET_DRUG_GROUPS',
  SET_DRUG_GROUPS: 'SET_DRUG_GROUPS',
  ADD_DRUG_GROUP_START: 'ADD_DRUG_GROUP_START',
  ADD_DRUG_GROUP_FINISH: 'ADD_DRUG_GROUP_FINISH',
  SET_FETCH_DRUG_LIST_META_DATA: 'SET_FETCH_DRUG_LIST_META_DATA',
  ADD_DRUG_CATEGORY_START: ' ADD_DRUG_CATEGORY_START',
  ADD_DRUG_CATEGORY_FINISH: 'ADD_DRUG_CATEGORY_FINISH',
  REMOVE_DRUG_GROUP_START: 'REMOVE_DRUG_GROUP_START',
  REMOVE_DRUG_GROUP_FINISH: 'REMOVE_DRUG_GROUP_FINISH',
  REMOVE_DRUG_CATEGORY_START: 'REMOVE_DRUG_CATEGORY_START',
  REMOVE_DRUG_CATEGORY_FINISH: 'REMOVE_DRUG_CATEGORY_FINISH',
  GET_DRUG_GROUP_DETAIL_START: 'GET_DRUG_GROUP_DETAIL_START',
  GET_DRUG_GROUP_DETAIL_FINISH: 'GET_DRUG_GROUP_DETAIL_FINISH',
  GET_DRUG_CATEGORY_DETAIL_START: 'GET_DRUG_CATEGORY_DETAIL_START',
  GET_DRUG_CATEGORY_DETAIL_FINISH: 'GET_DRUG_CATEGORY_DETAIL_FINISH',
  GET_DRUG_OUT_DATE_START: 'GET_DRUG_OUT_DATE_START',
  SET_DRUG_OUT_DATE: 'SET_DRUG_OUT_DATE',
  GET_DRUG_OUT_STOCK_START: 'GET_DRUG_OUT_STOCK_START',
  SET_DRUG_OUT_STOCK: 'SET_DRUG_OUT_STOCK',
  SET_DRUG_ORDER: 'SET_DRUG_ORDER',
  SET_DRUG_ORDER_SUPPLIER : 'SET_DRUG_ORDER_SUPPLIER',
  SET_IMPORT_TIME: 'SET_IMPORT_TIME'
};
