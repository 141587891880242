import React from 'react';
import './CardCustomize.scss';

const CardCustomize = ({ header, body }) => {
  return (
    <div className="_card-customize">
      {header && <div className="_card-header">{header}</div>}
      <div className="_card-body">{body}</div>
    </div>
  );
};

export default CardCustomize;
