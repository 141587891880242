/* eslint-disable import/no-anonymous-default-export */
import Types from './sales.types';

export default {
  getSalesDrugList: (search) => ({
    type: Types.GET_SALES_DRUG_LIST,
    payload: search,
  }),
  setSalesDrugList: (payload) => ({ type: Types.SET_SALES_DRUG_LIST, payload }),
  getSalesDrugDetail: (drug, formType, index, unitIndex, agencyId) => ({
    type: Types.GET_SALES_DRUG_DETAIL,
    payload: { drug, formType, index,unitIndex, agencyId},
  }),
  setSalesDrugDetail: (payload) => ({
    type: Types.SET_SALES_DRUG_DETAIL,
    payload,
  }),
  removeSalesDrugDetail: (payload) => ({
    type: Types.REMOVE_SALES_DRUG_DETAIL,
    payload,
  }),

  getSalesClientList: () => ({ type: Types.GET_SALES_CLIENT_LIST }),
  setSalesClientList: (payload) => ({
    type: Types.SET_SALES_CLIENT_LIST,
    payload,
  }),
  postSalesClient: (payload) => ({
    type: Types.START_POST_SALES_CLIENT,
    payload,
  }),
  postSalesClientDone: (payload) => ({
    type: Types.DONE_POST_SALES_CLIENT,
    payload,
  }),
  postSalesData: (payload) => ({ type: Types.START_POST_SALES_DATA, payload }),
  postSalesDataDone: (payload) => ({
    type: Types.DONE_POST_SALES_DATA,
    payload,
  }),
  getSalesInvoiceList: (payload) => ({
    type: Types.GET_SALES_INVOICE_LIST,
    payload,
  }),
  setSalesInvoiceList: (payload) => ({
    type: Types.SET_SALES_INVOICE_LIST,
    payload,
  }),
  removeSalesInvoiceId: () => ({
    type: Types.REMOVE_SALES_INVOICE_ID,
  }),
  setSalesSubmit: (payload) => ({ type: Types.SET_SALES_SUBMIT, payload }),
  setBillInfo: (payload) => ({
    type: Types.SET_BILL_INFO,
    payload
  }),
  getSalesStatistic: (payload) => ({
    type: Types.GET_SALES_STATISTIC,
    payload,
  }),
  setSalesStatistic: (payload) => ({
    type: Types.SET_SALES_STATISTIC,
    payload,
  }),
  getSalesInvoiceById: (payload) => ({
    type: Types.GET_SALES_INVOICE_ID,
    payload,
  }),
  setSalesInvoiceById: (payload) => ({
    type: Types.SET_SALES_INVOICE_ID,
    payload,
  }),
  resetSalesRedux: () => ({
    type: Types.RESET_SALES_REDUX,
  }),
  getSalesOutStock: (payload) => ({
    type: Types.GET_SALES_OUTSTOCK,
    payload,
  }),
  setSalesOutStock: (payload) => ({
    type: Types.SET_SALES_OUTSTOCK,
    payload,
  }),
};
