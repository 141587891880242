// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GET_SALES_DRUG_LIST: 'GET_SALES_DRUG_LIST',
  SET_SALES_DRUG_LIST: 'SET_SALES_DRUG_LIST',
  GET_SALES_DRUG_DETAIL: 'GET_SALES_DRUG_DETAIL',
  SET_SALES_DRUG_DETAIL: 'SET_SALES_DRUG_DETAIL',
  UPDATE_SALES_DRUG_DETAIL: 'UPDATE_SALES_DRUG_DETAIL',
  GET_SALES_CLIENT_LIST: 'GET_SALES_CLIENT_LIST',
  SET_SALES_CLIENT_LIST: 'SET_SALES_CLIENT_LIST',
  REMOVE_SALES_DRUG_DETAIL: 'REMOVE_SALES_DRUG_DETAIL',
  START_POST_SALES_CLIENT: 'START_POST_SALES_CLIENT',
  DONE_POST_SALES_CLIENT: 'DONE_POST_SALES_CLIENT',
  START_POST_SALES_DATA: 'START_POST_SALES_DATA',
  DONE_POST_SALES_DATA: 'DONE_POST_SALES_DATA',
  GET_SALES_INVOICE_LIST: 'GET_SALES_INVOICE_LIST',
  SET_SALES_INVOICE_LIST: 'SET_SALES_INVOICE_LIST',
  REMOVE_SALES_INVOICE_ID: 'REMOVE_SALES_INVOICE_ID',
  GET_SALES_INVOICE_ID: 'GET_SALES_INVOICE_ID',
  SET_SALES_INVOICE_ID: 'SET_SALES_INVOICE_ID',
  GET_SALES_STATISTIC: 'GET_SALES_STATISTIC',
  SET_SALES_STATISTIC: 'SET_SALES_STATISTIC',
  SET_SALES_SUBMIT: 'SET_SALES_SUBMIT',
  RESET_SALES_REDUX: 'RESET_SALES_REDUX',
  GET_SALES_OUTSTOCK: 'GET_SALES_OUTSTOCK',
  SET_SALES_OUTSTOCK: 'SET_SALES_OUTSTOCK',
  SET_BILL_INFO: 'SET_BILL_INFO',
};
