import { all, fork } from 'redux-saga/effects';

import { userSagas } from './user/user.sagas';
import { chartSagas } from './chart/chart.sagas';
import { salesSagas } from './sales/sales.sagas';
import { invoiceSagas } from './invoice/invoice.sagas';
import { drugSagas } from './drug/drug.sagas';
import { warehousingSagas } from './warehousing/warehousing.sagas';
import { masterSagas } from './master/master.sagas';
import { reportSagas } from './report/report.sagas';
import managementSagas from './management/management.sagas';

export default function* rootSaga() {
  yield all([
    fork(userSagas),
    fork(chartSagas),
    fork(masterSagas),
    fork(salesSagas),
    fork(invoiceSagas),
    fork(drugSagas),
    fork(warehousingSagas),
    fork(reportSagas),
    fork(managementSagas),
  ]);
}
