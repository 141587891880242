// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GET_CUSTOMER_LIST: 'GET_CUSTOMER_LIST',
  SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
  SET_CUSTOMER_ID: 'SET_CUSTOMER_ID',
  DONE_POST_SUPPLIER: 'DONE_POST_SUPPLIER',
  SET_SUPPLIER_LIST: 'SET_SUPPLIER_LIST',
  START_POST_CUSTOMER: 'START_POST_CUSTOMER',
  DONE_POST_CUSTOMER: 'DONE_POST_CUSTOMER',
  RESET_SALES_REDUX: 'RESET_SALES_REDUX',
};
