import { createSelector } from 'reselect';

const selectSales = (state) => state.sales;

export const selectSalesDrugList = createSelector(
  [selectSales],
  (sales) => sales.drugList
);

export const selectBillInfo = createSelector(
  [selectSales],
  (sales) => sales.billInfo
);


export const selectUnitDrugs = createSelector(
  [selectSales],
  (sales) => sales.units
);

export const selectComboDrugs = createSelector(
  [selectSales],
  (sales) => sales.combos
);

export const selectPrescriptionDrugs = createSelector(
  [selectSales],
  (sales) => sales.prescriptions
);

export const selectInfoNational = createSelector(
  [selectSales],
  (sales) => sales.infoNational
);

export const selectDrugBatches = createSelector(
  [selectSales],
  (sales) => sales.drugBatches
);

export const selectIsFetchingSales = createSelector(
  [selectSales],
  (sales) => sales.isFetching
);

export const selectSalesClientList = createSelector(
  [selectSales],
  (sales) => sales.clients
);

export const selectSalesInvoice = createSelector(
  [selectSales],
  (sales) => sales.invoice
);

export const selectInvoiceId = createSelector(
  [selectSales],
  (sales) => sales.invoiceId
);

export const selectSalesComboSummaryById = createSelector(
  [selectComboDrugs],
  (combos) => (comboIndex) => {
    const combo = comboIndex < combos.length ? combos[comboIndex] : null;
    const units = combo ? combo.units : [];

    return {
      unitDrugCount: units.length,
      totalAll: units.reduce(
        (acc, cur) => acc + cur.currentCost * cur.amount * (1 + cur.vat / 100),
        0
      ),
    };
  }
);

export const selectSalesSummary = createSelector([selectSales], (sales) => {
  const { units, combos, prescriptions } = sales;
  // Total Amount
  // if(units.length == 0) {
  //   return {
  //     productCount: 0,
  //     totalAmount: 0,
  //     totalMoney: 0,
  //     totalVAT: 0,
  //     totalAll: 0,
  //   }
  // }

  let totalAmount = units.reduce((a, b) => a + (+b?.amount || 0), 0);
  totalAmount += combos.reduce(
    (a, b) => a + +b.units.reduce((_a, _b) => _a + (+_b?.amount || 0), 0),
    0
  );
  totalAmount += prescriptions.reduce(
    (a, b) => a + +b.units.reduce((_a, _b) => _a + (+_b?.amount || 0), 0),
    0
  );

  let productCount = units.length;
  productCount += combos.reduce((a, b) => a + 1, 0);
  productCount += prescriptions.reduce((a, b) => a + (b?.units.filter((item) => item != undefined).length || 0), 0);
  let totalDiscountPromotion = units.reduce((a, b) => a + ((+b?.discountPromotion ?? 0)) * (b?.amount|| 0), 0);
  // Total Money
  let totalMoney = units.reduce((a, b) => a + (+b?.currentCost - (+b?.discountPromotion ?? 0)) * (b?.amount|| 0), 0);
  let totalBefore = units.reduce((a, b) => a + +b?.currentCost * (b?.amount|| 0), 0);
  totalMoney += combos.reduce((a, b) => a + (b?.price || 0) *( b?.quantity|| 0), 0);
  totalMoney += prescriptions.reduce(
    (x, y) => x + y.units.reduce((a, b) => a + ((+b?.currentCost || 0) - (+b?.discountPromotion || 0)) * (b?.amount || 0), 0),
    0
  );
  // Total VAT
  let totalVAT = units.reduce(
    (a, b) => a + ((+b?.currentCost || 0) * (b?.amount || 0) * (b?.vat || 0)) / 100 || 0,
    0
  );
  totalVAT += prescriptions.reduce(
    (x, y) =>
      x +
      y.units.reduce(
        (a, b) => a + ((+b?.currentCost || 0) * (b?.amount || 0) * (b?.vat || 0)) / 100 || 0,
        0
      ),
    0
  );

  return {
    productCount,
    totalAmount,
    totalMoney,
    totalVAT,
    totalDiscountPromotion,
    totalBefore: totalBefore + totalVAT,
    totalAll: totalMoney + totalVAT,
  };
});

export const selectValidCheck = createSelector(
  [selectSales],
  (sales) => sales.valid
);

export const selectSalesStatistic = createSelector(
  [selectSales],
  (sales) => sales.statistic
);

export const selectSalesSubmit = createSelector(
  [selectSales],
  (sales) => sales.submitType
);

export const selectSalesInvoiceById = createSelector(
  [selectSales],
  (sales) => sales.invoiceById
);
