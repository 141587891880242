import types from './management.types';

const actions = {
  fetchSupplierList: (payload) => ({
    type: types.MANAGEMENT_FETCH_SUPPLIER_LIST,
    payload,
  }),
  setSupplierList: (payload) => ({
    type: types.MANAGEMENT_SET_SUPPLIER_LIST,
    payload,
  }),

  fetchCustomerList: (payload) => ({
    type: types.MANAGEMENT_FETCH_CUSTOMER_LIST,
    payload,
  }),
  setCustomerList: (payload) => ({
    type: types.MANAGEMENT_SET_CUSTOMER_LIST,
    payload,
  }),

  fetchUserList: (payload) => ({
    type: types.MANAGEMENT_FETCH_USER_LIST,
    payload,
  }),
  setUserList: (payload) => ({
    type: types.MANAGEMENT_SET_USER_LIST,
    payload,
  }),
};

export default actions;
