// Tính năng trang Kho hàng
export const WAREHOUSE_IMPORT_LIST = 'warehouse_import_list';
export const WAREHOUSE_IMPORT_ADD = 'warehouse_import_add';
export const WAREHOUSE_IMPORT_EDIT = 'warehouse_import_edit';
export const WAREHOUSE_IMPORT_PRINT = 'warehouse_import_print';
export const WAREHOUSE_IMPORT_CANCEL = 'warehouse_import_cancel';
export const WAREHOUSE_IMPORT_EXPORT = 'warehouse_import_export';
export const WAREHOUSE_IMPORT_IMPORT = 'warehouse_import_import';
export const WAREHOUSE_IMPORT_TEMP = 'warehouse_import_temp';

export const PERMISSION_WAREHOUSE_IMPORT = {
  WAREHOUSE_IMPORT_LIST: WAREHOUSE_IMPORT_LIST,
  WAREHOUSE_IMPORT_ADD: WAREHOUSE_IMPORT_ADD,
  WAREHOUSE_IMPORT_EDIT: WAREHOUSE_IMPORT_EDIT,
  WAREHOUSE_IMPORT_PRINT: WAREHOUSE_IMPORT_PRINT,
  WAREHOUSE_IMPORT_CANCEL: WAREHOUSE_IMPORT_CANCEL,
  WAREHOUSE_IMPORT_EXPORT: WAREHOUSE_IMPORT_EXPORT,
  WAREHOUSE_IMPORT_IMPORT: WAREHOUSE_IMPORT_IMPORT,
  WAREHOUSE_IMPORT_TEMP: WAREHOUSE_IMPORT_TEMP,
};

export const WAREHOUSE_EXPORT_LIST = 'warehouse_export_list';
export const WAREHOUSE_EXPORT_ADD = 'warehouse_export_add';
export const WAREHOUSE_EXPORT_EDIT = 'warehouse_export_edit';
export const WAREHOUSE_EXPORT_CONFIRM = 'warehouse_export_confirm';
export const WAREHOUSE_EXPORT_PRINT = 'warehouse_export_print';
export const WAREHOUSE_EXPORT_CANCEL = 'warehouse_export_cancel';
export const WAREHOUSE_EXPORT_EXPORT = 'warehouse_export_export';
export const WAREHOUSE_EXPORT_IMPORT = 'warehouse_export_import';
export const WAREHOUSE_EXPORT_TEMP = 'warehouse_export_temp';

export const PERMISSION_WAREHOUSE_EXPORT = {
  WAREHOUSE_EXPORT_LIST: WAREHOUSE_EXPORT_LIST,
  WAREHOUSE_EXPORT_ADD: WAREHOUSE_EXPORT_ADD,
  WAREHOUSE_EXPORT_EDIT: WAREHOUSE_EXPORT_EDIT,
  WAREHOUSE_EXPORT_CONFIRM: WAREHOUSE_EXPORT_CONFIRM,
  WAREHOUSE_EXPORT_PRINT: WAREHOUSE_EXPORT_PRINT,
  WAREHOUSE_EXPORT_CANCEL: WAREHOUSE_EXPORT_CANCEL,
  WAREHOUSE_EXPORT_EXPORT: WAREHOUSE_EXPORT_EXPORT,
  WAREHOUSE_EXPORT_IMPORT: WAREHOUSE_EXPORT_IMPORT,
  WAREHOUSE_EXPORT_TEMP: WAREHOUSE_EXPORT_TEMP,
};

export const WAREHOUSE_STOCK_LIST = 'warehouse_stock_list';
export const WAREHOUSE_STOCK_EXPORT = 'warehouse_stock_export';

export const PERMISSION_WAREHOUSE_STOCK = {
  WAREHOUSE_STOCK_LIST: WAREHOUSE_STOCK_LIST,
  WAREHOUSE_STOCK_EXPORT: WAREHOUSE_STOCK_EXPORT,
};

export const WAREHOUSE_CHECK_LIST = 'warehouse_check_list';
export const WAREHOUSE_CHECK_ADD = 'warehouse_check_add';
export const WAREHOUSE_CHECK_EDIT = 'warehouse_check_edit';
export const WAREHOUSE_CHECK_CONFIRM = 'warehouse_check_confirm';
export const WAREHOUSE_CHECK_EXPORT = 'warehouse_check_export';
export const WAREHOUSE_CHECK_PRINT = 'warehouse_check_print';

export const PERMISSION_WAREHOUSE_CHECK = {
  WAREHOUSE_CHECK_LIST: WAREHOUSE_CHECK_LIST,
  WAREHOUSE_CHECK_ADD: WAREHOUSE_CHECK_ADD,
  WAREHOUSE_CHECK_EDIT: WAREHOUSE_CHECK_EDIT,
  WAREHOUSE_CHECK_EXPORT: WAREHOUSE_CHECK_EXPORT,
  WAREHOUSE_CHECK_CONFIRM: WAREHOUSE_CHECK_CONFIRM,
  WAREHOUSE_CHECK_PRINT: WAREHOUSE_CHECK_PRINT,
};

export const WAREHOUSE_CANCEL_LIST = 'warehouse_cancel_list';
export const WAREHOUSE_CANCEL_ADD = 'warehouse_cancel_add';
export const WAREHOUSE_CANCEL_CONFIRM = 'warehouse_cancel_confirm';
export const WAREHOUSE_CANCEL_CANCEL = 'warehouse_cancel_cancel';
export const WAREHOUSE_CANCEL_EXPORT = 'warehouse_cancel_export';
export const WAREHOUSE_CANCEL_PRINT = 'warehouse_cancel_print';

export const PERMISSION_WAREHOUSE_CANCEL = {
  WAREHOUSE_CANCEL_LIST: WAREHOUSE_CANCEL_LIST,
  WAREHOUSE_CANCEL_ADD: WAREHOUSE_CANCEL_ADD,
  WAREHOUSE_CANCEL_CONFIRM: WAREHOUSE_CANCEL_CONFIRM,
  WAREHOUSE_CANCEL_CANCEL: WAREHOUSE_CANCEL_CANCEL,
  WAREHOUSE_CANCEL_EXPORT: WAREHOUSE_CANCEL_EXPORT,
  WAREHOUSE_CANCEL_PRINT: WAREHOUSE_CANCEL_PRINT,
};

export const WAREHOUSE_REPORT_LIST = 'warehouse_report_list';
export const WAREHOUSE_REPORT_EXPORT = 'warehouse_report_export';

export const PERMISSION_WAREHOUSE_REPORT = {
  WAREHOUSE_REPORT_LIST: WAREHOUSE_REPORT_LIST,
  WAREHOUSE_REPORT_EXPORT: WAREHOUSE_REPORT_EXPORT,
};

export const PERMISSION_WAREHOUSE = {
  ...PERMISSION_WAREHOUSE_IMPORT,
  ...PERMISSION_WAREHOUSE_EXPORT,
  ...PERMISSION_WAREHOUSE_STOCK,
  ...PERMISSION_WAREHOUSE_CHECK,
  ...PERMISSION_WAREHOUSE_CANCEL,
  ...PERMISSION_WAREHOUSE_REPORT,
};
