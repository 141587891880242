import './BillInfoComponent.scss';
import { ItemType } from './AddItemComponent';
import FormCustomize from 'components/FormCustomize';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import {
  ListGroup,
  ListGroupItem,
  Card,
  Form,
  Button,
  Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import 'pages/sales/partials/BillInfo.scss';
import './BillInfoComponent.scss';
import {
  selectCheckInventoryId,
  selectQualityControlDrugList,
  selectQualityControlId,
  selectReportExportId,
  selectReportSummary,
} from 'redux/report/report.selectors';
import { selectCurrentUser } from 'redux/user/user.selectors';
import reportActions from 'redux/report/report.actions';
import { useHistory } from 'react-router-dom';
import paths from 'config/paths';
import {
  formatDate,
  showConfirmDialog,
  showToast,
  truncTime,
  APP_BRANCH
} from 'utils/util';
import moment from 'moment';

const BillInfoComponent = ({ type = ItemType.QualityControl, initialData }) => {
  const units = useSelector(selectQualityControlDrugList);
  const qualityControlId = useSelector(selectQualityControlId);
  const checkInventoryId = useSelector(selectCheckInventoryId);
  const reportExportId = useSelector(selectReportExportId);
  const isValid = units?.length;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(selectCurrentUser);
  const reportSummary = useSelector(selectReportSummary);

  const formList =
    type === ItemType.QualityControl
      ? [
          {
            name: 'chargePerson',
            label: 'Dược sĩ phụ trách',
          },
          {
            name: 'trackingStaff',
            label: 'Nhân viên theo dõi',
          },
        ]
      : type === ItemType.CheckInventory
      ? [{ name: 'note', label: 'Ghi chú', type: 'textarea', rows: 2 }]
      : [
          { name: 'createdAt', label: 'Ngày tạo hóa đơn', type: 'date', maxDate: moment().toDate() },
          {
            name: 'quantity',
            label: 'Số lượng loại thuốc',
            type: 'number',
            readOnly: true,
          },
          {
            name: 'amount',
            label: 'Tổng giá trị (VNĐ)',
            type: 'number',
            readOnly: true,
          },
          {
            name: 'vatAmount',
            label: 'Tổng VAT (VNĐ)',
            type: 'number',
            readOnly: true,
          },
          { name: 'description', label: 'Ghi chú', type: 'textarea', rows: 2 },
        ];
  const initialValues =
    type === ItemType.QualityControl
      ? {
          chargePerson: user.userinfo.fullName,
          trackingStaff: user.userinfo.fullName,
        }
      : type === ItemType.CheckInventory
      ? {
          note: initialData?.note || '',
        }
      : {
          createdAt: truncTime(new Date()),
          amount: 0,
          vatAmount: 0,
        };

  const handleSubmit = (values) => {
    let errElm = document.querySelector('._add-item-component .is-invalid');
    if (errElm !== null) {
      showToast('Dữ liệu nhập không hợp lệ. Vui lòng kiểm tra lại.');
      errElm.focus();
      return;
    }
    showConfirmDialog({
      message: `${
        type === ItemType.QualityControl
          ? 'Xác nhận tạo sổ kiểm soát chất lượng?'
          : type === ItemType.CheckInventory
          ? 'Xác nhận tạo phiếu kiểm kho?'
          : `Xác nhận ${initialData?.id ? 'chỉnh sửa' : ''} xuất huỷ`
      }`,
      defaultAction: () => {
        dispatch(
          reportActions.postReportQuantityControlDrugList({
            type,
            ...values,
            ...(type === ItemType.QualityControl
              ? {
                  data: units?.map((e) => e.values),
                  source : APP_BRANCH,
                }
              : type === ItemType.CheckInventory
              ? {
                  checkDetail: units?.map((e) => e.values),
                  id: initialData?.id,
                  source : APP_BRANCH,
                }
              : {
                  invoiceDetail: units?.map((e) => {
                    const { mainCost, ...payload } = e.values;
                    return {
                      ...payload,
                      cost: mainCost,
                      usage: '',
                      source : APP_BRANCH,
                    };
                  }),
                  invoiceType: 'IV8',
                  receipt_date: formatDate(
                    formik.values.createdAt,
                    'YYYY-MM-DD'
                  ),
                  invoice_id: initialData?.id,
                  source : APP_BRANCH,
                  createdAt: formatDate(formik.values.createdAt, 'YYYY-MM-DD'),
                  paymentStatus: 1,
                  customerId: null,
                  status: 'pending',
                  payAmount: formik.values.amount,
                }),
          })
        );
      },
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (
      type === ItemType.QualityControl &&
      qualityControlId &&
      qualityControlId > 0
    ) {
      showToast('Tạo sổ kiểm soát chất lượng thành công', 'success');
      history.push(paths.report.qualityControl);
    }
  }, [qualityControlId]);

  useEffect(() => {
    if (type === ItemType.CheckInventory && checkInventoryId) {
      showToast('Tạo phiếu kiểm kho thành công', 'success');
      history.push(paths.report.checkInventory);
    }
  }, [checkInventoryId]);

  useEffect(() => {
    if (type === ItemType.ReportExport && reportExportId) {
      showToast('Xuất huỷ thành công', 'success');
      history.push(paths.report.export);
    }
  }, [reportExportId]);

  useEffect(() => {
    switch (type) {
      case ItemType.QualityControl:
        break;
      case ItemType.CheckInventory:
        break;
      case ItemType.ReportExport:
        formik.setFieldValue('quantity', units.length);
        formik.setFieldValue('amount', +reportSummary.totalAmount);
        formik.setFieldValue('vatAmount', +reportSummary.vatAmount);
        break;
      default:
    }
  }, [units]);

  return (
    <Card className="_quality-control-bill-info _bill-info _bill-info-component">
      <Card.Header>
        <h6 className="mb-0">
          {type === ItemType.QualityControl
            ? 'Thông tin hóa đơn kiểm tra chất lượng'
            : type === ItemType.CheckInventory
            ? 'Thông tin phiếu kiểm kho'
            : 'Thông tin phiếu xuất huỷ'}
        </h6>
      </Card.Header>
      <Card.Body>
        <Form noValidate inline onSubmit={formik.handleSubmit}>
          <ListGroup>
            {formList.map((formProps, index) => (
              <ListGroupItem key={index}>
                <FormCustomize {...formProps} formik={formik} />
              </ListGroupItem>
            ))}
          </ListGroup>
          <Row className="submit-button">
            <Button
              className={isValid ? 'btn-info' : 'btn-secondary'}
              type="submit"
              disabled={!isValid}
            >
              Hoàn thành
            </Button>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default BillInfoComponent;
