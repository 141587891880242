const ManagementTypes = {
  MANAGEMENT_FETCH_CUSTOMER_LIST: 'MANAGEMENT_FETCH_CUSTOMER_LIST',
  MANAGEMENT_SET_CUSTOMER_LIST: 'MANAGEMENT_SET_CUSTOMER_LIST',
  MANAGEMENT_FETCH_SUPPLIER_LIST: 'MANAGEMENT_FETCH_SUPPLIER_LIST',
  MANAGEMENT_SET_SUPPLIER_LIST: 'MANAGEMENT_SET_SUPPLIER_LIST',
  MANAGEMENT_FETCH_USER_LIST: 'MANAGEMENT_FETCH_USER_LIST',
  MANAGEMENT_SET_USER_LIST: 'MANAGEMENT_SET_USER_LIST',
};

export default ManagementTypes;
