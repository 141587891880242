import React from 'react';
import NoImage from 'assets/pictures/noimage.jpg';

const ProductImage = ({ className, src, onClick, ...props }) => {
  return (
    <img
      className={`_product-image ${className || ''}`}
      {...props}
      src={src || NoImage}
      onError={(e) => {
        e.target.src = NoImage;
      }}
      onClick={onClick}
      alt=""
    />
  );
};

export default ProductImage;
