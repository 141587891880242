import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import types from './sales.types';
import urls from 'config/urls';
import sendRequest from 'utils/sendRequest';
import actions from './sales.action';
import { SHIPPING } from './sales.reducer';

function* fetchDrugList({ payload }) {
  try {
    const URL = payload ? `${urls.sales.searchDrugList}/${payload}` : urls.sales.drugListFavorite;

    const data = yield call(sendRequest, {
      url: URL,
      background: true,
    });
    yield put(actions.setSalesDrugList(data));
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(actions.setSalesDrugList([]));
  }
}

function* fetchDrugDetail({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: `${urls.sales.drugDetail}/${payload.drug.id || payload.drug.drugId}`,
      params: {
        agencyId: payload.agencyId,
      }
    });
    yield put(
      actions.setSalesDrugDetail({
        detail: payload.drug,
        batches: data,
        formType: payload.formType,
        index: payload.index,
        unitIndex: payload.unitIndex,
      })
    );
  } catch (ex) {
    console.log('ex: ', ex);
    yield put(
      actions.setSalesDrugDetail({
        detail: null,
        batches: [],
        formType: payload.formType,
      })
    );
  }
}

function* fetchClientList() {
  try {
    const data = yield call(sendRequest, {
      url: `${urls.sales.clientList}`,
    });
    yield put(actions.setSalesClientList(data));
  } catch (ex) {
    yield put(actions.setSalesClientList([]));
  }
}

function* postClient({ payload }) {
  try {
    let d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    const body = {
      gender: payload.gender,
      name: payload.name,
      number_phone: payload.phoneNumber || null,
      email: payload.email || null,
      birthday: payload.dob || [year, month, day].join('-'),
      address: payload.address || null,
      tax_number: payload.taxNumber || null,
      status: 1,
    };
    const data = yield call(sendRequest, {
      method: 'POST',
      url: `${urls.sales.clientList}`,
      body,
    });

    yield put(actions.postSalesClientDone(data));
  } catch (ex) {
    yield put(actions.postSalesClientDone(null));
  }
}

function* postSalesData({ payload }) {
  // console.log('[sales.sagas.js] payload: ', payload);
  try {
    const invoiceId = yield call(sendRequest, {
      method: 'POST',
      url: urls.sales.submit,
      body: payload,
    });
    console.log('[sales.sagas.js] response: ', invoiceId);
    yield put(actions.postSalesDataDone(invoiceId));
  } catch (error) {
    console.log('[sales.sagas.js] error: ', error);
    const { combos, prescriptions, units } = payload;
    let objIds = {};
    units.forEach((u, i) => (objIds = { ...objIds, [u.id]: u.drugName }));
    if (combos.length)
      combos.forEach((combo, i) =>
        combo.units.forEach((u, i) => {
          objIds = { ...objIds, [u.id]: u.drugName };
        })
      );
    if (prescriptions.length) prescriptions[0].units.forEach((u, i) => (objIds = { ...objIds, [u.id]: u.drugName }));
    yield put(actions.getSalesOutStock(objIds));
  }
}

function* fetchSalesInvoiceList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.invoice.list,
      params: payload,
    });
    yield put(actions.setSalesInvoiceList(data));
  } catch (ex) {
    yield put(actions.setSalesInvoiceList([]));
  }
}

function* fetchSalesStaticstic({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.sales.statistic,
      params: payload,
    });
    yield put(actions.setSalesStatistic(data));
  } catch (ex) {
    console.log('[sales.sagas.js] ex: ', ex);
  }
}

function* fetchInvoiceById({ payload }) {
  try {
    const { type } = payload;
    let data = yield call(
      sendRequest,
      type === 'order'
        ? {
            url: urls.order.detail,
            params: {
              id: payload.invoiceId,
            },
          }
        : type === 'fromProvisional'
        ? {
            url: `${urls.warehouseProvisional.detailBill}/${payload.invoiceId}`,
          }
        : type === 'national'
        ? {
            url: urls.sales.getDetailByCode,
            params: {
              don_thuoc: payload.invoiceId,
            },
          }
          : type === SHIPPING
          ? {
              url: urls.shipping.shippingDetail + payload.invoiceId,
            }
          : {
            url: `${urls.invoice.detail}/${payload.invoiceId}`,
          }
    );
    if (type == 'national') {
      data = {
        clinic: [data],
        invoiceDetail: data.thongTinDonThuoc.map((item) => {
          if(!item.localDrug) {
            return {}
          }
          const detailItem = item.localDrug['0'] || {};
          return {
            ...detailItem,
            comboName: 'Đơn thuốc',
            drugId: detailItem.id,
            drug: {
              ...detailItem,
              numbers:item.localDrug?.numbers,
              units: item.localDrug?.unit
            }
          };
        }),
        national: true
      };
    } else if (type == SHIPPING) {
      data = {
        invoice: data.warehouseTransfer,
        invoiceDetail: data.warehouseTransferDetail,
      };
    }
    yield put(
      actions.setSalesInvoiceById({
        invoiceId: payload.invoiceId,
        invoiceData: data,
      })
    );
  } catch (ex) {
    const { type } = payload;
    if (type == 'national') {
    }
    console.log('[sales.sagas.js] ex: ', ex);
  }
}
function* fetchSalesOutStock({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.sales.outStock,
      body: {
        drugIds: Object.keys(payload).map((i) => +i),
      },
      method: 'post',
    });
    yield put(actions.setSalesOutStock(data));
  } catch (ex) {
    console.log('[sales.sagas.js] ex: ', ex);
  }
}

export function* salesSagas() {
  yield takeLatest(types.GET_SALES_DRUG_LIST, fetchDrugList);
  yield takeEvery(types.GET_SALES_DRUG_DETAIL, fetchDrugDetail);
  yield takeLatest(types.GET_SALES_CLIENT_LIST, fetchClientList);
  yield takeLatest(types.START_POST_SALES_CLIENT, postClient);
  yield takeLatest(types.START_POST_SALES_DATA, postSalesData);
  yield takeLatest(types.GET_SALES_INVOICE_LIST, fetchSalesInvoiceList);
  yield takeLatest(types.GET_SALES_STATISTIC, fetchSalesStaticstic);
  yield takeLatest(types.GET_SALES_INVOICE_ID, fetchInvoiceById);
  yield takeLatest(types.GET_SALES_OUTSTOCK, fetchSalesOutStock);
}
