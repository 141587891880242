import reportTypes from './report.types';

const INITIAL_STATE = {
  revenueAndGrossProfit: null,
  inventory: null,
  salesPrescription: null,
  qualityControl: null,
  qualityControlId: null,
  units: [],
  reportWarehouse: [],
  valids: [],
  specialDrug: [],
  checkInventory: [],
  checkInventoryId: null,
  isChecking: false,
  reportExportId: null,
  reportStockCardId: null,
};

const reportReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case reportTypes.SET_REPORT_REVENUE_AND_GROSS_PROFIT:
      return {
        ...state,
        revenueAndGrossProfit: payload,
      };
    case reportTypes.SET_REPORT_INVENTORY:
      return {
        ...state,
        inventory: payload,
      };
    case reportTypes.SET_REPORT_SALES_PRESCRIPTION:
      return {
        ...state,
        salesPrescription: payload,
      };
    case reportTypes.SET_REPORT_QUALITY_CONTROL_LIST:
      return {
        ...state,
        qualityControl: payload,
      };

    case reportTypes.SET_REPORT_QUALITY_CONTROL_DRUG_LIST: {
      const { units } = state;
      return {
        ...state,
        units: [...units, payload],
        valids: checkUnitsDuplication(units),
      };
    }
    case reportTypes.REMOVE_REPORT_QUALITY_CONTROL_DRUG_LIST: {
      let { units } = state;
      units = [...units.slice(0, payload), ...units.slice(payload + 1)];
      return {
        ...state,
        units,
        valids: checkUnitsDuplication(units),
      };
    }

    case reportTypes.UPDATE_REPORT_QUALITY_CONTROL_DRUG_LIST: {
      const { data, index } = payload;
      let { units } = state;
      units = units.map((unit, idx) =>
        index === idx ? { ...unit, ...data } : unit
      );
      return {
        ...state,
        units,
        valids: checkUnitsDuplication(units),
      };
    }

    case reportTypes.SET_REPORT_QUALITY_CONTROL_ID:
      return {
        ...state,
        qualityControlId: payload,
      };

    case reportTypes.RESET_REPORT_QUALITY_CONTROL_DRUG_LIST:
      return {
        ...state,
        units: [],
      };

    case reportTypes.SET_REPORT_WAREHOUSE:
      return {
        ...state,
        reportWarehouse: payload,
      };
    case reportTypes.SET_SPECIAL_DRUG:
      return {
        ...state,
        specialDrug: payload,
      };
    case reportTypes.SET_CHECK_INVENTORY:
      return {
        ...state,
        checkInventory: payload,
      };
    case reportTypes.SET_CHECK_INVENTORY_ID:
      return {
        ...state,
        checkInventoryId: payload,
      };

    case reportTypes.SET_REPORT_EXPORT_ID:
      return {
        ...state,
        reportExportId: payload?.id ? payload.id : payload,
        isChecking: payload?.isChecking
      };
    case reportTypes.SET_STOCK_CARD_ID:
        return {
          ...state,
          reportStockCardId: payload?.id ? payload.id : payload,
        };
    default:
      return state;
  }
};

export default reportReducer;

const checkUnitsDuplication = (drugList) => {
  let dup = drugList.map(() => ({
    duplicated: false,
  }));
  drugList.map((e, i, a) => {
    const idx = a
      .slice(i + 1)
      .findIndex(
        (d) =>
          d.values.drugCode === e.values.drugCode &&
          d.values.number === e.values.number &&
          d.values.unitId === e.values.unitId
      );
    if (idx !== -1) dup[idx + i + 1] = dup[i] = { duplicated: true };
    return e;
  });
  return dup;
};
