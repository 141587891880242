import React from 'react';
import './Loader.scss';
import Logo from './Logo';

const Loader = ({ hidden, white = false }) => {
  return (
    <div
      className={`_loaderContainer${hidden ? ' hidden' : ''}${
        white ? ' white' : ''
      }`}
    >
      <div className="_loaderOverlay"></div>
      <div className="_loaderContent">
        <Logo className="logo" />
        <div className="_loader">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
