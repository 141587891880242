// Tính năng trang SP không phải thuốc
const GOODS_ADD = 'goods_add';
const GOODS_EDIT = 'goods_edit';
const GOODS_STOP_BUSINESS = 'goods_stop_business';
const GOODS_EXPORT = 'goods_export';
const GOODS_IMPORT = 'goods_import';
const GOODS_PRINT = 'goods_print';
const GOODS_LIST = 'goods_list';

export const PERMISSION_GOODS_GENERAL = {
  GOODS_ADD: GOODS_ADD,
  GOODS_EDIT: GOODS_EDIT,
  GOODS_STOP_BUSINESS: GOODS_STOP_BUSINESS,
  GOODS_EXPORT: GOODS_EXPORT,
  GOODS_IMPORT: GOODS_IMPORT,
  GOODS_PRINT: GOODS_PRINT,
  GOODS_LIST: GOODS_LIST,
};

const GOODS_GROUP_EDIT = 'goods_group_edit';
const GOODS_GROUP_DELETE = 'goods_group_delete';
const GOODS_GROUP_ADD = 'goods_group_add';
const GOODS_GROUP_LIST = 'goods_group_list';
const GOODS_GROUP_EXPORT = 'goods_group_export';

export const PERMISSION_GOODS_GROUP = {
  GOODS_GROUP_EDIT: GOODS_GROUP_EDIT,
  GOODS_GROUP_DELETE: GOODS_GROUP_DELETE,
  GOODS_GROUP_ADD: GOODS_GROUP_ADD,
  GOODS_GROUP_LIST: GOODS_GROUP_LIST,
  GOODS_GROUP_EXPORT: GOODS_GROUP_EXPORT,
};

const GOODS_CATEGORY_EDIT = 'goods_category_edit';
const GOODS_CATEGORY_DELETE = 'goods_category_delete';
const GOODS_CATEGORY_ADD = 'goods_category_add';
const GOODS_CATEGORY_LIST = 'goods_category_list';
const GOODS_CATEGORY_EXPORT = 'goods_category_export';

export const PERMISSION_GOODS_CATEGORY = {
  GOODS_CATEGORY_EDIT: GOODS_CATEGORY_EDIT,
  GOODS_CATEGORY_DELETE: GOODS_CATEGORY_DELETE,
  GOODS_CATEGORY_ADD: GOODS_CATEGORY_ADD,
  GOODS_CATEGORY_LIST: GOODS_CATEGORY_LIST,
  GOODS_CATEGORY_EXPORT: GOODS_CATEGORY_EXPORT,
};

export const PERMISSION_GOODS = {
  ...PERMISSION_GOODS_GENERAL,
  ...PERMISSION_GOODS_GROUP,
  ...PERMISSION_GOODS_CATEGORY,
};
