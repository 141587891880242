import React, { forwardRef, useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import DropdownFilter from 'components/DropdownFilter';
import { Input } from 'antd';
import DatePicker from 'react-datepicker';
import { FaEye, FaEyeSlash, FaRegCalendarAlt, FaArrowAltCircleLeft } from 'react-icons/fa';
import MaskedInput from 'react-text-mask';
import vi from 'date-fns/locale/vi';
import Checkbox from './Checkbox';
import Radio from './Radio';
import moment from 'moment';
import { Portal } from 'react-overlays';
import './FormCustomize.scss';
import { getValueForPath } from 'utils/util';
Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + (h*60*60*1000));
  return this;
}
export const FormCustomize = forwardRef(
  (
    {
      label = '',
      options,
      listRadio,
      type = 'string',
      customElement,
      row,
      formik,
      selectOnFocus = true,
      action,
      onChange,
      onBlur,
      warning,
      error,
      labelStyle,
      isAutoFocus = false,
      customStyle,
      showLabel = true,
      source,
      reloadWith,
      suffixes,
      popover,
      ...props
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    let feedback;
    props.onChange = onChange;
    props.onBlur = onBlur;

    if (formik) {
      props.onChange = (e) => {
        if (type !== 'date' && type !== 'year'  && type !== 'month') {
          formik.handleChange(e);
        } else {
          formik.setFieldValue(props.name, e);
        }
        if (onChange) {
          onChange(e);
        }
      };
      props.onBlur = (e) => {
        formik.handleBlur(e);
        if (onBlur) {
          onBlur(e);
        }
      };

      props.isInvalid = getValueForPath(formik.touched, props.name) && !!getValueForPath(formik.errors, props.name);
      props.value = getValueForPath(formik.values, props.name);
      feedback = props.isInvalid ? getValueForPath(formik.errors, props.name) : null;
    }

    if (ref) props.ref = ref;

    if (selectOnFocus) {
      let { onFocus } = props;
      props.onFocus = (e) => {
        e.target.select();
        if (typeof onFocus === 'function') onFocus(e);
      };
    }

    if (options && type != 'autocomplete') {
      type = 'select';
    }
    if (!props.placeholder && label !== '') {
      if (props.isShowPlaceholderFirst) {
        props.placeholder = `${type === 'select' ? 'Chọn' : 'Nhập'} ${label.toLowerCase()}`;
      } else {
        props.placeholder = `${label[0].toUpperCase()}${label.substring(1).toLowerCase()}`;
      }
    }
    if (props.required === true) {
      props.placeholder += ' *';
    }

    const contentPopover = (
      <div>
        <Input
          placeholder="Nhập nội dung"
          onChange={(e) => {
            props.onChange({
              target: {
                name: popover,
                value: e.target.value,
              },
            });
          }}
        />
      </div>
    );

    let renderComponent = null;
    switch (type) {
      case 'select':
        renderComponent = (
          <>
            {/* {props.value == popover ? <Popover placement="rightTop" content={contentPopover} title="Nội dung">
              <DropdownFilter {...props} source={source} action={action} options={options} />
            </Popover> :
              <DropdownFilter {...props} source={source} action={action} options={options} />
            } */}
            {popover && props.value == popover ? (
              <>
                <Form.Control
                  style={{ paddingRight: 27 }}
                  type={'text'}
                  value={formik.values[popover] ?? ''}
                  autoComplete="off"
                  autoFocus={isAutoFocus}
                  onChange={(e) => {
                    console.log(e.target.value);
                    props.onChange({
                      target: {
                        name: popover,
                        value: e.target.value,
                      },
                    });
                  }}
                />

                <OverlayTrigger overlay={<Tooltip>Chọn lại</Tooltip>}>
                  <FaArrowAltCircleLeft
                    className="show-password-icon show-password"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.onChange({
                        target: {
                          name: props.name,
                          value: 'before',
                        },
                      });
                    }}
                  />
                </OverlayTrigger>
              </>
            ) : (
              <DropdownFilter
                {...props}
                source={source}
                action={action}
                onChange={(obj) => {
                  if (formik?.values[props.name] && formik.values[props.name] == obj.target.value) {
                    return;
                  } else {
                    props.onChange(obj);
                    if (reloadWith) {
                      formik.setFieldValue(reloadWith, undefined);
                    }
                  }
                }}
                options={options}
              />
            )}

            {/* {action} */}
          </>
        );
        break;
      case 'string':
      case 'password':
        renderComponent = (
          <>
            <Form.Control
              type={type === 'string' ? 'text' : showPassword ? 'text' : 'password'}
              value={props.value || ''}
              {...props}
              autoComplete="off"
              autoFocus={isAutoFocus}
            />
            {action ? action :type === 'password' &&
              formik.values[props.name] &&
              (showPassword ? (
                <OverlayTrigger overlay={<Tooltip>Ẩn mật khẩu!</Tooltip>}>
                  <FaEyeSlash
                    className="show-password-icon show-password"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowPassword(!showPassword);
                    }}
                  />
                </OverlayTrigger>
              ) : (
                <OverlayTrigger overlay={<Tooltip>Hiện mật khẩu!</Tooltip>}>
                  <FaEye
                    className="show-password-icon"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowPassword(!showPassword);
                    }}
                  />
                </OverlayTrigger>
              ))}
          </>
        );
        break;
      case 'number':
        let elmProps = { ...props };
        delete elmProps.isInvalid;
        delete elmProps.onChange;

        renderComponent = (
          <>
            <NumberFormat
              className={`form-control text-right ${props.className || ''}${props.isInvalid ? ' is-invalid' : ''} ${suffixes ? 'has-suffixes' : ''}`}
              autoComplete="off"
              autoFocus={isAutoFocus}
              allowNegative={false}
              {...elmProps}
              onValueChange={(values) => {
                if (props.onChange) {
                  props.onChange({
                    target: {
                      name: props.name,
                      value: values.floatValue === undefined ? '' : values.floatValue,
                    },
                  });
                }
              }}
              decimalSeparator=","
              thousandSeparator={elmProps.thousandSeparator === false ? false : '.'}
              allowLeadingZeros={false}
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                if (props.maxvalue != null && props.minvalue != null) {
                  return formattedValue === '' || (floatValue <= props.maxvalue && floatValue >= props.minvalue);
                } else if (props.maxvalue) {
                  return formattedValue === '' || floatValue <= props.maxvalue;
                } else {
                  return true;
                }
              }}
            />
            {suffixes ? <span className="suffixes">{suffixes}</span> : ''}
          </>
        );
        break;
      case 'date':
        let utcDateUpdate = new Date();
        if (props.value) {
          const dateConvert = moment(props.value).toDate();
          utcDateUpdate = props.showTimeSelect
            ? dateConvert
            : new Date(Date.UTC(dateConvert.getFullYear(), dateConvert.getMonth(), dateConvert.getDate()));
        } else {
          utcDateUpdate = props.showTimeSelect
            ? utcDateUpdate
            : new Date(Date.UTC(utcDateUpdate.getFullYear(), utcDateUpdate.getMonth(), utcDateUpdate.getDate()));
        }
        renderComponent = (
          <>
            <DatePicker
              locale={vi}
              autoComplete="off"
              dateFormat={props.showTimeSelect ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
              popperContainer={CalendarContainer}
              customInput={
                props.showTimeSelect ? (
                  <MaskedInput
                    mask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/, ' ', /[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
                  />
                ) : (
                  <MaskedInput mask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/]} />
                )
              }
              wrapperClassName={props.isInvalid ? 'is-invalid' : ''}
              className={`form-control${props.isInvalid ? ' is-invalid' : ''}`}
              {...props}
              selected={
                props.value && props.value.length !== 0 && moment(props.value).toDate() !== 'Invalid Date' ? moment(props.value).toDate()  : null
              }
              minDate={props.minDate instanceof Date ? props.minDate : props.minDate ? formik.values[props.minDate] : null}
              maxDate={props.maxDate instanceof Date ? props.maxDate : props.maxDate ? formik.values[props.maxDate] : null}
              showYearDropdown
              showMonthDropdown
              onChange={(date, event) => {
                if (date != null) {
                  utcDateUpdate = props.showTimeSelect ? date : new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                }
                props.onChange(utcDateUpdate || '', event);
              }}
              showTimeSelect={props.showTimeSelect ? true : false}
            />
            <FaRegCalendarAlt className="calendar-icon" />
          </>
        );
        break;
        case 'month':
        renderComponent = (
          <>
            <DatePicker
              locale={vi}
              wrapperClassName={props.isInvalid ? 'is-invalid' : ''}
              className={`form-control${props.isInvalid ? ' is-invalid' : ''}`}
              autoComplete="off"
              selected={
                props.value && props.value.length !== 0 && moment(props.value, 'MM/yyyy').toDate() !== 'Invalid Date' ? moment(props.value, 'MM/yyyy').toDate() : null
              }
                maxDate={props.maxDate instanceof Date ? props.maxDate : props.maxDate ? formik.values[props.maxDate] : null}
              onChange={(date, event) => {
                if(date !=null) {
                  props.onChange(moment(date).format('MM/yyyy'), event);
                }

              }}
              disabled={props.readOnly}
              showMonthYearPicker
              dateFormat="MM/yyyy"
            />
            <FaRegCalendarAlt className="calendar-icon" />
          </>
        );
        break;
      case 'year':
        renderComponent = (
          <>
            <DatePicker
              locale={vi}
              wrapperClassName={props.isInvalid ? 'is-invalid' : ''}
              className={`form-control${props.isInvalid ? ' is-invalid' : ''}`}
              autoComplete="off"
              selected={
                props.value && props.value.length !== 0 && moment(props.value).toDate() !== 'Invalid Date' ? moment(props.value).toDate() : null
              }
              onChange={(date, event) => {
                if(date !=null) {
                  props.onChange(moment(date).format('yyyy'), event);
                }

              }}
              showYearPicker
              dateFormat="yyyy"
            />
            <FaRegCalendarAlt className="calendar-icon" />
          </>
        );
        break;
      case 'textarea':
        renderComponent = <Form.Control as="textarea" row={row} value={props.value || ''} {...props} ></Form.Control>;
        break;
      case 'plaintext':
        renderComponent = <Form.Control row={row} {...props}></Form.Control>;
        renderComponent = <div className="form-control plaintext">{props.value}</div>;
        break;
      case 'check':
        let isChecked = ['true', true, 1].indexOf(formik.values[props.name]) !== -1;
        renderComponent = (
          <Checkbox
            label={label}
            isChecked={isChecked}
            {...props}
            onClick={(e) => {
              formik.setFieldValue(props.name, !isChecked);
            }}
          />
        );
        break;
      case 'radio':
        renderComponent = (
          <Radio
            label={label}
            options={listRadio}
            value={props.value ?? props.initValue}
            {...props}
            onChange={(e) => {
              formik.setFieldValue(props.name, e.target.value);
            }}
          />
        );
        break;
      case 'autocomplete':
        renderComponent = <DropdownFilter {...props} source={source} typeAutoComplete action={action} options={options} />;
        break;
      case 'empty':
        renderComponent = <></>;
        break;
        case 'component':
        renderComponent = <>{props.component}</>;
        break;
      default:
        break;
    }

    return (
      <Form.Group
        className={`${'_form-customize'}${warning ? ' is-warning' : ''}${error ? ' is-error' : ''} ${labelStyle ? 'label-form' : ''} ${
          !!props?.containerClassName ? props.containerClassName : ''
        }`}
        id={props.name}
        style={customStyle}
      >
        {type === 'plaintext' && <Form.Label className="plaintext-label">{label}</Form.Label>}
        {renderComponent}
        {label && type !== 'plaintext' && showLabel ? (
          <Form.Label>
            {label}
            {props.required === true && <span> (*)</span>}
          </Form.Label>
        ) : null}
        {feedback ? (
          <Form.Control.Feedback type="invalid" tooltip>
            {feedback}
          </Form.Control.Feedback>
        ) : warning ? (
          <Form.Control.Feedback type="warning" tooltip>
            {warning}
          </Form.Control.Feedback>
        ) : error ? (
          <Form.Control.Feedback type="invalid" tooltip>
            {error}
          </Form.Control.Feedback>
        ) : null}
        {customElement}
      </Form.Group>
    );
  }
);

const CalendarContainer = ({ children }) => {
  const el = document.getElementsByTagName('body')[0];

  return <Portal container={el}>{children}</Portal>;
};

export default FormCustomize;
