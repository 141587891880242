// Tính năng trang Báo cáo tổng quan
const REPORT_STOCK_CARD_ADD = 'report_stock_card_add';
const OVERVIEW_REPORT = 'overview_report'


export const PERMISSION_REPORT_STOCK_CARD = {
  REPORT_STOCK_CARD_ADD: REPORT_STOCK_CARD_ADD,
};

export const PERMISSION_OVERVIEW_REPORT = {
  OVERVIEW_REPORT,
  ...PERMISSION_REPORT_STOCK_CARD,
};
