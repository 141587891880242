import UserAcitonTypes from './user.types';

export const signInStart = (username, password, type, subscription = null) => ({
  type: UserAcitonTypes.SIGN_IN_START,
  payload: { username, password, type, subscription },
});

export const signInSuccess = (user) => ({
  type: UserAcitonTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = () => ({
  type: UserAcitonTypes.SIGN_IN_FAILURE,
});

export const signOut = () => ({
  type: UserAcitonTypes.SIGN_OUT,
});

export const setRefreshDate = (payload) => ({
  type: UserAcitonTypes.SET_REFRESH_DATE,
  payload,
});
export const refresh = (payload) => ({
  type: UserAcitonTypes.REFRESH_TOKEN_START,
  payload,
});
export const refreshDone = (user) => ({
  type: UserAcitonTypes.REFRESH_TOKEN_DONE,
  payload: user,
});
export const updateUserInfo = (user) => ({
  type: UserAcitonTypes.UPDATE_USER_INFO,
  payload: user,
});

export const setNotiTime = (payload) => ({
  type: UserAcitonTypes.SET_NOTI_TIME,
  payload,
});

export const setNoti = (payload) => ({
  type: UserAcitonTypes.SET_NOTI,
  payload,
});
export const addNoti = (payload) => ({
  type: UserAcitonTypes.ADD_NOTI,
  payload,
});
