import actions from './drug.types';

export const getDrugList = (payload) => ({
  type: actions.GET_DRUG_LIST,
  payload,
});

export const setDrugList = (payload) => ({
  type: actions.SET_DRUG_LIST,
  payload,
});

export const getDrugGroup = (payload) => ({
  type: actions.GET_DRUG_GROUPS,
  payload,
});

export const setDrugGroup = (data) => ({
  type: actions.SET_DRUG_GROUPS,
  payload: data,
});

export const postDrugGroup = (data, callback) => ({
  type: actions.ADD_DRUG_GROUP_START,
  payload: data,
  callback,
});

export const postDrugGroupFinish = (data) => ({
  type: actions.ADD_DRUG_GROUP_FINISH,
  payload: data,
});

export const getDrugCategory = (payload) => ({
  type: actions.GET_DRUG_CATEGORIES,
  payload,
});

export const setDrugCategory = (data) => ({
  type: actions.SET_DRUG_CATEGORIES,
  payload: data,
});

export const postDrugCategory = (data, callback) => ({
  type: actions.ADD_DRUG_CATEGORY_START,
  payload: data,
  callback,
});

export const postDrugCategoryFinish = (data) => ({
  type: actions.ADD_DRUG_CATEGORY_FINISH,
  payload: data,
});

export const removeDrugCategory = (data) => ({
  type: actions.REMOVE_DRUG_CATEGORY_START,
  payload: data,
});

export const removeDrugCategoryFinish = (data) => ({
  type: actions.REMOVE_DRUG_CATEGORY_FINISH,
  payload: data,
});

export const removeDrugGroup = (data) => ({
  type: actions.REMOVE_DRUG_GROUP_START,
  payload: data,
});

export const removeDrugGroupFinish = (data) => ({
  type: actions.REMOVE_DRUG_GROUP_FINISH,
  payload: data,
});

export const getDrugCategoryDetail = ({ id, ...payload }) => ({
  type: actions.GET_DRUG_CATEGORY_DETAIL_START,
  id,
  payload,
});

export const getDrugCategoryDetailFinish = (payload) => ({
  type: actions.GET_DRUG_CATEGORY_DETAIL_FINISH,
  payload,
});

export const getDrugGroupDetail = ({ id, ...payload }) => ({
  type: actions.GET_DRUG_GROUP_DETAIL_START,
  id,
  payload,
});

export const getDrugGroupDetailFinish = (payload) => ({
  type: actions.GET_DRUG_GROUP_DETAIL_FINISH,
  payload,
});

export const getDrugOutDateStart = (callback) => ({
  type: actions.GET_DRUG_OUT_DATE_START,
  callback,
});

export const setDrugOutDate = (payload) => ({
  type: actions.SET_DRUG_OUT_DATE,
  payload,
});

export const getDrugOutStockStart = (callback) => ({
  type: actions.GET_DRUG_OUT_STOCK_START,
  callback,
});

export const setDrugOutStock = (payload) => ({
  type: actions.SET_DRUG_OUT_STOCK,
  payload,
});

export const setDrugOrder = (payload) => ({
  type: actions.SET_DRUG_ORDER,
  payload,
});

export const setDrugSupplier = (payload) => ({
  type: actions.SET_DRUG_ORDER_SUPPLIER,
  payload,
});

export const setImportTime = (payload) => ({
  type: actions.SET_IMPORT_TIME,
  payload,
});