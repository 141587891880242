import { takeLatest, put, call } from 'redux-saga/effects';
import types from './master.types';
import urls from 'config/urls';
import sendRequest from 'utils/sendRequest';
import actions from './master.actions';

function* fetchCustomerList({ isSupplier = false, moneyFromPoint  }) {
  try {
    const data = yield call(sendRequest, {
      url: `${isSupplier ? urls.master.supplier : urls.master.customer}`,
      params: {moneyFromPoint}
    });
    if(isSupplier) {
      yield put(actions.setSupplierList(data));
    } else {
      yield put(actions.setCustomerList(data));
    }

  } catch (ex) {
    yield put(actions.setCustomerList([]));
  }
}

function* postCustomer({ payload }) {
  try {
    const isSupplier = payload.isSupplier || false;
    const data = yield call(sendRequest, {
      method: payload.id ? 'PUT' : 'POST',
      url: `${isSupplier ? urls.master.supplier : urls.master.customer}${
        payload.id ? `/${payload.id}` : ''
      }`,
      body: payload,
    });
    if(isSupplier) {
      yield put(actions.postSupplierDone({ ...payload, id: data.id }));
    } else {
      yield put(actions.postCustomerDone({ ...payload, id: data.id }));
    }
   
  } catch (ex) {
    yield put(actions.postCustomerDone(null));
  }
}

export function* masterSagas() {
  yield takeLatest(types.GET_CUSTOMER_LIST, fetchCustomerList);
  yield takeLatest(types.START_POST_CUSTOMER, postCustomer);
}
