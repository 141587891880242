// eslint-disable-next-line import/no-anonymous-default-export
export default {
  GET_REPORT_REVENUE_AND_GROSS_PROFIT: 'GET_REPORT_REVENUE_AND_GROSS_PROFIT',
  SET_REPORT_REVENUE_AND_GROSS_PROFIT: 'SET_REPORT_REVENUE_AND_GROSS_PROFIT',
  GET_REPORT_INVENTORY: 'GET_REPORT_INVENTORY',
  SET_REPORT_INVENTORY: 'SET_REPORT_INVENTORY',
  GET_REPORT_SALES_PRESCRIPTION: 'GET_REPORT_SALES_PRESCRIPTION',
  SET_REPORT_SALES_PRESCRIPTION: 'SET_REPORT_SALES_PRESCRIPTION',
  GET_REPORT_QUALITY_CONTROL_LIST: 'GET_REPORT_QUALITY_CONTROL_LIST',
  SET_REPORT_QUALITY_CONTROL_LIST: 'SET_REPORT_QUALITY_CONTROL_LIST',
  GET_REPORT_QUALITY_CONTROL_DRUG_LIST: 'GET_REPORT_QUALITY_CONTROL_DRUG_LIST',
  SET_REPORT_QUALITY_CONTROL_DRUG_LIST: 'SET_REPORT_QUALITY_CONTROL_DRUG_LIST',
  REMOVE_REPORT_QUALITY_CONTROL_DRUG_LIST:
    'REMOVE_REPORT_QUALITY_CONTROL_DRUG_LIST',
  UPDATE_REPORT_QUALITY_CONTROL_DRUG_LIST:
    'UPDATE_REPORT_QUALITY_CONTROL_DRUG_LIST',
  RESET_REPORT_QUALITY_CONTROL_DRUG_LIST:
    'RESET_REPORT_QUALITY_CONTROL_DRUG_LIST',
  POST_REPORT_QUALITY_CONTROL_DRUG_LIST:
    'POST_REPORT_QUALITY_CONTROL_DRUG_LIST',
  SET_REPORT_QUALITY_CONTROL_ID: 'SET_REPORT_QUALITY_CONTROL_ID',
  SET_CHECK_INVENTORY_ID: 'SET_CHECK_INVENTORY_ID',
  GET_REPORT_WAREHOUSE: 'GET_REPORT_WAREHOUSE',
  SET_REPORT_WAREHOUSE: 'SET_REPORT_WAREHOUSE',
  GET_SPECIAL_DRUG: 'GET_SPECIAL_DRUG',
  SET_SPECIAL_DRUG: 'SET_SPECIAL_DRUG',
  GET_CHECK_INVENTORY: 'GET_CHECK_INVENTORY',
  SET_CHECK_INVENTORY: 'SET_CHECK_INVENTORY',
  SET_REPORT_EXPORT_ID: 'SET_REPORT_EXPORT_ID',
  SET_STOCK_CARD_ID: 'SET_STOCK_CARD_ID',
};
