import { createSelector } from 'reselect';

const selectReport = (state) => state.report;

export const selectRevenueAndGrossProfit = createSelector(
  [selectReport],
  (report) => report.revenueAndGrossProfit || { data: [], sumData: {} }
);

export const selectInventory = createSelector(
  [selectReport],
  (report) => report.inventory || { data: [] }
);

export const selectSalesPrescription = createSelector(
  [selectReport],
  (report) => report.salesPrescription || []
);

export const selectQualityControl = createSelector(
  [selectReport],
  (report) => report.qualityControl || []
);

export const selectQualityControlDrugList = createSelector(
  [selectReport],
  (report) => report.units || []
);

export const selectReportWarehouse = createSelector(
  [selectReport],
  (report) => report.reportWarehouse || {}
);

export const selectQualityControlId = createSelector(
  [selectReport],
  (report) => report.qualityControlId || null
);

export const selectQualityControlValids = createSelector(
  [selectReport],
  (report) => report.valids
);

export const selectSpecialDrug = createSelector(
  [selectReport],
  (report) => report.specialDrug
);

export const selectCheckInventory = createSelector(
  [selectReport],
  (report) => report.checkInventory
);

export const selectCheckInventoryId = createSelector(
  [selectReport],
  (report) => report.checkInventoryId || null
);

export const selectReportExportId = createSelector(
  [selectReport],
  (report) => report.reportExportId
);

export const selectReportStockCardId = createSelector(
  [selectReport],
  (report) => report.reportStockCardId
);


export const selectIsChecking = createSelector(
  [selectReport],
  (report) => report.isChecking
);


export const selectReportSummary = createSelector(
  [selectQualityControlDrugList],
  (units) => {
    return {
      totalAmount: units.reduce(
        (acc, cur) => acc + (+cur?.values?.totalCost || 0),
        0
      ),
      vatAmount: units.reduce(
        (acc, cur) =>
          acc +
          (+cur.mainCost * (+cur?.values?.quantity || 0) * +cur.vat) / 100.0,
        0
      ),
    };
  }
);
