import React, { useEffect, useRef, useState } from 'react';
import './SearchForm.scss';
import { useFormik } from 'formik';

import { BiSearchAlt } from 'react-icons/bi';
import FormCustomize from './FormCustomize';
import { Form, Button } from 'react-bootstrap';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { selectCodeScaned } from 'redux/scan-code/scan-code.selectors';
import moment from 'moment';

export const SEARCH_FORM_REFRESH_DISABLED = 0;
export const SEARCH_FORM_REFRESH_CLEAR = 1;
export const SEARCH_FORM_REFRESH_DEFAULT = 2;

const SearchForm = ({
  searchFields = [],
  placeholder = 'Nhập thông tin tìm kiếm',
  handleSubmit = () => {},
  handleChange = () => {},
  searchData,
  searchDataRef,
  hasSearchInput = true,
  hasSearchButton = true,
  refreshType = SEARCH_FORM_REFRESH_CLEAR,
  autoSearch = false,
  submitButtonText = 'Tìm kiếm',
  isAutoFocus = true,
  searchRef,
  className,
  handleFocus = (f) => f,
  handleBlur = (f) => f,
  initCallOnChange = false,
  searchValue,
  onScanCode = null,
  updateSearchOnScanCode = false,
  CustomEleSearchForm,
  CustomStartSearchForm
}) => {
  let searchFieldsInitValue = {};
  searchFields.forEach((item) => {
    searchFieldsInitValue[item.name] = item.initValue || '';
  });
  let selectFields = searchFields.filter((item) => item.type === 'select' || item.options).map((item) => item.name);
  let initialValues = {
    search: typeof searchValue == 'string' ? searchValue : '',
    ...searchFieldsInitValue,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (searchValue && typeof searchValue == 'object') {
      let formikValue = {};
      Object.entries(initialValues).forEach(([key, value]) => {
        if (value && typeof value == 'object') {
          formikValue[key] = moment(searchValue[key], 'YYYY-MM-DD').utcOffset(0, true).toDate();
        } else {
          formikValue[key] = searchValue[key];
        }
      });
      if(JSON.stringify({...formikValue, page: undefined, perPage: undefined}) != JSON.stringify(formik.values)) {
        formik.setValues({ ...formikValue });
      }
    }
  }, [searchValue]);

  const codeScaned = useSelector(selectCodeScaned);

  useEffect(() => {
    if (typeof onScanCode === 'function' && codeScaned) onScanCode(codeScaned.scanCode, formik);
    else if (updateSearchOnScanCode) {
      if (codeScaned) formik.setFieldValue('search', codeScaned.scanCode);
    }
  }, [codeScaned]);

  const isMounted = useRef(false);
  useEffect(() => {
    let values = {};
    handleSearchDate();
    Object.keys(formik.values).forEach((k) => {
      let v = formik.values[k];
      if (v !== undefined && v !== null && v !== '') {
        values[k] = v;
      }
    });
    if (!isMounted.current) {
      isMounted.current = true;
      if (autoSearch === true) {
        handleSubmit(searchFields.length === 0 ? formik.values.search : values);
      }
      return;
    }
    handleChange(searchFields.length === 0 ? formik.values.search : values);
  }, [formik.values]);

  const selfSearchRef = useRef(null);
  let finalSearchRef = searchRef || selfSearchRef;

  useEffect(() => {
    //FIXME: fix this code
    setTimeout(() => {
      if (isAutoFocus && finalSearchRef.current) {
        finalSearchRef.current.focus();
      }
    }, 200);

    if (initCallOnChange) formik.setValues({ ...formik.values });
  }, []);

  const handleSearchDate = () => {
    const tempSearch = [...searchFields.map((item) => ({ ...item }))];
    const findNameAccept = ['to', 'endDate', 'toDate', 'createdTo'];
    const dateTo = tempSearch.find((item) => findNameAccept.includes(item.name));
    if (dateTo) {
      switch (dateTo.name) {
        case 'to':
          dateTo.minDate = formik.values.from;
          if (formik.values.from > formik.values.to) {
            formik.setValues({ ...formik.values, to: formik.values.from });
          }
          break;
        case 'endDate':
          dateTo.minDate = formik.values.fromDate;
          if (formik.values.fromDate > formik.values.endDate) {
            formik.setValues({ ...formik.values, endDate: formik.values.fromDate });
          }
          break;
        case 'toDate':
          dateTo.minDate = formik.values.fromDate;
          if (formik.values.fromDate > formik.values.toDate) {
            formik.setValues({ ...formik.values, toDate: formik.values.fromDate });
          }
          break;
        case 'createdTo':
          dateTo.minDate = formik.values.createdFrom;
          if (formik.values.createdFrom > formik.values.createdTo) {
            formik.setValues({ ...formik.values, createdTo: formik.values.createdFrom });
          }
          break;
        default:
      }
    }
  };

  return (
    <div className="_search-form">
      <Form
        noValidate
        onSubmit={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      >
         {CustomStartSearchForm ? <CustomStartSearchForm /> : null}
        {hasSearchInput && (
          <div className={classnames(className, 'search-box')}>
            <FormCustomize
              onFocus={() => handleFocus(searchDataRef)}
              onBlur={() => handleBlur(searchDataRef)}
              formik={formik}
              placeholder={placeholder}
              name="search"
              ref={finalSearchRef}
              isAutoFocus={isAutoFocus}
            />
            <BiSearchAlt />
          </div>
        )}
        {searchFields.map((formProps, index) => {
          return <FormCustomize key={index} {...formProps} formik={formik} index={index} />;
        })}
        <div className="wrap-button">
          {refreshType !== SEARCH_FORM_REFRESH_DISABLED && (
            <Button
              onClick={() => {
                if (refreshType === SEARCH_FORM_REFRESH_DEFAULT) {
                  formik.setValues(initialValues);
                } else {
                  let v = { ...formik.values };
                  let ks = Object.keys(v);
                  for (let i = 0; i < ks.length; i++) {
                    v = {
                      ...v,
                      [ks[i]]: selectFields.indexOf(ks[i]) < 0 ? '' : initialValues[ks[i]],
                    };
                  }
                  formik.setValues(v);
                }
              }}
            >
              Làm mới
            </Button>
          )}
          {hasSearchButton && <Button type="submit">{submitButtonText}</Button>}
          {CustomEleSearchForm ? <CustomEleSearchForm /> : null}
        </div>
        <div className="search-data-box" ref={searchDataRef}>
          {searchData}
        </div>
      </Form>
    </div>
  );
};

export default SearchForm;
