import { takeLatest, put, call } from 'redux-saga/effects';
import types from './invoice.types';
import urls from 'config/urls';
import sendRequest from 'utils/sendRequest';
import actions from './invoice.actions';

function* fetchInvoiceList({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.invoice.list,
      params: payload,
    });
    yield put(actions.setInvoiceList(data));
  } catch (ex) {
    yield put(actions.setInvoiceList([]));
  }
}

function* fetchInvoiceStatistic({ payload }) {
  try {
    const data = yield call(sendRequest, {
      url: urls.invoice.statistic,
      params: payload,
    });
    yield put(actions.setInvoiceStatistic(data));
  } catch (ex) {
    console.log('[invoice.sagas.js] ex: ', ex);
  }
}

export function* invoiceSagas() {
  yield takeLatest(types.GET_INVOICE_LIST, fetchInvoiceList);
  yield takeLatest(types.GET_INVOICE_STATISTIC, fetchInvoiceStatistic);
}
