import React from 'react';
import './FormItemRemoveButton.scss';
import { FaWindowClose } from 'react-icons/fa';

const FormItemRemoveButton = (props) => {
  return (
    <div className="_close-button-component" {...props}>
      <FaWindowClose />
    </div>
  );
};

export default FormItemRemoveButton;
